import type { QuantityUnit } from 'shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'


// Part Number	Variant	Description	Category	Comments	Vendor	Vendor Part Number
// export const allPartsConfig: MapperConfig = {
//   name: 'All Parts',
//   type: 'Upload',
//   superUserOnly: true,
//   orgIds: ['hextronics', 'hextronics-sandbox', 'hextronics-demo'],
//   rootIsTopLevel: true,
//   Logo: DocumentIcon,
//   importOutputFields: {
//     metadata: [
//       'cost',
//       'operation-sequence'
//     ]
//   },
//   fileToRows: {
//     xlsx: {
//       enable: true,
//       firstRow: 1
//     }
//   },
//   rowsToBom: [{
//     type: 'FilterRemove',
//     config: {
//       conditions: [{
//         type: 'empty',
//         column: 'part_number',
//       }]
//     }
//   }, {
//     type: 'SubassemblyExplode',
//     config: [{
//       explodeIdentifier: 'subass',
//       subassemblyKeyColumn: 'production_bom_no',
//     }]
//   }],
//   standardizeBom: {
//     columns: {
//       summary: {
//         type: 'ignore'
//       },
//       metadata: {
//         'cost': {
//           type: 'simple',
//           value: '${row.cost.match(/\\d+\\.\\d+/)?.[0] || 0}-USD',
//         },
//         'operation-sequence': {
//           type: 'simple',
//           value: '{{row.operation_sequence}}',
//         }
//       },
//       hierarchy: {
//         value: '{{row.__hierarchy}}',
//         type: 'simple'
//       },
//       partNumber: [{
//         conditions: [{
//           type: 'eq',
//           column: '__explode_identifier',
//           value: 'subass'
//         }],
//         onMatch: {
//           type: 'simple',
//           value: '${row.__firstPart.production_bom_no}'
//         },
//       }, {
//         default: true,
//         onMatch: {
//           type: 'simple',
//           value: '${row.part_number}',
//         }
//       }],
//       categoryId: [{
//         conditions: [{
//           type: 'eq',
//           column: '__explode_identifier',
//           value: 'subass'
//         }],
//         onMatch: {
//           type: 'simple',
//           value: 'subassembly'
//         },
//       }, {
//         default: true,
//         onMatch: {
//           type: 'simple',
//           value: "${kebabCase((row.inv_code || 'other'))}",
//         }
//       }],
//       isOffTheShelf: {
//         type: 'simple',
//         value: 'false'
//       },
//       name: [{
//         conditions: [{
//           type: 'eq',
//           column: '__explode_identifier',
//           value: 'subass'
//         }],
//         onMatch: {
//           type: 'simple',
//           value: '${row.__firstPart.top_level_bom_name}'
//         },
//       }, {
//         default: true,
//         onMatch: {
//           type: 'simple',
//           value: '${row.description}',
//         }
//       }],
//       quantity: [{
//         conditions: [{
//           type: 'eq',
//           column: '__explode_identifier',
//           value: 'subass'
//         }],
//         onMatch: {
//           type: 'simple',
//           value: '1'
//         },
//       }, {
//         default: true,
//         onMatch: {
//           type: 'simple',
//           value: '{{row.qty}}',
//         }
//       }],
//       units: {
//         value: 'each' as QuantityUnit,
//         type: 'quantity'
//       }
//     }
//   }
// }

export const allPartsConfig: MapperConfig = {
  name: 'All Parts',
  type: 'Upload',
  superUserOnly: true,
  orgIds: ['hextronics', 'hextronics-sandbox', 'hextronics-demo'],
  rootIsTopLevel: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: [
      'cost',
      'operation-sequence'
    ]
  },
  fileToRows: {
    xlsx: {
      enable: true,
      firstRow: 1
    }
  },
  rowsToBom: [{
    type: 'FilterRemove',
    config: {
      conditions: [{
        type: 'empty',
        column: 'part_number',
      }]
    }
  }, {
    type: 'StitchHierarchy',
    config: {
      parentPartNumberColumn: 'production_bom_no',
      partNumberColumn: 'part_number',
    }
  }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        'cost': [{
          conditions: [{
            type: 'empty',
            column: 'inv_cat',
          }],
          onMatch: {
            type: 'simple',
            value: ''
          },
        }, {
          conditions: [{
            type: 'eq',
            column: 'inv_cat',
            value: 'Subassembly'
          }],
          onMatch: {
            type: 'simple',
            value: ''
          },
        }, {
          default: true,
          onMatch: {
            type: 'simple',
            value: '${(Number(row.cost.match(/\\d+\\.\\d+/)?.[0] || 0) / Number(row.qty)).toFixed(2)}-USD',
          }
        }],
        'operation-sequence': {
          type: 'simple',
          value: '{{row.operation_sequence}}',
        }
      },
      hierarchy: {
        value: '{{row.__hierarchy}}',
        type: 'simple'
      },
      partNumber: {
          type: 'simple',
          value: '${row.part_number}',
      },
      categoryId: [{
        conditions: [{
          type: 'empty',
          column: 'inv_cat',
        }],
        onMatch: {
          type: 'simple',
          value: 'top-level'
        },
      }, {
        conditions: [{
          type: 'eq',
          column: 'inv_cat',
          value: 'Subassembly'
        }],
        onMatch: {
          type: 'simple',
          value: 'subassembly'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: "other",
        }
      }],
      isOffTheShelf: [{
        conditions: [{
          type: 'empty',
          column: 'inv_cat',
        }],
        onMatch: {
          type: 'simple',
          value: 'no'
        },
      }, {
        conditions: [{
          type: 'eq',
          column: 'inv_cat',
          value: 'Subassembly'
        }],
        onMatch: {
          type: 'simple',
          value: 'no'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: "yes",
        }
      }],
      name: [{
        conditions: [{
          type: 'notEmpty',
          column: '__first_row_top_level_bom_name',
        }],
        onMatch: {
          type: 'simple',
          value: '${row.__first_row_top_level_bom_name}'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '${row.description || "No Name Found"}',
        }
      }],
      quantity: [{
        conditions: [{
          type: 'notEmpty',
          column: '__first_row_top_level_bom_name',
        }],
        onMatch: {
          type: 'simple',
          value: '1'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.qty}}',
        }
      }],
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      }
    }
  }
}