import { getMapper as gm, getMapperOptions as getMOps } from './mapperConfigs'
import fileToRows, { BomRow } from "./1-fileToRows"
import validateBom, { Invalid as InvalidType } from "./4-validateBom"
import rowsToBom from './2-rowsToBom'
import standardizeBom, { ImportedPart as IP, CompleteImportedPart as CIP } from "./3-standardizeBom"
import { AppContext } from 'src/lib/appContext'
import {
  PartNumberByKeyMutation,
  PartNumberByKeyMutationVariables,
  PartNumberGenerateMutation,
  PartNumberGenerateMutationVariables,
  CategoryIdByPartNumberQuery,
  CategoryIdByPartNumberQueryVariables
} from 'types/graphql'
import type { SelectedProject } from 'src/components/ProjectSelectCell'
import { MetadataSchema } from '../metadata'
import { useMutation } from '@redwoodjs/web'
import { useLazyQuery } from '@apollo/client'
import kebabCase from 'lodash.kebabcase'
import uniqBy from 'lodash.uniqby'
import omit from 'lodash.omit'

export type ImportedPart = IP
export type CompleteImportedPart = CIP

export const getMapperOptions = getMOps
export const getMapper = gm

type MapperInput = {
  appContext: AppContext
  metadataSchema: MetadataSchema
  selectedMapper: string
  selectedProject: SelectedProject
  partNumberByKeyMutation:
    ReturnType<typeof useMutation<PartNumberByKeyMutation, PartNumberByKeyMutationVariables>>[0]
  partNumberGenerationMutation:
    ReturnType<typeof useMutation<PartNumberGenerateMutation, PartNumberGenerateMutationVariables>>[0]
  categoryIdByPartNumberQuery:
    ReturnType<typeof useLazyQuery<CategoryIdByPartNumberQuery, CategoryIdByPartNumberQueryVariables>>[0]
}

export type Invalid = InvalidType

type Output = {
  valid: false
  invalids: Invalid[]
} | {
  valid: true
  bom: ImportedPart[]
  distributors: { id: string, name: string }[]
}

const replaceDistributorNames = (bom: ImportedPart[]) => {
  let distributors: { id: string, name: string }[] = []
  const withDistributorId = bom.map(row => {
    const sources = row.sources.map(source => {
      if (typeof source.distributorName !== 'string') return source

      if (source.distributorName === '') {
        return {
          ...omit(source, 'distributorName'),
        }
      }

      const distributorId = kebabCase(source.distributorName)
      distributors = [...distributors, {
        id: distributorId,
        name: source.distributorName
      }]
      return {
        ...omit(source, 'distributorName'),
        distributorId
      }
    })
    return {
      ...row,
      sources
    }
  })
  return {
    bom: withDistributorId,
    distributors: uniqBy(distributors, 'id')
  }
}

export type ToBomInput = {
  type: 'file',
  data: File
} | {
  type: 'rows',
  data: BomRow[]
}

export const fileToStandardBom: (orgId: string, input: ToBomInput, other: MapperInput) => Promise<Output>
   = async (orgId, input, other) => {
  const mapperConfig = gm(orgId, other.selectedMapper)
  if (!mapperConfig) throw new Error(`Mapper config for: ${other.selectedMapper} not found`)

  let bomRows: BomRow[] = []
  if (input.type === 'file') {
    bomRows = await fileToRows(mapperConfig, input.data)
  }
  if (input.type === 'rows') {
    bomRows = input.data
  }
  console.log({ fileToRows: bomRows })
  const bom = rowsToBom(mapperConfig, bomRows, other)
  console.log({ rowsToBom: bom })
  const standardBom = await standardizeBom(mapperConfig, bom, other)

  const finalBom = replaceDistributorNames(standardBom)
  console.log({ finalBom })

  const invalids = validateBom(mapperConfig, finalBom.bom, other)
  if (invalids.length > 0) return { valid: false, invalids }

  return {
    valid: true,
    ...finalBom
  }
}
