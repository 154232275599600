import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, navigate } from '@redwoodjs/router';
import { z } from 'zod';
import { AnyFilter, zAnyFilter, ContainsFilter } from 'shared/types';

const serializeFilters = (filters: AnyFilter[]) => encodeURIComponent(JSON.stringify(filters));
const deserializeFilters = (filterStr: string): AnyFilter[] => {
  try {
    const parsed = JSON.parse(decodeURIComponent(filterStr));
    return z.array(zAnyFilter).parse(parsed);
  } catch (error) {
    console.error('Failed to parse URL parameter:', error);
    return [];
  }
};

const defaultFilter: ContainsFilter = {
  key: 'field.name',
  type: 'Contains',
  value: ''
}

type UseFiltersInput = {
  useURL?: boolean
  initialFilters?: AnyFilter[]
  alwaysFilters?: AnyFilter[]
}

const useHierarchyFilters = (input: UseFiltersInput = { useURL: false, initialFilters: [] }) => {
  const { useURL, initialFilters: inputFilters } = input
  const initialFilters = inputFilters || []

  const [filters, setFilters] = useState<AnyFilter[]>(() => {
    if (useURL) {
      const params = new URLSearchParams(window.location.search)
      const filterStr = params.get('filters')
      return filterStr ? deserializeFilters(filterStr) : initialFilters
    } else {
      return initialFilters
    }
  })

  const initialFiltersRef = useRef(initialFilters)
  // Update URL when filters change and if useURL is true
  useEffect(() => {
    if (useURL) {
      const params = new URLSearchParams(window.location.search)
      const serialized = serializeFilters(filters);
      if (filters.length) {
        params.set('filters', serialized);
      } else {
        params.delete('filters');
      }
      const newUrl = `${window.location.pathname}?${params}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
    if (serializeFilters(initialFiltersRef.current) !== serializeFilters(initialFilters)) {
      setFilters(initialFilters);
      initialFiltersRef.current = initialFilters;
    }
  }, [filters, useURL, initialFilters]);

  // Actions to modify filters
  const addFilter = useCallback((filter?: AnyFilter) => {
    filter = filter || defaultFilter;
    setFilters(prevFilters => [...prevFilters, filter]);
  }, []);

  const removeFilter = useCallback((index: number) => {
    setFilters(prevFilters => prevFilters.filter((_, idx) => idx !== index));
  }, []);

  const updateFilter = useCallback((index: number, newFilter: AnyFilter) => {
    setFilters(prevFilters => prevFilters.map((f, idx) => idx === index ? newFilter : f));
  }, []);

  return { filters, addFilter, removeFilter, updateFilter };
}

export default useHierarchyFilters;
