import {
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
  createContext,
  useContext
} from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

import { routes, useParams } from '@redwoodjs/router'
import { useVirtualizer } from '@tanstack/react-virtual'

import { XCircleIcon, ChevronDownIcon, ChevronRightIcon, ExclamationTriangleIcon, PlusIcon, ChevronUpIcon, XMarkIcon, PhotoIcon } from '@heroicons/react/20/solid'
import { ScaleIcon } from '@heroicons/react/16/solid'

import { AggregationNode, Aggregator } from 'src/components/PartCell/aggregation';
import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { useRequiredContext } from 'src/lib/appContext'

import type {
  PartHierarchy,
  PartTreeNodeFragment,
} from 'types/graphql'

import { isVersionIncrement, validVersion } from 'src/lib/version'

import { AppLink } from 'src/lib/routing'
import { SwatchIcon } from '@heroicons/react/24/outline';
import padEnd from 'lodash.padend'
import * as Tooltip from "src/components/ToolTip"
import { letF } from 'api/src/shared/functional'
import { findPartChildren } from 'api/src/lib/bom'

import type {
  HierarchyController,
  ControllerTreeNode,
  SubjectPart,
  EditableAggregator,
} from './hierarchyController'

import {
  HierarchyControllerContext
} from './hierarchyController'
import { humanBoolean } from '../../lib/formatters'
import groupBy from 'lodash.groupby'
import { PartColumnSelect, PartFilterSelect, allFilters } from '../PartColumnSelector/PartColumnSelector'
import keyBy from 'lodash.keyby'
import { useLifeCycleStages } from 'src/lib/lifecycle'
import Thumbnail from '../ThumbnailViewer'
import { getThumbnail } from '../Artifacts/Artifacts'
import { createPortal } from 'react-dom'

export {
  HierarchyControllerContext,
  useHierarchyControllerFactory,
} from './hierarchyController'

export type {
  RootInput,
  HierarchyController,
  HierarchyControllerInput,
  ControllerTreeNode,
  SubjectPart,
  PartHierarchyHeaderColumn,
  ActivatableAggregator,
  EditableAggregator,
  HierarchyMode
} from './hierarchyController'

type PartHierarchyExplorerProps = {
  hierarchyController: HierarchyController
}

const HEADER_HEIGHT = 50
const FILTER_HEIGHT = 40

const BranchDisabledContext = createContext<boolean>(false)

const ROW_HEIGHT = 35

const leftSticky = {
  position: 'sticky',
  left: 0,
  zIndex: 22,
  overflow: 'visible',
  height: ROW_HEIGHT,
  alignItems: 'center',
} as const
interface AggWidthInput {
  name: string
  index: number
}

const aggWidth = (a: AggWidthInput, columnWidths: Record<string, number>) => {
  const minWidth = 40 + (10 * a.name.length)
  return Math.max(
    (columnWidths[`agg.${a.index}`] || 0),
    minWidth
  )
}

const PartHierarchy: React.FC<PartHierarchyExplorerProps> = ({
  hierarchyController,
}) => {
  const [nameColumnWidth, setNameColumnWidth] = useState(350)

  const { roots, selectable, fullScreen, selectedAgg, setSelectedAgg, filterController, allColumns, filteredColumns } = hierarchyController

  const [columnWidths, setColumnWidths] = useState<Record<string, number>>(allColumns.reduce((widths, c) => {
    return {
      ...widths,
      [c.fullKey]: c.width
    }
  }, {} as Record<string, number>))

  const updateColumnWidth = (columnKey: string, width: number) => {
    setColumnWidths(prev => ({
      ...prev,
      [columnKey]: width
    }))
  }


  const rootComponents = roots
    .map((root, i) => {
      return <HierarchyControllerContext.Provider value={{ hierarchyController, root, columnWidths, nameColumnWidth }} key={i}>
        <PartHierarchyNode node={root.rootNode} depth={0} />
      </HierarchyControllerContext.Provider>
    })

  const parentRef = React.useRef(null)

  const rowCountByRoot = roots.reduce((rowCountByRoot, root, rootIndex) => {
    // we should index the root in the controller, and then use the root index
    // elsewhere to refer to it, or more ideally adjust all the nodes to include
    // the root index as the first part of their hierarchy
    // const { rootIndex } = root

    const treeNodeCount = root.tree.reduce((treeNodeCount, node) => {
      if (node.filterRemove) return treeNodeCount

      // always count the root
      if (node.isTreeRoot) return treeNodeCount + 1

      if (node.parentToNodeDependency?.groupId) return treeNodeCount

      let anyCollapsedAncestor = false
      const levels = node.hierarchy.split('.')
      for (let i = 1; i < levels.length; i++) {
        const ancestorLevel = levels.slice(0, i).join('.')
        anyCollapsedAncestor = anyCollapsedAncestor || !root.expandedNodes[ancestorLevel]
      }
      if (!anyCollapsedAncestor) {
        return treeNodeCount + 1
      }

      return treeNodeCount
    }, 0)

    // need to calc groups
    const groups = groupBy(root.tree.filter(n => n.parentToNodeDependency?.groupId), 'parentToNodeDependency.groupId')
    const groupNodeCount = Object.entries(groups).reduce((groupNodeCount, [id, groupNodes]) => {
      let count = groupNodeCount
      const firstNode = groupNodes[0]!

      let anyCollapsedAncestor = false
      const levels = firstNode.hierarchy.split('.')
      for (let i = 1; i < levels.length; i++) {
        const ancestorLevel = levels.slice(0, i).join('.')
        anyCollapsedAncestor = anyCollapsedAncestor || !root.expandedNodes[ancestorLevel]
      }

      const childCount = groupNodes.filter(n => !n.filterRemove).length
      const nodeCount = childCount === 0 ? 0 : 1

      if (anyCollapsedAncestor) return count

      count += nodeCount

      // count children if group is expanded
      if (root.expandedNodes[id]) {
        count += childCount
      }

      return count
    }, 0)

    return {
      ...rowCountByRoot,
      [rootIndex]: treeNodeCount + groupNodeCount
    }
  }, {} as Record<number, number>)

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: roots.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (rootIndex) => {
      // Row height plus 1px border
      return (ROW_HEIGHT + 1) * rowCountByRoot[rootIndex]!
    },
  })

  React.useEffect(() => {
    rowVirtualizer.measure()
  }, [roots])

  const allSelected = roots.every(root => {
    const selectedByLevel = keyBy(root.selectedNodes)
    const allSelected = root.tree.every(node => {
      if (node.filterRemove) {
        return true
      }
      const gid = node.parentToNodeDependency?.groupId
      if (gid && !selectedByLevel[gid]) {
        return false
      }
      if (!selectedByLevel[node.hierarchy]) {
        return false
      }
      return true
    })

    return allSelected
  })
  const selectAll = <div className='flex items-center cursor-pointer'>
    <input
      onClick={e => e.stopPropagation()}
      type="checkbox"
      onChange={e => {
        if (e.target.checked) {
          hierarchyController.selectAll()
        } else {
          hierarchyController.unselectAll()
        }
      }}
      checked={allSelected}
      aria-describedby="select-all-children"
      className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
    />
  </div>

  const totalRowCount = Object.values(rowCountByRoot).reduce((total, rowCount) => total + rowCount, 0)

  const uiFilters = hierarchyController.hideFilterUi ? [] : filterController.filters
  const filtersHeight = FILTER_HEIGHT * uiFilters.length
  const totalHeight = (((ROW_HEIGHT + 1) * totalRowCount) + HEADER_HEIGHT * 2) + filtersHeight + 60
  const containerHeight = fullScreen ? undefined : Math.min(450, totalHeight)
  const stages = useLifeCycleStages()

  return <div className='overflow-auto h-full w-full' ref={parentRef} style={{ height: containerHeight }}>
    <div
      className='w-fit min-w-full'
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
        position: 'relative',
      }}
    >
      {
        uiFilters.map((f, i) => {
          const selectedFilter = allFilters.find(filter => filter.key === f.type)!
          const selectedColumn = allColumns.find(c => c.fullKey === f.key)!
          return <div
            key={i}
            className='sticky bg-gray-100 text-xs border-t border-gray-200' style={{
              height: FILTER_HEIGHT,
              top: (i * (FILTER_HEIGHT)),
              // hack of the century to make dropdowns not on top of each other
              zIndex: 26 + (uiFilters.length - i)
            }}>
            <div className='fixed left-0 right-0 flex items-center gap-2 pl-10 pr-6' style={{
              position: fullScreen ? 'fixed' : 'sticky',
              height: FILTER_HEIGHT
            }}>
              <div>Filter</div>
              <PartColumnSelect columns={allColumns} value={f.key} onChange={(fullKey) => {
                const column = allColumns.find(c => c.fullKey === fullKey)!
                // default to another filter if the type isn't supported
                const getFilterOrDefault = () => {
                  if (selectedFilter.forType.includes(column.valueType)) {
                    return {
                      type: f.type
                    }
                  }

                  // option not allowed unless there is at least
                  // one stage
                  const defaultStage = stages[0]!.key

                  const value = column.valueType === 'Boolean' ? true
                    : column.valueType === 'Number' ? 0
                      : column.valueType === 'String' ? ''
                        : column.valueType === 'URL' ? ''
                          : column.valueType === 'LifeCycle' ? defaultStage : null

                  const defaultFilter = allFilters.find(filter => filter.forType.includes(column.valueType))!
                  return {
                    type: defaultFilter.key,
                    value
                  }
                }

                filterController.updateFilter(i, {
                  ...f,
                  ...getFilterOrDefault(),
                  key: column.fullKey
                })
              }} />
              <PartFilterSelect value={f.type} forType={selectedColumn.valueType} onChange={(filterType) => {
                filterController.updateFilter(i, {
                  ...f,
                  type: filterType
                })
              }} />
              <selectedFilter.Input value={f.value} valueType={selectedColumn.valueType} onChange={(v) => {
                filterController.updateFilter(i, {
                  ...f,
                  value: v
                })
              }} />
              {/* <div className='flex-1'>{JSON.stringify(f.value)}</div> */}
              <button
                className='ml-auto'
                onClick={() => filterController.removeFilter(i)}><XMarkIcon className='w-4 h-4' /></button>
            </div>
          </div>
        })
      }
      <div className='border-t bg-white z-[25] sticky top-0 w-fit min-w-full' style={{
        top: (uiFilters.length * FILTER_HEIGHT)
      }}>
        <PartHierarchyHeader
          hierarchyController={hierarchyController}
          selectedAggIndex={selectedAgg?.index}
          onAggregationHeaderClicked={(a) => {
            hierarchyController.setToolbarMode('agg')
            setSelectedAgg(a.index)
          }}
          selectedStyle='!border-gray-200 rounded-b pt-[17px] -mt-[17px] -mb-[5px] bg-gray-100 w-auto hover:bg-gray-100'
          onCreateLocalAggregator={hierarchyController.createLocalAggregator}
          nameColumnWidth={nameColumnWidth}
          onWidthChange={setNameColumnWidth}
          columnWidths={columnWidths}
          onColumnWidthChange={updateColumnWidth}
        />
      </div>
      <div className='auto pb-4 w-fit min-w-full'>
        {selectable &&
          <div className='w-full border-t flex text-xs'>
            <div className={`px-5 flex border-gray-200 items-center gap-3 bg-white`} style={{
              ...leftSticky,
              minWidth: `${nameColumnWidth}px`,
              height: ROW_HEIGHT,
            }} >
              <div className='w-4 -mx-1' />
              {selectAll}
              Select All
            </div>
          </div>
        }
        {rootComponents.length > 0 ?
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {/* Only the visible items in the virtualizer, manually positioned to be in view */}
            {rowVirtualizer.getVirtualItems().map((virtualItem) => (
              <div
                key={virtualItem.key}
                data-index={virtualItem.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                {rootComponents[virtualItem.index]}
              </div>
            ))}
          </div>
          : <div className='italic text-gray-600 text-center bg-gray-100 p-12'>There are no parts</div>}
      </div>
    </div>
  </div>
}

type PartHierarchyHeaderProps = {
  selectedAggIndex?: number
  selectedStyle?: string
  onAggregationHeaderClicked?: (agg: EditableAggregator) => void
  onCreateLocalAggregator?: () => void
  hierarchyController: HierarchyController
  nameColumnWidth: number
  onWidthChange: (width: number) => void
  columnWidths: Record<string, number>
  onColumnWidthChange: (columnKey: string, width: number) => void
}

export const PartHierarchyHeader: React.FC<PartHierarchyHeaderProps> = ({
  selectedAggIndex,
  selectedStyle = '',
  onAggregationHeaderClicked,
  onCreateLocalAggregator,
  hierarchyController,
  nameColumnWidth,
  onWidthChange,
  columnWidths,
  onColumnWidthChange
}) => {
  const { filteredColumns, aggregators, showAggregations, sortColumn, sortBy } = hierarchyController
  const shownAggs = showAggregations ? aggregators : []

  const [isDragging, setIsDragging] = useState<string | null>(null)
  const getTargetType = (a: Aggregator) => {
    const currencySetting = hierarchyController.targetCurrency
    return currencySetting?.type === a.targetType.type ? currencySetting : a.targetType
  }

  const aggClassName = (agg: EditableAggregator) =>
    twMerge(
      classNames(`
          group shrink-0 p-2 -my-2 ml-[9px] -mr-[9px] border border-t-0 min-w-32 border-white text-right
          whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible flex items-center
          hover:z-10 hover:bg-white relative justify-end pr-4
        `,
        {
          [selectedStyle]: agg.index === selectedAggIndex,
          'italic': agg.isDirty
        })
    )

  const AggTab = onAggregationHeaderClicked ? 'button' : 'div'


  const [startX, setStartX] = useState(0)
  const [startWidth, setStartWidth] = useState(0)

  const handleMouseDown = (columnKey: string, initialWidth: number) => (e: React.MouseEvent) => {
    setIsDragging(columnKey)
    setStartX(e.clientX)
    setStartWidth(initialWidth)
    e.preventDefault()
  }

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return

      const diff = e.clientX - startX
      const newWidth = Math.max(50, startWidth + diff) // Minimum width of 100px

      if (isDragging === 'nameColumn') {
        onWidthChange(newWidth)
      } else {
        onColumnWidthChange(isDragging, newWidth)
      }
      e.preventDefault()
    }

    const handleMouseUp = () => {
      setIsDragging(null)
    }

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, startX, startWidth, onWidthChange, onColumnWidthChange])

  const ResizeHandle = ({ columnKey }: { columnKey: string }) => {
    const rightClass = columnKey === 'nameColumn' || columnKey.startsWith('agg.') ? 'right-0.5': '-right-5'
    return <div
      className={`absolute cursor-col-resize w-4 items-center flex flex-col h-6 ${rightClass}`}
      onMouseDown={handleMouseDown(columnKey, columnKey === 'nameColumn' ? nameColumnWidth : columnWidths[columnKey] || 100)}
    >
      <div className='border-r border-r-gray-200 flex-1'></div>
    </div>
  }

  return <div className={`flex text-xs pr-2 justify-between`}>
    <div className='flex items-center' style={{
      ...leftSticky,
      height: HEADER_HEIGHT,
      minWidth: `${nameColumnWidth}px`,
    }}>
      <div className={`pl-10 flex items-center bg-white relative`} style={{ width: '100%', height: HEADER_HEIGHT }}>
        Part Number / Name
        <ResizeHandle columnKey="nameColumn" />
      </div>
    </div>
    <div className='flex gap-6 items-center overflow-hidden'>
      {filteredColumns.filter(c => c.key !== 'partNumberName').map(column => {
        const columnKey = column.fullKey
        const isSortColumn = column.type === sortColumn.type && column.key === sortColumn.key
        const width = columnWidths[columnKey] || column.width

        return (
          <div key={columnKey} className="relative flex items-center" style={{ width,  height: HEADER_HEIGHT }}>
            <button
              title={column.name}
              className='flex items-center'
              onClick={() => {
                if (!isSortColumn) {
                  hierarchyController.setSortBy({
                    type: column.type,
                    key: column.key,
                    direction: 'asc'
                  })
                  return
                }
                if (sortBy.direction === 'asc') {
                  hierarchyController.setSortBy({
                    ...sortBy,
                    direction: 'desc'
                  })
                  return
                }
                hierarchyController.resetSort()
              }}
            >
              <div className='shrink-0 overflow-hidden text-ellipsis whitespace-nowrap h-[17px] items-center'
                style={{
                  width: isSortColumn ? width - 20 : width,
                  textAlign: column.align || 'left',
                }}
              >
                {column.name}
              </div>
              {isSortColumn ? <div>
                {sortBy.direction === 'asc' ? <ChevronUpIcon className='w-[20px]' /> : <ChevronDownIcon className='w-[20px]' />}
              </div> : null}
            </button>
            <ResizeHandle columnKey={columnKey} />
          </div>
        )
      })}
      {shownAggs && shownAggs.length > 0 &&
        shownAggs.map((a, idx) => letF(getTargetType(a), targetType => {
          // I can't figure out why this should
          // be editable when shownAggs are ActiveAggregators??
          const agg = a as EditableAggregator
          return <AggTab
            key={idx}
            className={aggClassName(a)}
            style={{ width: aggWidth(a, columnWidths), height: HEADER_HEIGHT }}
            type={onAggregationHeaderClicked ? 'button' : undefined}
            onClick={() => onAggregationHeaderClicked?.(a)}
          >
            <span className={`p-2 -m-2 bg-inherit`}>
              <ScaleIcon className='w-4 inline -mt-1' />{' '}
              {a.name} {(targetType.type === "Number" || targetType.type === "Boolean") ? null : `(${targetType.unit})`} {agg.isDirty && '*'}
            </span>
            <ResizeHandle columnKey={`agg.${agg.index}`} />
          </AggTab>
        }))
      }
      {(showAggregations) ?
        <Tooltip.Container>
          <Tooltip.Message position='leftHorizontal' className='right-8'>
            Add New Aggregation
          </Tooltip.Message>
          <button className='flex w-5 h-5 justify-center items-center mx-3 hover:bg-gray-200 rounded' type='button' onClick={onCreateLocalAggregator}>
            <PlusIcon className='w-4 h-4' />
          </button>
        </Tooltip.Container>
        : null}
    </div>
  </div>
}

type Padding = {
  partNumber: number
}

const quantityAndUnit = (quantity?: number, units?: string) => {
  if (typeof quantity === 'undefined') return '-'
  units = units || 'each'
  const unit = (['each', 'units']).includes(units) ? '' : ` ${units}`
  return `${quantity}${unit}`
}

type GroupNode = { groupId: string, children: ControllerTreeNode[], hierarchy: string }

type PartHierarchyNodeProps = {
  padding?: Padding
  node: ControllerTreeNode | GroupNode
  parentKey?: string
  depth: number
}
const PartHierarchyNode: React.FC<PartHierarchyNodeProps> = ({
  node,
  padding,
  parentKey,
  depth,
}) => {
  const {
    root: {
      tree,
      subjectParts,
      expandedNodes,
      setExpanded,
      aggregations,
      selectedNodes,
      setSelectedNodes,
    },
    hierarchyController: {
      filteredColumns,
      selectable,
      aggregators,
      usageContext,
      showAggregations,
      sortBy,
      sortColumn,
    },
    nameColumnWidth,
    columnWidths
  } = useRequiredContext(HierarchyControllerContext)
  const shownAggs = showAggregations ? aggregators : []

  const branchDisabled = useContext(BranchDisabledContext)

  const orgId = useParams().orgId!
  const isGroup = 'groupId' in node

  const groupNode = isGroup ? node as GroupNode : null
  const partNode = !isGroup ? node as ControllerTreeNode : null

  const hasAggregations = (shownAggs?.length || 0) > 0

  const nodeId = isGroup ? node.groupId : node.hierarchy
  const expanded = expandedNodes[nodeId]

  const children = findPartChildren(tree, node.hierarchy).sort((childA, childB) => {
    return sortColumn.sortFn(childA, childB)
  })
  if (sortBy.direction === 'asc') children.reverse()

  const nodeProps = subjectParts?.find(p => p.hierarchy === node.hierarchy)

  const expandable = (isGroup || Boolean(children.filter(c => !c.filterRemove).length))
  const iconClass = 'h-5 w-5 mx-1'

  const toggleClass = classNames(
    twMerge(
      'flex items-center justify-between bg-white box-content pr-2 border-t',
      // isRoot && 'border-t',
      nodeProps?.className,
    ),
    {
      'cursor-pointer': expandable
    }
  )

  const toggleExpand = () => {
    if (expandable) setExpanded(nodeId, !expanded)
  }

  const expandButton = () => {
    const icon = expanded ?
      <ChevronDownIcon data-testid='collapse-hierarchy' className={iconClass} /> :
      <ChevronRightIcon data-testid='expand-hierarchy' className={iconClass} />

    return <div className=' text-gray-500'>
      {expandable ? icon : <div className={iconClass} />}
    </div>
  }

  const checked = (isGroup && node.children.some(c => selectedNodes.includes(c.hierarchy))) || selectedNodes.includes(node.hierarchy)
  const disableChildren = isGroup ? (branchDisabled) : (branchDisabled || (!checked));

  const selectBox = () => {
    if (!selectable) return null
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        id="hierarchy-node-toggle"
        data-testid={`hierarchy-node-checkbox-${partNode?.part?.partNumber || groupNode?.groupId}`}
        name="hierarchy-node"
        type="checkbox"
        onChange={e => {
          let newNodes: typeof selectedNodes
          if (groupNode) {
            const children = groupNode.children.map(c => c.hierarchy)
            const decendantFilter = children.map(c => c + '.')
            const allDecendants = tree
              .filter(h => decendantFilter.some(d => h.hierarchy.startsWith(d)))
              .map(d => d.hierarchy)

            if (e.currentTarget.checked) {
              newNodes = Array.from(new Set([...selectedNodes, ...children, ...allDecendants]))
            }
            else {
              const dSet = new Set(allDecendants)
              newNodes = selectedNodes.filter(n => !children.includes(n) && !dSet.has(n))
            }
          }
          else {
            if (e.currentTarget.checked) {
              if (!expanded) {
                //when node is collapsed, include children automatically
                const allDecendants = tree
                  .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                  .map(d => d.hierarchy)
                newNodes = Array.from(new Set([...selectedNodes, node.hierarchy, ...allDecendants]))
              }
              else {
                newNodes = [...selectedNodes, node.hierarchy]
              }
            }
            else {
              const dSet = new Set(tree
                .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                .map(d => d.hierarchy))
              newNodes = selectedNodes.filter(n => n !== node.hierarchy && !dSet.has(n))
            }
          }

          setSelectedNodes(newNodes)
        }}
        checked={checked}
        disabled={branchDisabled}
        aria-describedby="hierarchy-node-checkbox"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const AggNode = ({ agg, values }: { agg: Aggregator, values?: AggregationNode['values'] }) => {
    const formatter = (n: number) => agg.targetType.type === 'Price' ?
      n.toLocaleString("en", { minimumFractionDigits: 2 }) : n

    const inner = () => {
      if (agg.reducer === 'And') {
        return String(values?.[agg.name]?.[0] ?? '-')
      }

      if (!values?.[agg.name]) return '-'
      const [min, max, note] = values[agg.name]!
      return <div className='text-right' style={{ textAlign: 'right' }}>
        {note?.type === 'failed' ?
          <Tooltip.Container className='relative'>
            <XCircleIcon className='w-4 inline' />
            <Tooltip.Message className="bottom-10" position='left'>
              There was an error calulating this value
            </Tooltip.Message>
          </Tooltip.Container> :
          note?.type === 'success' ?
            <Tooltip.Container className='relative'>
              {min === max ?
                formatter(min) : `${formatter(min)} - ${formatter(max)}`
              }*
              <Tooltip.Message className="bottom-10 z-50" position='left'>
                This value includes conversions from the following units: [{Array.from(note.from).join(", ")}]
              </Tooltip.Message>
            </Tooltip.Container> :
            min === max ?
              formatter(min) : `${formatter(min)} - ${formatter(max)}`
        }
      </div>
    }
    return <div className='text-right min-w-32 pr-2' style={{ width: aggWidth(agg, columnWidths), textAlign: 'right' }}>
      {inner()}
    </div>
  }

  const indentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${depth * 18}px`
  }
  const thickIndentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${38 + (depth * 18)}px`
  }

  if (isGroup) {
    if (node.children.filter(c => !c.filterRemove).length === 0) {
      return null
    }
    const aggValues = aggregations?.getGroupNode(parentKey!, node.groupId)?.values
    const childPadding = node.children.reduce((padding, child) => {
      return {
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { partNumber: 0 } as { partNumber: number })
    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass} style={{ height: ROW_HEIGHT }}>
        <div className={`gap-3 flex bg-inherit grow pr-2`} style={{
          ...(leftSticky),
          width: `${nameColumnWidth}px`,
          ...indentationLevel,
        }}>
          {expandButton()}
          {selectBox()}
          <div className='flex gap-2'>
            <SwatchIcon className='w-4' />
            <div className='flex-1'>
              <div>{node.groupId}</div>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-end gap-6'>
          {(filteredColumns).filter(c => c.key !== 'partNumberName').map(f => <div style={{ width: columnWidths[f.fullKey] }} className='shrink-0' key={f.name}></div>)}
          {(hasAggregations && aggValues) && (shownAggs!).map((a, idx) =>
            <AggNode key={idx} values={aggValues} agg={a} />)}
          <CreateAggSpacer show={showAggregations} />
        </div>
      </div>
      {expanded && <div className='flex'>
        <div className='border-gray-100 mt-1 mb-4'></div>
        <div className='flex flex-col flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {node.children.map(c =>
              <PartHierarchyNode depth={depth + 1} key={c.hierarchy} node={c} padding={childPadding} />
            )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      }
    </div>
  }
  else {
    const SPACE = '\u00A0'

    if (node.filterRemove) return null

    const fields = {
      quantity: quantityAndUnit(node.parentToNodeDependency?.quantity, node.parentToNodeDependency?.units), //String(node.dependency?.quantity ?? '-'),
      referenceDesignator: String(node.parentToNodeDependency?.referenceDesignator || '-'),
      version: node.versionDisplay,
      category: node.part.proto.category.name,
      cadRev: node.part.cadRev || '-',
      owner: node.proto?.owner.name || '-',
      changeOrders: <div className='flex gap-2' onClick={(e) => e.stopPropagation()}>
        {
          node.proto?.inChangeOrders.filter(c => (['Review', 'Draft']).includes(c.state)).map(c => {
            return <a
              href={routes.changeOrder({ orderNumber: c.number, orgId })}
              target='_blank'
              className='text-white bg-blue-400 rounded-md inline-block p-0.5 px-2 text-xs hover:underline'>
              #{c.number} - {c.name}
            </a>
          })
        }
      </div>,
      isOffTheShelf: humanBoolean(node.part.isOffTheShelf),
      lifeCycle: <div className='flex items-center justify-center h-5'>
        <StatusDot lifeCycle={node.part.lifeCycle} size='sm' alignTooltip='right' />
      </div>,
      thumbnail: letF(getThumbnail(node.part.artifacts), a => a && <HoverPopover artifact={a} />)
    }
    const columnValues = filteredColumns.map(c => {
      if (c.type === 'field') {
        return {
          ...c,
          value: fields[c.key as keyof typeof fields]
        }
      }
      if (c.type === 'metadata') {
        const m = node.part.resolvedMetadata[c.key]
        return {
          ...c,
          value: m?.displayValue || '-'
        }
      }
      throw new Error('Invalid column type')
    }).filter(c => {
      return c.key !== 'partNumberName'
    })

    const childPadding = children.reduce((padding, child) => {
      return {
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { partNumber: 0 } as { partNumber: number })

    const childrenWithGroups =
      [
        ...children.filter(c => !c.parentToNodeDependency?.groupId) as (ControllerTreeNode & { groupId?: undefined })[],
        ...Object.entries(
          groupBy(
            children.filter(c => c.parentToNodeDependency?.groupId), c => c.parentToNodeDependency?.groupId
          )
        ).map(([groupId, group]) => ({
          groupId,
          children: group!,
          hierarchy: ''
        })),
      ]

    const subParts = (expanded && childrenWithGroups.length) ?
      <div className='flex'>
        <div className='flex flex-col flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {selectable &&
              <div className='w-full border-t flex'>
                <div className={`h-[35px] px-3 text-xs flex border-gray-200 items-center gap-3 bg-white`} style={{
                  ...leftSticky,
                  width: `${nameColumnWidth}px`,
                  ...thickIndentationLevel,
                  height: '35px', //not sure why this is necessary to get the rows to line up
                }} >
                  <div className='w-4 -mx-1' />
                  <SelectAllBox node={node} />
                  Select All Children
                </div>
                <div className='bg-green-20 shrink-0 flex gap-6' /*make row the correct width*/ >
                  {(filteredColumns).filter(c => c.key !== 'partNumberName').map(f =>
                    <div style={{ width: columnWidths[f.fullKey] }} className='shrink-0' key={f.name} />)}
                  {hasAggregations && shownAggs!.map((a, idx) =>
                    <div className={`shrink-0 min-w-32`} style={{ width: aggWidth(a, columnWidths) }} key={idx} />)}
                </div>
              </div>
            }
            {childrenWithGroups
              .map(c =>
                <PartHierarchyNode depth={depth + 1} key={c.groupId ?? c.hierarchy} node={c} padding={childPadding}
                  parentKey={node.hierarchy} />
              )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      : null

    const partNumberPadding = padding?.partNumber ? padding.partNumber + 1 : undefined
    const partNumber = padEnd(`#${node.part.partNumber}`, partNumberPadding, SPACE)

    const message = nodeProps?.message || null

    const aggValues = aggregations?.getPartNode(node.hierarchy)?.values

    const linkClass = 'underline text-blue-700 font-sans'
    const nodeInChangeOrder = Boolean(usageContext?.type === 'ChangeOrder' &&
      usageContext.changeOrder.includedParts.find(p =>
        p.partNumber === node.part.partNumber &&
        p.version === node.part.version)
    )
    // Hack because the version summary component changes the version string
    // to illustrate a version change
    const versionLink = nodeInChangeOrder ?
      <a href={`#part-change-${node.part.partNumber}`} className={linkClass}>
        {node.part.name}
      </a>
      : letF(validVersion(node.part.version as string) ?
        routes.partWithVersion({ orgId, partNumber: node.part.partNumber, version: node.part.version as string }) :
        routes.part({ orgId, partNumber: node.part.partNumber }), link =>
        <AppLink className={linkClass} to={link}>{node.part.name}</AppLink>
      )

    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass} style={{ height: ROW_HEIGHT }}>
        <div className={`gap-3 flex bg-inherit font-mono pr-2`} style={{
          ...leftSticky,
          width: `${nameColumnWidth}px`,
          ...indentationLevel,
        }}>
          {expandButton()}
          {selectBox()}
          <div className='font-medium'>{partNumber}</div>
          <div className='relative flex-1 bg-inherit flex items-center'>
            <div className='absolute left-0 right-0 bg-inherit h-8 flex items-center overflow-hidden text-ellipsis whitespace-nowrap backdrop-brightness-100 hover:pr-2 hover:overflow-visible hover:whitespace-normal hover:z-20 hover:w-max'>
              {versionLink}
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-end gap-6'>
          {
            (columnValues)
              .map(f => {
                return <div
                  style={{ width: columnWidths[f.fullKey] }}
                  key={`${f!.type}.${f!.key}`}
                  className='group shrink-0 text-right whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible hover:z-10'>
                  <div className='group-hover:bg-inherit px-2 -mx-2 flex items-center' style={{ justifyContent: alignToFlexAlign(f?.align) }}>
                    <div>{f!.value}</div>{f?.key === 'version' && message}
                  </div>
                </div>
              })
          }
          {(hasAggregations) && (shownAggs!).map((a, idx) => <AggNode key={idx} values={aggValues} agg={a} />)}
          <CreateAggSpacer show={showAggregations} />
        </div>
      </div>
      {subParts}
    </div>
  }
}

type SelectAllBoxProps = {
  node: ControllerTreeNode | GroupNode
}
const SelectAllBox: React.FC<SelectAllBoxProps> = ({ node }) => {
  const {
    root: {
      tree,
      selectedNodes,
      setSelectedNodes,
    },
  } = useRequiredContext(HierarchyControllerContext)

  const branchDisabled = useContext(BranchDisabledContext)
  const allDecendants = tree
    .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))

  const selectedByLevel = keyBy(selectedNodes)
  const allSelected = allDecendants.every(node => {
    if (node.filterRemove) {
      return true
    }
    const gid = node.parentToNodeDependency?.groupId
    if (gid && !selectedByLevel[gid]) {
      return false
    }
    if (!selectedByLevel[node.hierarchy]) {
      return false
    }
    return true
  })

  const allGroupsByLevel = allDecendants
    .reduce((allGroups, d) => {
      if (d.parentToNodeDependency?.groupId) {
        return {
          ...allGroups,
          [d.parentToNodeDependency?.groupId]: true
        }
      }
      return allGroups
    }, {} as Record<string, boolean>)

  const allDescendentLevels = [...allDecendants.map(n => n.hierarchy), ...Object.keys(allGroupsByLevel)]
  const allDescendentsByLevel = keyBy(allDescendentLevels)

  return <div className='flex items-center cursor-pointer'>
    <input
      onClick={e => e.stopPropagation()}
      name="dependencyControl"
      type="checkbox"
      onChange={e => {
        setSelectedNodes(
          e.target.checked ?
            Array.from(new Set([...selectedNodes, ...allDescendentLevels])) :
            selectedNodes.filter(n => !allDescendentsByLevel[n])
        )
      }}
      checked={allSelected}
      disabled={branchDisabled}
      aria-describedby="select-all-children"
      className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
    />
  </div>
}

export const addVersionWarnings = (tree: PartTreeNodeFragment[], existingSubjectParts?: SubjectPart[]) => {
  const outOfDateRefsByLevel = tree.reduce((byLevel, n) => {
    if (!validVersion(n.part.version as string)) return byLevel
    if (!validVersion(n.part.proto.currentVersionString)) return byLevel
    if (!n.part.proto.currentVersionString) return byLevel
    if (!isVersionIncrement(n.part.version, n.part.proto.currentVersionString)) return byLevel

    return {
      ...byLevel,
      [n.hierarchy]: true as const
    }
  }, {} as Record<string, true>)

  const outOfDateParentsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((parentsByLevel, level) => {
      const levelParts = level.split(/\./g)
      return {
        ...parentsByLevel,
        [levelParts.slice(0, -1).join('.')]: true as const
      }
    }, {} as Record<string, true>)

  const outOfDateAncestorsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((ancestorsByLevel, level) => {
      const levelParts = level.split(/\./g)
      const nextAncestors = levelParts.reduce(({ levelAcc, ancestorsByLevel }, level) => {
        const nextLevel = [...levelAcc, level]
        return {
          ancestorsByLevel: {
            ...ancestorsByLevel,
            [nextLevel.join('.')]: true as const
          },
          levelAcc: nextLevel
        }
      }, { ancestorsByLevel, levelAcc: [] as string[] })
      return nextAncestors.ancestorsByLevel
    }, {} as Record<string, true>)

  const subjectParts = [...tree].map(n => {
    const existingSubject = (existingSubjectParts?.find(s => s.hierarchy === n.hierarchy))

    const partRefOutOfDate = outOfDateRefsByLevel[n.hierarchy]
    if (partRefOutOfDate && !n.isTreeRoot) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Newer version v{n.part.proto.currentVersionString} available
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }

    const childrenOutOfDate = outOfDateParentsByLevel[n.hierarchy]
    if (childrenOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Uses out of date parts
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }

    const descendentsOutOfDate = outOfDateAncestorsByLevel[n.hierarchy]
    if (descendentsOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Parts in this tree are out of date
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }
    return existingSubject
  }).filter(Boolean) as SubjectPart[]
  return subjectParts
}

const CreateAggSpacer = ({ show }: { show?: boolean }) =>
  show ? <div className='inline-block w-5 mx-3' /> : null

const alignToFlexAlign = (input?: 'center' | 'left' | 'right') => {
  if (input === 'center') return 'center'
  if (input === 'right') return 'end'

  // left is default
  return 'start'
}

interface PopoverInput {
  artifact: {
    id: number
    fileId: number
    file: {
      url: string
      timestamp: string
    }
  }
}
function HoverPopover({ artifact }: PopoverInput) {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // We'll use a ref to measure the trigger’s position
  const triggerRef = useRef<HTMLDivElement>(null);

  // Constants for the popover geometry
  const POPOVER_WIDTH = 384; // w-96 in Tailwind’s default config is 24rem ≈ 384px
  const OFFSET = 8;          // Gap between trigger and popover

  const ICON_WIDTH = 24
  useEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();

      // Default to right side
      let leftPos = rect.right + OFFSET + window.scrollX;

      // Check if it overflows beyond the right edge of the viewport
      const isOverflowingRight = leftPos + POPOVER_WIDTH > window.innerWidth;

      if (isOverflowingRight) {
        // Position on the left side
        leftPos = rect.left - OFFSET - POPOVER_WIDTH - ICON_WIDTH + window.scrollX;
      }

      setPosition({
        top: rect.top + rect.height / 2 + window.scrollY, // vertical center of trigger
        left: leftPos,
      });
    }
  }, [isOpen]);

  return (
    <>
      {/* Trigger element */}
      <div
        ref={triggerRef}
        className="inline-block"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <PhotoIcon className="w-4 h-4 text-gray-600 relative top-0.5" />
      </div>

      {isOpen &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              top: position.top,
              left: position.left,
              transform: 'translateY(-50%)', // center vertically
            }}
            className="border bg-white shadow-lg p-3 z-[9999]"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <div className="w-96 h-80">
              <Thumbnail artifact={artifact} className="w-full h-full" />
            </div>
          </div>,
          document.body
        )}
    </>
  );
}

export default PartHierarchy
