import { User } from 'types/graphql'
import Logo from 'src/components/Logo'
import { routes, useParams, useLocation, navigate } from '@redwoodjs/router'
import { NavLink, Link } from '@redwoodjs/router'
import { useAuth } from 'src/auth'
import * as ListBox from 'src/components/ListBox'
import { BellIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import SearchBarCell from 'src/components/SearchBarCell'

type NavBarProps = {
  me?: Pick<User, 'latestNotification' | 'notificationsReadAt' | 'name' | 'isSuperUser'>
}
const NavBar: React.FC<NavBarProps> = ({
  me
}) => {
  const orgId = useParams().orgId!

  const { pathname } = useLocation()
  const partsActive = /^\/[^\/]+\/parts/.test(pathname)
  const coActive = /^\/[^\/]+\/change-order/.test(pathname)

  const navLinkClass = 'pl-2 pr-2 box-border relative self-stretch flex items-center'

  const lastNotificationCheck = me?.notificationsReadAt
  const latestNotification = me?.latestNotification

  const newNotifications = latestNotification &&
    (!lastNotificationCheck || new Date(latestNotification) > new Date(lastNotificationCheck))

  const { logOut } = useAuth();

  const handleUserMenuAction = (action: string) => {
    if (action === 'logout') logOut()
    if (action === 'superuser') navigate(routes.superUserOrg({ orgId }))
  }

  // used instead of bottom border which would move the nav text upward
  const underline = <div className='absolute border-b-4 border-purple-300 bottom-0 left-0 right-0'></div>
  return (
    <div className='h-16 z-[29]'>
      <nav className="px-6 bg-[#016dc3] border-b-1 border-gray-200 flex items-center gap-4 h-16 z-[29] text-white fixed top-0 left-0" style={{ width: 'calc(100vw)' }}>
        <a href={routes.parts({ orgId })}><Logo className='inline mr-4 text-white w-8 h-8' /></a>
        <NavLink to={routes.parts({ orgId })} activeClassName='' className={navLinkClass}>
          Parts
          {partsActive && underline}
        </NavLink>
        <NavLink to={routes.changeOrders({ orgId, state: 'draft' })} activeClassName='' className={navLinkClass}>
          Change Order
          {coActive && underline}
        </NavLink>
        <div className='flex-1 flex justify-end mr-4'>
          <SearchBarCell />
        </div>
        <div className='ml-auto flex items-center'>
          <div className='flex items-center mr-4 gap-3'>
            <a href={routes.notificatins({ orgId })} className='cursor-pointer flex gap-0.5 items-center'>
              {newNotifications && <svg className="h-2 w-2 fill-purple-400" viewBox="0 0 6 6" aria-hidden="true">
                <circle cx={3} cy={3} r={3} />
              </svg>}
              <BellIcon className='h-5 text-white' />
            </a>
            <Link to={routes.orgSettings({ orgId })} className='cursor-pointer flex gap-0.5 items-center'>
              <Cog6ToothIcon className='h-5 text-white' />
            </Link>
          </div>
          <div className='flex items-center rounded-full w-7 h-7 justify-center bg-purple-300'>
            <div>
              {me?.name[0]!.toUpperCase()}
            </div>
          </div>
          <ListBox.ListBox value='' onChange={handleUserMenuAction}>
            {({ open }) => (<>
              <div className="relative">
                <ListBox.PlainButton className='text-white' displayValue={me?.name || ''} />
                <ListBox.Options open={open} className='text-black text-xs'>
                  {me?.isSuperUser ? <ListBox.Option value='superuser' display={`Super User`} key='superuser' /> : null}
                  <ListBox.Option value='logout' display={`Logout`} key='logout' />
                </ListBox.Options>
              </div>
            </>
            )}
          </ListBox.ListBox>
        </div>
      </nav>
    </div>
  )
}

export default NavBar
