import { HierarchyMode, useHierarchyControllerFactory, HierarchyControllerInput } from '../PartHierarchy/PartHierarchy';
import FullScreenHierarchy from '../PartCell/FullScreenHierarchy'
import { usePartsCache } from 'src/lib/usePartsCache';
import { navigate, routes, useMatch, useParams } from '@redwoodjs/router';
import LoadingSpinner from '../Loading';

export const QUERY = gql`
  query AllPartsCellQuery {
    orphanParts {
      tree {
        ...PartTreeNodeFragment
      }
    }
  }
`

export default () => {
  const orgId = useParams().orgId!
  const { data } = usePartsCache()

  const showFlat = useMatch(routes.partsFlat({ orgId })).match
  const hierarchyMode: HierarchyMode = showFlat ? 'flat' : 'hierarchy'
  const roots = hierarchyMode === 'hierarchy' ? (data?.orphanParts || []).map(orphan => {
    return {
      ...orphan,
      initiallyExpandedNodes:
        []
    }
  }) : (data?.protos || []).map((p, i) => {
    return {
      tree: [{
        hierarchy: `${i}`,
        part: {
          ...p.currentVersion,
          proto: p
        },
        isTreeRoot: true,
      }],
      initiallyExpandedNodes: []
    }
  })

  const hierarchyOnlycols: HierarchyControllerInput['omitColumns'] = [{
    type: 'field',
    key: 'quantity'
  }, {
    type: 'field',
    key: 'referenceDesignator'
  }]

  const hierarchyController = useHierarchyControllerFactory({
    fullScreen: true,
    hierarchyMode,
    omitColumns: hierarchyMode === 'hierarchy' ? [] : hierarchyOnlycols,
    onHierarchyModeClick (mode) {
      if (mode === 'hierarchy') {
        navigate(routes.parts({ orgId }))
      } else {
        navigate(routes.partsFlat({ orgId }))
      }
    },
    showAggregations: hierarchyMode === 'hierarchy',
    roots: roots as HierarchyControllerInput['roots'],
    useUrlForFilters: true
  })

  if (!data) return <LoadingSpinner className='flex p-10 items-center justify-center' />

  return <>
    <FullScreenHierarchy
        hierarchyController={hierarchyController}
      />
  </>
}
