import * as ListBox from 'src/components/ListBox'
import { PartHierarchyHeaderColumn } from '../PartHierarchy'
import { allFilters } from './PartFilterSelector'
import { useLifeCycleStages } from 'src/lib/lifecycle'

type PartColumnSelectProps = {
  columns: PartHierarchyHeaderColumn[],
  onChange: (fullKey: string) => void
  value: string
}

const supportedTypes = allFilters.flatMap(f => f.forType)

export const PartColumnSelect: React.FC<PartColumnSelectProps> = ({ onChange, value, columns }) => {
  const stages = useLifeCycleStages()
  const allOptions = columns
    .filter(c => {
      if (!supportedTypes.includes(c.valueType)) return false
      if (c.fullKey === 'field.lifeCycle' && stages.length === 0) return false
      return !c.notInFilters
    })
  const display = allOptions.find(o => o.fullKey === value)!.name
  return <ListBox.ListBox onChange={(key) => {
    console.log('column select', key)
    onChange(key)
  }} value={value}>
  {({ open }) =>
    <div className="relative text-xs">
      <ListBox.Button className='text-xs' size='sm' displayValue={display}/>
      <ListBox.Options open={open} align='left'>
        {allOptions.map(o => (
          <ListBox.Option key={o.fullKey} className='py-3' value={o.fullKey} display={o.name} />
        ))}
      </ListBox.Options>
    </div>
  }
</ListBox.ListBox>
}

export * from './PartFilterSelector'
