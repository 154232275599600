export const rwUrlEncode = (urlComponent: string) => urlComponent.replaceAll(".", "~")
export const rwUrlDecode = (urlComponent: string) => urlComponent.replaceAll("~", ".")

type KeysOfType<O, T> = {
  [K in keyof O]: O[K] extends T ? K : never;
}[keyof O];
type Return<T, K extends KeysOfType<T, string>> =
    { [KK in string & T[K]]?: ({ [_ in K]: KK } & T)[] }

export function groupBy<T, K extends KeysOfType<T, string>>( arr: T[], keyExtractor: (element: T) => T[K]): Return<T, K> {
  return arr.reduce((acc, item) => {
    const group = keyExtractor(item) as string & T[K];
    const groupArray: T[] = (acc[group] = acc[group] ?? [])
    groupArray.push(item)
    return acc
  }, {} as Return<T, K>)
}

//code should never reach here
export const exhaustiveCheck = (_: never): never => {throw new Error('Missing implementation for branch')}

declare const __brand: unique symbol
type Branded<B> = { [__brand]: B }
export type Brand<T, B> = T & Branded<B>

type OmitTypename<T> = {
  [K in keyof T as K extends '__typename' ? never : K]: T[K] extends object
    ? T[K] extends Array<infer U>
      ? OmitTypename<U>[]
      : OmitTypename<T[K]>
    : T[K]
}

export function removeTypename<T>(obj: T): OmitTypename<T> {
  if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item)) as OmitTypename<T>
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      if (key !== '__typename') {
        ;(acc as any)[key] = removeTypename((obj as any)[key])
      }
      return acc
    }, {} as OmitTypename<T>)
  }
  return obj as OmitTypename<T>
}

export function simpleHash(input: string): string {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash.toString(16);
}

export function filterFalsy<T>(input: T[]): NonNullable<T>[] {
  return input.filter(Boolean) as NonNullable<T>[]
}
