import React, { useEffect, useState } from 'react'
import { CellSuccessProps, CellLoadingProps, CellFailureProps, useQuery } from '@redwoodjs/web'

import type {
  OnShapeImportQuery,
  OnShapeBomQuery,
  OnShapeBomQueryVariables
} from 'types/graphql'
import * as ListBox from '../ListBox'
import { BomRow } from 'src/lib/mapping/1-fileToRows'
import { reportGenericError } from 'src/lib/reportError'

export const QUERY = gql`
  query OnShapeImportQuery {
    onShapeDocuments {
      id
      name
      versions {
        id
        name
      }
    }
  }
`

export const ONSHAPE_BOM_QUERY = gql`
  query OnShapeBomQuery($input: OnShapeBomInput!) {
    onShapeBom(input: $input)
  }
`

type Props = {
  onBomChange: (rows: BomRow[] | null) => void
  onLoading: () => void
}

type SuccessProps = CellSuccessProps<OnShapeImportQuery> & Props
type LoadingProps = CellLoadingProps<OnShapeImportQuery> & Props
type FailureProps = CellFailureProps<OnShapeImportQuery> & Props

export const Failure = (props: FailureProps) => {
  const eventId = reportGenericError(props.error)
  return <div className='bg-red-200 p-2'>
    <div className='font-medium'>
      There was an error connecting with Altium
    </div>
    <div className='text-sm'>Contact Bomello support with error ID: <span className='font-bold'>{eventId}</span> to escalate a bug fix.</div>
  </div>
}

export const Loading = (props: LoadingProps) => {
  return <OnShapeImport {...props} />
}

export const Success = (props: SuccessProps) => {
  return <OnShapeImport {...props} />
}

type OnShapeImortProps = Partial<CellSuccessProps<OnShapeImportQuery>> & Props
const OnShapeImport = ({ onShapeDocuments, onBomChange, onLoading }: OnShapeImortProps) => {
  const displayValue = (id: string | null) => {
    if (!id) return 'Select OnShape Document'
    return onShapeDocuments?.find(d => d.id === id)!.name
  }

  const [documentId, setDocumentId] = useState<null | string>(null)
  const onChange = (id: string) => {
    setDocumentId(id)
    setVersionId(null)
    onBomChange(null)
  }

  const [versionId, setVersionId] = useState<null | string>(null)

  const selectedDocument = onShapeDocuments?.find(d => d.id === documentId)
  const versions = selectedDocument?.versions

  useEffect(() => {
    const getBom = async (documentId: string, versionId: string) => {
      onLoading()
      const bom = await refetch({
        input: {
          documentId: documentId,
          versionId: versionId
        }
      })
      console.log({ bom })
      onBomChange(bom.data.onShapeBom as BomRow[])
    }
    if (documentId && versionId) {
      getBom(documentId, versionId)
    }
  }, [documentId, versionId])

  const { data, loading, refetch } = useQuery<OnShapeBomQuery, OnShapeBomQueryVariables>(ONSHAPE_BOM_QUERY, {
    skip: true,
  })
  return (<div className='flex flex-col gap-4 items-end'>
      <ListBox.ListBox onChange={onChange} value={documentId}>
        {({ open }) =>
          <div className="relative h-full">
            <ListBox.Button className='py-2' displayValue={displayValue(documentId)} disabled={!onShapeDocuments} />
            <ListBox.Options open={open} align='right' className='text-sm'>
              {onShapeDocuments?.map(d => (
                <ListBox.Option key={d.id} className='py-3' value={d.id} display={displayValue(d.id)} />
              ))}
            </ListBox.Options>
          </div>
        }
      </ListBox.ListBox>
      {
        <VersionInput versions={versions} versionId={versionId} setVersionId={setVersionId}  />
      }
    </div>
  )
}

type DocumentVersion = {
  id: string
  name: string
}
type VersionInputProps = {
  versions?: DocumentVersion[]
  setVersionId: (v: string) => void
  versionId: string | null
}
export const VersionInput = ({ versions, setVersionId, versionId }: VersionInputProps) => {
  const displayValue = (id: string | null) => {
    if (!id || !versions) return 'Select Document Version'
    return versions.find(d => d.id === id)!.name
  }

  const onChange = (id: string) => {
    setVersionId(id)
  }

  return (
    <ListBox.ListBox onChange={onChange} value={versionId} disabled={!versions}>
    {({ open }) =>
      <div className="relative h-full">
        <ListBox.Button className='py-2' displayValue={displayValue(versionId)} disabled={!versions} />
        <ListBox.Options open={open} align='right' className='text-sm'>
          {(versions || []).map(d => (
            <ListBox.Option key={d.id} className='py-3' value={d.id} display={displayValue(d.id)} />
          ))}
        </ListBox.Options>
      </div>
    }
  </ListBox.ListBox>
  )
}

