import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react'

import { navigate, routes, useParams } from '@redwoodjs/router'

import {
  HierarchyTreeRootNode,
  Aggregator,
  buildAggregationTree,
  SumAggregator,
  MaxAggregator,
  AndAggregator,
  buildHierarchyTree,
  AggregationRootNode,
  NumericTarget,
  BooleanTarget,
  AggregatorTarget
} from 'src/components/PartCell/aggregation';
import type { ExchangeRates } from 'api/src/lib/exchangeRates'
import { useAppContext } from 'src/lib/appContext'

import type {
  PartTreeNodeFragment,
  PartTreeNodePartFragment,
  AddPartHierarchyToChangeOrderMutation,
  AddPartHierarchyToChangeOrderMutationVariables,
  MetadataType,
  PartCacheQuery,
  Maybe
} from 'types/graphql'


import { ReactNode, useState } from 'react'
import { AlwaysUpdateIcon, PinnedIcon } from '../VersionRange/VersionRange'
import { IncludedPart } from '../ChangeOrderChangesCell/VersionChangeSummary'
import keyBy from 'lodash.keyby'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import { resolveMetadataObj, useMetadataSchema, MetadataValue } from 'src/lib/metadata';
import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { humanBoolean } from 'src/lib/formatters'
import { sortVersionFn } from 'src/lib/version'
import useHierarchyFilters from './useHierarchyFilters'
import { allFilters } from '../PartColumnSelector/PartFilterSelector'
import { AnyFilter } from 'src/shared/types';
import { usePartsCache } from 'src/lib/usePartsCache';
import { CubeTransparentIcon } from '@heroicons/react/20/solid';
import { getThumbnail } from '../Artifacts/Artifacts';


interface InputNode extends PartTreeNodeFragment {
  previousVersion?: string
  previousVersionRange?: string
}

interface ControllerPart extends PartTreeNodePartFragment {
  resolvedMetadata: ReturnType<typeof resolveMetadataObj>
}
export interface ControllerTreeNode extends InputNode {
  versionDisplay: React.ReactNode
  proto?: Maybe<NonNullable<NonNullable<PartCacheQuery['partProtosCache']>['protos']>[number]>
  part: ControllerPart
  filterRemove?: boolean
}

export type SubjectPart = {
  hierarchy: string
  className?: string
  message?: ReactNode
}

export interface RootInput {
  tree: InputNode[]
  subjectParts?: SubjectPart[]
  // should always be built from rootpart?
  initiallyExpandedNodes?: string[]
}

type ColumnType = 'field' | 'metadata' | 'aggregation'

export type PartHierarchyHeaderColumn = {
  fullKey: string
  name: string
  width: number
  hide?: boolean
  key: string
  notInFilters?: boolean
  filterValue: (node: ControllerTreeNode) => MetadataValue | null | number[]
  valueType: MetadataType | 'LifeCycle' | 'ChangeOrder' | 'Owner',
  showNonFullscreen?: boolean
  type: ColumnType
  align?: 'center' | 'right' | 'left'
  displayFn: (node: ControllerTreeNode) => React.ReactNode
  sortFn: (nodeA: ControllerTreeNode, nodeB: ControllerTreeNode) => -1 | 0 | 1
}

export const ADD_TO_CHANGE_ORDER_MUTATION = gql`
mutation AddPartHierarchyToChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, allowDuplicates: true, input: $input) {
    partNumber
  }
}
`

export type HierarchyMode = 'hierarchy' | 'flat'

export interface HierarchyControllerInput {
  columns?: PartHierarchyHeaderColumn[]
  omitColumns?: Pick<PartHierarchyHeaderColumn, 'key' | 'type'>[]
  showAggregations?: boolean
  fullScreen?: boolean
  hierarchyMode?: HierarchyMode
  onToggleFullscreen?: () => void
  onToolbarModeChange?: (mode: ToolBarMode) => void
  onHierarchyModeClick?: (mode: HierarchyMode) => void
  roots: RootInput[]
  usageContext?: {
    type: 'ChangeOrder',
    changeOrder: {
      includedParts: IncludedPart[]
    }
  }
  hideFilterUi?: boolean
  useUrlForFilters?: boolean
  initialFilters?: AnyFilter[]
}

interface ControllerRoot extends RootInput {
  tree: ControllerTreeNode[]
  subjectParts: SubjectPart[]
  rootIndex: number
  expandedNodes: Record<string, boolean>
  setExpanded: (id: string, expanded: boolean) => void
  selectedNodes: string[]
  setSelectedNodes: ((nodes: string[]) => void)
  hierarchyTree: HierarchyTreeRootNode
  aggregations: AggregationRootNode
  rootNode: ControllerTreeNode
}

export type ActivatableAggregator = Aggregator & { isActive?: boolean, index: number }
export type EditableAggregator = ActivatableAggregator & { isDirty?: boolean, isNew?: boolean, savedIndex?: number }

type SortBy = {
  key: string
  type: ColumnType
  direction: 'asc' | 'desc'
}

export interface HierarchyController extends Omit<HierarchyControllerInput, 'columns'> {
  roots: ControllerRoot[]
  hierarchyMode: HierarchyMode
  allColumns: PartHierarchyHeaderColumn[]
  filteredColumns: PartHierarchyHeaderColumn[]
  aggregators: ActivatableAggregator[]
  setAggregators: Dispatch<SetStateAction<ActivatableAggregator[]>>

  targetCurrency?: AggregatorTarget
  setTargetCurrency: Dispatch<SetStateAction<NumericTarget | undefined>>

  savedAggregators: Aggregator[]
  exchangeRates: ExchangeRates

  selectable: boolean
  setSelectable: ((flag: boolean) => void)
  addSelectionToChangeOrder: (changeOrderNumber: number) => Promise<void>
  createChangeOrderWithSelection: () => void

  fullScreen: boolean
  showAggregations: boolean

  selectedAgg: EditableAggregator | null
  setSelectedAgg: (aggIndex?: number | null) => void
  createLocalAggregator: () => void
  toolbarMode: ToolBarMode
  setToolbarMode: (mode: ToolBarMode) => void

  unselectAll: () => void
  selectAll: () => void

  resetSort: () => void
  setSortBy: (sortBy: SortBy) => void
  sortBy: SortBy
  sortColumn: PartHierarchyHeaderColumn
  filterController: ReturnType<typeof useHierarchyFilters>
}

const baseColumns: PartHierarchyHeaderColumn[] = [{
  // this is only actually used for sorting
  name: 'Part Number/ Name',
  key: 'partNumberName',
  fullKey: 'field.partNumberName',
  type: 'field',
  width: 60,
  notInFilters: true,
  showNonFullscreen: true,
  align: 'left',
  valueType: 'String',
  filterValue() { return null },
  displayFn(node) {
    return node.part.partNumber
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.partNumber
    const b = nodeB.part.partNumber
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
}, {
  // this is only actually used for sorting
  name: 'Part Number',
  key: 'partNumber',
  fullKey: 'field.partNumber',
  type: 'field',
  width: 60,
  // this column just for filtering
  hide: true,
  align: 'left',
  valueType: 'String',
  filterValue(node) {
    return node.part.partNumber
  },
  displayFn(node) {
    return node.part.partNumber
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.partNumber
    const b = nodeB.part.partNumber
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
},
{
  // this is only actually used for sorting
  name: 'Part Name',
  key: 'name',
  fullKey: 'field.name',
  type: 'field',
  width: 60,
  valueType: 'String',
  showNonFullscreen: true,
  align: 'left',
  hide: true,
  filterValue(node) {
    return node.part.name || null
  },
  displayFn(node) {
    return node.part.partNumber
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.partNumber
    const b = nodeB.part.partNumber
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
}, {
  name: 'Version',
  key: 'version',
  fullKey: 'field.version',
  type: 'field',
  showNonFullscreen: true,
  width: 100,
  valueType: 'String',
  align: 'right',
  filterValue(node) {
    return node.part.version
  },
  displayFn(node) {
    return node.versionDisplay
  },
  sortFn(nodeA, nodeB) {
    return sortVersionFn(nodeA.part.version, nodeB.part.version) as 1 | 0 | -1
  }
}, {
  name: 'Lifecycle',
  key: 'lifeCycle',
  fullKey: 'field.lifeCycle',
  type: 'field',
  width: 60,
  valueType: 'LifeCycle',
  showNonFullscreen: true,
  align: 'center',
  filterValue(node) {
    return node.part.lifeCycle || null
  },
  displayFn(node) {
    return <div className='flex items-center justify-center h-5'>
      <StatusDot lifeCycle={node.part.lifeCycle} size='sm' alignTooltip='right' />
    </div>
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.lifeCycle || ''
    const b = nodeB.part.lifeCycle || ''
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
}, {
  name: 'Quantity',
  key: 'quantity',
  fullKey: 'field.quantity',
  type: 'field',
  width: 100,
  valueType: 'Number',
  showNonFullscreen: true,
  align: 'center',
  notInFilters: true,
  filterValue(node) {
    return node.parentToNodeDependency?.quantity || null
  },
  displayFn(node) {
    return quantityAndUnit(node.parentToNodeDependency?.quantity, node.parentToNodeDependency?.units)
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.parentToNodeDependency?.quantity || 0
    const b = nodeB.parentToNodeDependency?.quantity || 0
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'Thumbnail',
  key: 'thumbnail',
  fullKey: 'field.thumbnail',
  type: 'field',
  width: 60,
  valueType: 'String',
  showNonFullscreen: true,
  align: 'center',
  notInFilters: true,
  filterValue(node) {
    //not sure about this
    return Boolean(getThumbnail(node.part.artifacts))
  },
  displayFn(node) {
    //probably should render html
    return getThumbnail(node.part.artifacts)?.file.url
  },
  sortFn(nodeA, nodeB) {
    const a = getThumbnail(nodeA.part.artifacts)
    const b = getThumbnail(nodeB.part.artifacts)
    if (a && b || !a && !b) return 0
    if (a) return 1
    return -1
  }
}, {
  name: 'Category',
  key: 'category',
  fullKey: 'field.category',
  valueType: 'String',
  showNonFullscreen: true,
  type: 'field',
  width: 150,
  align: 'left',
  displayFn(node) {
    return node.part.proto.category.name
  },
  filterValue(node) {
    return node.part.proto.category.name
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.proto.category.name
    const b = nodeB.part.proto.category.name
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'Change Orders',
  key: 'changeOrders',
  fullKey: 'field.changeOrders',
  type: 'field',
  showNonFullscreen: false,
  width: 200,
  valueType: 'ChangeOrder',
  align: 'left',
  filterValue(node) {
    return node.proto?.inChangeOrders.map(c => c.number) || null
  },
  displayFn(node) {
    return <div>
      {
        node.proto?.inChangeOrders.map(c => {
          return <div>{c.number}</div>
        })
      }
    </div>
  },
  sortFn(nodeA, nodeB) {
    const coCountA = nodeA.proto?.inChangeOrders.length || 0
    const coCountB = nodeB.proto?.inChangeOrders.length || 0
    if (coCountA > coCountB) return 1
    if (coCountA < coCountB) return -1
    return 0
  }
}, {
  name: 'Owner',
  key: 'owner',
  fullKey: 'field.owner',
  type: 'field',
  showNonFullscreen: false,
  width: 150,
  valueType: 'Owner',
  align: 'left',
  filterValue(node) {
    return node.proto?.owner.id || null
  },
  displayFn(node) {
    return node.proto?.owner.name || '-'
  },
  sortFn(nodeA, nodeB) {
    const ownerNameA = nodeA.proto?.owner.id || ''
    const ownerNameB = nodeB.proto?.owner.id || ''
    if (ownerNameA > ownerNameB) return -1
    if (ownerNameA < ownerNameB) return 1
    return 0
  }
}, {
  name: 'Designator',
  key: 'referenceDesignator',
  fullKey: 'field.referenceDesignator',
  type: 'field',
  valueType: 'String',
  width: 100,
  align: 'left',
  notInFilters: true,
  displayFn(node) {
    return String(node.parentToNodeDependency?.referenceDesignator || '-')
  },
  filterValue(node) {
    return typeof node.parentToNodeDependency?.referenceDesignator === 'string' ? node.parentToNodeDependency.referenceDesignator : null
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.parentToNodeDependency?.referenceDesignator || ''
    const b = nodeB.parentToNodeDependency?.referenceDesignator || ''
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'CAD Rev',
  key: 'cadRev',
  fullKey: 'field.cadRev',
  valueType: 'String',
  type: 'field',
  width: 50,
  align: 'center',
  displayFn(node) {
    return String(node.part.cadRev || '-')
  },
  filterValue(node) {
    return node.part.cadRev || null
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.cadRev || ''
    const b = nodeB.part.cadRev || ''
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'Off the shelf',
  key: 'isOffTheShelf',
  fullKey: 'field.isOffTheShelf',
  valueType: 'Boolean',
  type: 'field',
  width: 90,
  align: 'center',
  displayFn(node) {
    return humanBoolean(node.part.isOffTheShelf)
  },
  filterValue(node) {
    return node.part.isOffTheShelf
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.isOffTheShelf
    const b = nodeB.part.isOffTheShelf
    if (a && !b) return 1
    if (!a && b) return -1
    return 0
  }
}]

const mergeIntoBaseColumns = (passedColumns?: PartHierarchyHeaderColumn[]): PartHierarchyHeaderColumn[] => {
  return baseColumns.map(c => {
    if (!passedColumns) return c
    const conf = passedColumns.find(conf => conf.type === c.type && conf.key === c.key)
    if (!conf) return c
    return {
      ...c,
      ...conf
    }
  })
}

export type ToolBarMode = 'agg' | null

export const useHierarchyControllerFactory = (properties: HierarchyControllerInput): HierarchyController => {
  const orgId = useParams().orgId!
  const appContext = useAppContext()
  const { data } = usePartsCache()
  const protosByPn = keyBy(data?.protos, 'partNumber')
  const { fullScreen, showAggregations, omitColumns, useUrlForFilters, initialFilters } = properties

  const filterController = useHierarchyFilters({ useURL: useUrlForFilters, initialFilters })

  const metadataSchema = useMetadataSchema()!
  const metadataCols: PartHierarchyHeaderColumn[] = Object.entries(metadataSchema).map(([key, m]) => {
    return {
      name: m.displayName,
      key: key,
      align: 'left',
      type: 'metadata',
      fullKey: `metadata.${key}`,
      valueType: m.type,
      width: Math.max(10 * m.displayName.length, 100),
      displayFn(n) {
        return n.part.resolvedMetadata[key]?.displayValue || '-'
      },
      filterValue(n) {
        return n.part.resolvedMetadata[key]?.entry || null
      },
      sortFn(nodeA, nodeB) {
        // pretty dumb, but could be specified in the metadata resolver
        // to sort different types
        const a = nodeA.part.resolvedMetadata[key]?.displayValue || '-'
        const b = nodeB.part.resolvedMetadata[key]?.displayValue || '-'
        if (a > b) return -1
        if (a < b) return 1
        return 0
      }
    }
  })

  const allColumns = [...mergeIntoBaseColumns(properties.columns), ...metadataCols]
  const filteredColumns = [...allColumns].filter(col => {
    if (col.hide) return false
    if (omitColumns?.find(c => c.key === col.key && c.type === col.type)) {
      return false
    }
    return (fullScreen || col.showNonFullscreen)
  })

  const defaultSort: SortBy = {
    key: 'partNumberName',
    type: 'field',
    direction: 'asc'
  }
  const [sortBy, setSortBy] = useState<SortBy>(defaultSort)
  const sortColumn = allColumns.find(c => c.key === sortBy.key && c.type === sortBy.type)!

  const { aggregationConfigs } = appContext.currentOrg
  const exchangeRates = appContext.currentOrg.exchangeRates as ExchangeRates


  const savedAggregators = useMemo(() =>
    (aggregationConfigs || []).map(c => {
      const reducerInput = {
        metadataSchema,
        name: c.name,
        exchangeRates,
        metadata: c.metadata as NonNullable<typeof c.metadata>,
        sources: c.sources as NonNullable<typeof c.sources>
      }
      if (c.reducer === 'Sum') {
        const targetType = c.targetType as NumericTarget
        return SumAggregator({
          targetType,
          ...reducerInput
        })
      }
      if (c.reducer === 'Max') {
        const targetType = c.targetType as NumericTarget
        return MaxAggregator({
          targetType,
          ...reducerInput
        })
      }
      if (c.reducer === 'And') {
        const targetType = c.targetType as unknown as BooleanTarget
        return AndAggregator({
          targetType,
          ...reducerInput
        })
      }
      throw new Error('No reducer known for ' + c.reducer)
    }
    ) ?? [],
    [aggregationConfigs]
  )


  type ExpandedNodes = Record<number, Record<string, boolean>>

  // this should include a calculation on whether there is a filter in
  // hierarchy view, but the order of things here would mean a refactor
  // to do that correctly
  const initiallyExpandedNodes = properties.roots.reduce((rootsExpanded, root, i) => {
    const hash = Object.fromEntries((root.initiallyExpandedNodes || []).map(h => [h, true]))
    return {
      ...rootsExpanded,
      [i]: hash
    }
  }, {} as ExpandedNodes)
  const [expandedNodesByRoot, setExpandedByRoot] = useState<ExpandedNodes>(initiallyExpandedNodes)

  const [selectMode, setSelectMode] = useState(false)

  type SelectedNodes = Record<number, string[]>
  const [selectedNodesByRoot, setSelectedByRoot] = useState<SelectedNodes>({})

  const selectAll = () => {
    const allNodes = properties.roots.reduce((allNodes, root, i) => {
      const nodeIndexes = root.tree.flatMap(n => {
        const gid = n.parentToNodeDependency?.groupId
        if (gid) return [n.hierarchy, gid]
        return n.hierarchy
      })
      return {
        ...allNodes,
        [i]: nodeIndexes
      }
    }, {} as SelectedNodes)
    setSelectedByRoot(allNodes)
  }
  const unselectAll = () => setSelectedByRoot({})

  const [aggregators, setAggregators] = useState<ActivatableAggregator[]>(
    (savedAggregators ?? []).map((a, index) => ({ ...a, index, savedIndex: index, isActive: true }))
  )

  const [targetCurrency, setTargetCurrency] = useState<NumericTarget | undefined>()

  const handleSetSelectNodesMode = (flag: boolean) => {
    if (!flag) {
      unselectAll()
      setSelectMode(false)
      return
    }

    selectAll()
    setSelectMode(true)
  }

  const [addToChangeOrderMutation] = useMutation<
    AddPartHierarchyToChangeOrderMutation,
    AddPartHierarchyToChangeOrderMutationVariables
  >(ADD_TO_CHANGE_ORDER_MUTATION)

  const addToChangeOrder = async (changeOrderNumber: number, input: AddPartHierarchyToChangeOrderMutationVariables['input']) => {
    const variables: AddPartHierarchyToChangeOrderMutationVariables = {
      changeOrderNumber,
      input
    }
    const { errors } = await addToChangeOrderMutation({
      variables: variables,
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error adding part to change order`
      })
      return
    }
  }

  const appliedFilters = filterController.filters.map(f => {
    return {
      ...f,
      column: allColumns.find(c => c.fullKey === f.key)!,
      filterSpec: allFilters.find(filter => filter.key === f.type)!
    }
  })

  const roots = properties.roots.map((root, rootIndex) => {

    const rootNode = root.tree.find(n => n.isTreeRoot)!

    const calcFilter = (controllerNode: ControllerTreeNode) => {
      let filterRemove = false
      for (const filter of appliedFilters) {
        filterRemove = filterRemove || filter.filterSpec.filterRemoveNode(filter.column.filterValue(controllerNode), filter.value)
      }
      return filterRemove
    }
    const controllerTree = root.tree.map(node => {
      const { parentToNodeDependency } = node

      // is root of tree
      if (!parentToNodeDependency) {
        const previousRootVersion = node.previousVersion ? <>
          <div>{node.previousVersion}</div>
          <div>→</div>
        </> : null

        const controllerRoot: ControllerTreeNode = {
          ...node,
          proto: protosByPn[node.part.partNumber],
          part: {
            ...node.part,
            resolvedMetadata: resolveMetadataObj(metadataSchema, node.part.metadata as Record<string, MetadataValue>),
          },
          versionDisplay: <div className='flex items-center gap-1'>
            {previousRootVersion}
            <div>{node.part.version}</div>
          </div>
        }
        return {
          ...controllerRoot,
          filterRemove: calcFilter(controllerRoot)
        }
      }

      // only show this icon when the version range changes
      const alwaysUpdateIcon = !node.previousVersionRange || node.previousVersionRange === parentToNodeDependency.toVersionRange ? null : <AlwaysUpdateIcon />

      const versionIcon = parentToNodeDependency.toVersionRange === '*' ?
        alwaysUpdateIcon : <PinnedIcon version={node.part.version as string} />

      const versionChange = () => {
        if (!node.previousVersion) return
        if (!node.previousVersionRange) return
        if (!parentToNodeDependency) throw Error('Dependency should be there')
        if (node.previousVersion === node.part.version &&
          node.previousVersionRange === parentToNodeDependency.toVersionRange) return

        const versionIcon = node.previousVersionRange === '*' ?
          alwaysUpdateIcon : <PinnedIcon version={node.previousVersion} />

        return <>
          <div>{node.previousVersion}</div>
          {versionIcon}
          <div>→</div>
        </>
      }

      const controllerNode = {
        ...node,
        proto: protosByPn[node.part.partNumber],
        part: {
          ...node.part,
          resolvedMetadata: resolveMetadataObj(metadataSchema, node.part.metadata as Record<string, MetadataValue>),
        },
        versionDisplay: <div className='flex items-center gap-1'>
          {versionChange()}
          <div>{node.part.version}</div>
          {versionIcon}
        </div>
      }

      return {
        ...controllerNode,
        filterRemove: calcFilter(controllerNode)
      }

    })

    let filterAncestors: Record<string, boolean> = {}
    const treeByLevel = keyBy(controllerTree, 'hierarchy')
    controllerTree.forEach(n => {
      if (!n.filterRemove) {
        const levels = n.hierarchy.split('.')
        for (let i = 1; i < levels.length; i++) {
          const ancestorLevel = levels.slice(0, i).join('.')
          const ancestorNode = treeByLevel[ancestorLevel]!
          treeByLevel[ancestorLevel] = {
            ...ancestorNode,
            filterRemove: false
          }
          // expand ancestors if filter and they match
          if (filterController.filters.length > 0) {
            filterAncestors[ancestorLevel] = true
          }
        }
      }
    })

    const finalTree = Object.values(treeByLevel)

    const controllerRoot = finalTree.find(n => n.isTreeRoot)!

    const groupsWithSelection = keyBy(
      finalTree.filter(n => {
        return (!n.filterRemove && n.parentToNodeDependency?.groupId)
      })
      , n => n.parentToNodeDependency?.groupId
    )

    const selectedNodes = selectedNodesByRoot[rootIndex]?.filter(n => {
      if (groupsWithSelection[n]) {
        return true
      }
      return !treeByLevel[n]?.filterRemove
    }) || []
    const setSelectedNodes = (nodes: string[]) => {
      setSelectedByRoot({
        ...selectedNodesByRoot,
        [rootIndex]: nodes
      })
    }
    const selectedByLevel = keyBy(selectedNodes)
    const filteredHierarchy = selectMode ? controllerTree.filter(n => selectedByLevel[n.hierarchy]) : controllerTree
    const hierarchyTree = buildHierarchyTree(controllerRoot, filteredHierarchy)
    const aggregations = buildAggregationTree(hierarchyTree, aggregators, { targetOverride: targetCurrency })

    return {
      ...root,
      rootIndex,
      rootNode: controllerRoot,
      expandedNodes: expandedNodesByRoot[rootIndex] || {},
      setExpanded: (id: string, expanded: boolean) => {
        setExpandedByRoot({
          ...expandedNodesByRoot,
          [rootIndex]: {
            ...expandedNodesByRoot[rootIndex],
            [id]: expanded
          }
        })
      },
      hierarchyTree,
      tree: finalTree,
      treeByLevel,
      aggregations,
      onHierarchyModeClick: properties.onHierarchyModeClick,
      selectedNodes: selectedNodes ?? [],
      setSelectedNodes: setSelectedNodes,
      subjectParts: root.subjectParts ?? [],
      rootPart: rootNode,
    }
  }).sort((rootA, rootB) => {
    return sortColumn.sortFn(rootA.rootNode, rootB.rootNode)
  })

  if (sortBy.direction === 'asc') roots.reverse()

  useEffect(() => {
    // if no filters, we don't change expanded state
    if (filterController.filters.length === 0) {
      return
    }

    const nextExpanded = roots.reduce((expandedByRoot, root, i) => {
      let expandedNodes = root.expandedNodes
      root.tree.forEach(n => {
        if (!n.filterRemove) {
          const levels = n.hierarchy.split('.')
          for (let i = 1; i < levels.length; i++) {
            const ancestorLevel = levels.slice(0, i).join('.')
            // expand ancestors of matching nodes
            expandedNodes[ancestorLevel] = true
          }
          if (n.parentToNodeDependency?.groupId) {
            expandedNodes[n.parentToNodeDependency.groupId] = true
          }
        }
      })
      return {
        ...expandedByRoot,
        [i]: expandedNodes
      }
    }, expandedNodesByRoot)
    setExpandedByRoot(nextExpanded)
  }, [filterController.filters, properties.hierarchyMode, properties.roots.length])

  const getSelectedUniqueParts = () => {
    let selectPartsByPn: Record<string, PartTreeNodeFragment> = {}
    for (const root of roots) {
      const selectedByLevel = keyBy(root.selectedNodes)
      const filteredHierarchy = selectMode ? root.tree.filter(n => selectedByLevel[n.hierarchy]) : root.tree
      const selected = keyBy(filteredHierarchy, 'part.partNumber')
      selectPartsByPn = {
        ...selectPartsByPn,
        ...selected,
      }
    }
    return selectPartsByPn
  }

  const createChangeOrderWithSelection = () => {
    const selectPartsByPn = getSelectedUniqueParts()
    const opaquePartNumbers = Object.keys(selectPartsByPn).map(pn => btoa(pn)).join(',')

    navigate(routes.changeOrderCreate({ orgId, withPartNumbers: opaquePartNumbers }))
  }

  const addSelectionToChangeOrder = async (changeOrderNumber: number) => {
    const selectPartsByPn = getSelectedUniqueParts()
    const pushes = Object.values(selectPartsByPn).map(node => {
      return {
        type: 'Push' as const,
        partNumber: node.part.partNumber,
        version: calculateNextVersion(node.part.proto.currentVersionString!),
      }
    })

    await addToChangeOrder(changeOrderNumber, pushes)

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: changeOrderNumber, tab: 'changes' })}`)
  }

  const [mode, setToolbarMode] = useState<ToolBarMode>(null)
  const [selectedAggIdx, setSelectedAggIdx] = useState<number | null>(null)

  useEffect(() => {
    if (!showAggregations) {
      setToolbarMode(null)
      setSelectedAggIdx(null)
    }
  }, [showAggregations])

  useEffect(() => {
    if (mode === 'agg' && aggregators.length === 0) {
      createLocalAggregator()
    }
  }, [mode, aggregators])

  const nameSeed = useRef<number>(0)

  const createLocalAggregator = () => {
    const newAgg: EditableAggregator = {
      ...SumAggregator({
        name: `New Aggregator ${++nameSeed.current}`,
        exchangeRates,
        targetType: {
          type: 'Price',
          unit: 'USD'
        },
        metadata: [],
        sources: []
      }),
      isDirty: true,
      isNew: true,
      isActive: true,
      index: aggregators.length,
    }

    setToolbarMode('agg')
    setAggregators(aggregators.concat(newAgg))
    setSelectedAggIdx(aggregators.length)
  }

  const getSelectedAgg = () => selectedAggIdx === null ? null
    : aggregators[selectedAggIdx] as EditableAggregator
  const selectedAgg = getSelectedAgg()

  const setSelectedAgg = (aggIndex?: number | null) => {
    setSelectedAggIdx(aggIndex ?? null)
  }

  const { columns, ...passThroughProps } = properties
  return Object.seal({
    ...passThroughProps,
    roots,
    hierarchyMode: properties.hierarchyMode || 'hierarchy',
    exchangeRates: exchangeRates as ExchangeRates,
    allColumns,
    filteredColumns,
    sortBy,
    sortColumn,
    setSortBy,
    resetSort() {
      setSortBy(defaultSort)
    },
    fullScreen: Boolean(fullScreen),
    showAggregations: Boolean(properties.showAggregations),
    onToggleFullscreen: properties.onToggleFullscreen,
    selectedAgg,
    setSelectedAgg,
    savedAggregators,
    selectable: selectMode,
    setSelectable: handleSetSelectNodesMode,
    createLocalAggregator,
    toolbarMode: fullScreen ? mode : null,
    setToolbarMode(mode) {
      setToolbarMode(mode)
      if (properties.onToolbarModeChange) {
        properties.onToolbarModeChange(mode)
      }
    },
    aggregators: aggregators,
    setAggregators,
    targetCurrency,
    setTargetCurrency,
    addSelectionToChangeOrder,
    createChangeOrderWithSelection,
    selectAll,
    unselectAll,
    filterController,
  })
}

type HierarchyContextInput = {
  hierarchyController: HierarchyController,
  root: HierarchyController['roots'][number],
  columnWidths: Record<string, number>
  nameColumnWidth: number
} | null
export const HierarchyControllerContext = createContext<HierarchyContextInput>(null)

const quantityAndUnit = (quantity?: number, units?: string) => {
  if (typeof quantity === 'undefined') return '-'
  units = units || 'each'
  const unit = (['each', 'units']).includes(units) ? '' : ` ${units}`
  return `${quantity}${unit}`
}
