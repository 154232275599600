import type {
  ImportPreviewQuery,
  ImportPartsMutation,
  ImportPartsMutationVariables,
  ImportCreateProjectMutation,
  ImportCreateProjectMutationVariables,
  PartDeltaInput,
  LivePartProto,
  Part
} from 'types/graphql'

import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_QUERY } from 'src/lib/queries'
import { navigate, routes, useParams } from '@redwoodjs/router'

import { ImportedPart, getMapper } from 'src/lib/mapping'
import Button from 'src/components/Button'
import type { CellSuccessProps } from '@redwoodjs/web'
import { useMemo, useRef, useState } from 'react'
import { SelectedProject } from 'src/components/ProjectSelectCell'
import { PlusIcon, MinusIcon } from '@heroicons/react/16/solid'
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import { reportMutationError } from 'src/lib/reportError';
import classNames from 'classnames'
import { useMutation } from '@redwoodjs/web'
import { findPartParent } from 'api/src/lib/bom'
import { Form, TextField } from '../Form'
import { MetadataValue, useMetadataSchema } from 'src/lib/metadata'
import { useAppContext } from 'src/lib/appContext'
import { nullControlSeq } from 'shared/types'

import * as calculateImport from './calculateImport'
import type { Change, ChangeGroup, MapperSource } from './calculateImport'

export const QUERY = gql`
query ImportPreviewQuery ($partNumbers: [String!]!, $orderNumber: Int!) {
  partCategories {
    id
    name
    hasCadRev
  }
  currentOrg {
    distributors {
      id
      name
    }
  }
  changeOrder(number: $orderNumber) {
    includedParts {
      version
      cadRev
      name
      summary
      isOffTheShelf
      metadata
      sources {
        distributorId
        distributorSku
        url
      }
      artifacts {
        filename
        importId
        fileId
        isThumbnail
      }
      dependencies {
        section
        to {
          version
          name
          summary
          partNumber
          cadRev
          isOffTheShelf
          metadata
          proto {
            category {
              id
            }
          }
        }
        toVersionRange
        quantity
        groupId
        referenceDesignator
        units
      }
      proto {
        partNumber
        project {
          id
        }
        category {
          id
        }
      }
    }
  }
  partProtos(partNumbers: $partNumbers) {
    partNumber
    project {
      id
    }
    category {
      id
    }
    currentVersion {
      isOffTheShelf
      version
      publishId
      cadRev
      name
      summary
      metadata
      sources {
        distributorId
        distributorSku
        url
      }
      artifacts {
        filename
        importId
        fileId
        isThumbnail
      }
      dependencies {
        section
        to {
          version
          name
          partNumber
          summary
          cadRev
          isOffTheShelf
          metadata
          sources {
            distributorId
            distributorSku
            url
          }
          artifacts {
            filename
            importId
            fileId
          }
          proto {
            category {
              id
            }
          }
        }
        toVersionRange
        quantity
        groupId
        referenceDesignator
        units
      }
    }
  }
}
`

export const IMPORT_PARTS_MUTATION = gql`
mutation ImportPartsMutation ($changeOrderNumber: Int!, $rootPartNumber: String!, $input: [PartDeltaInput!]!) {
  addPartDeltas(isImport: true, rootPartNumber: $rootPartNumber, changeOrderNumber: $changeOrderNumber, input: $input) {
    partNumber
  }
}
`

// todo (maybe) - add this into the addPartDeltas mutation on the server side
export const INPORT_CREATE_PROJECT_MUTATION = gql`
  mutation ImportCreateProjectMutation (
    $rootPartNumber: String!
    $link: String
    $name: String!
    $defaultMapper: String!
  ) {
    createProject(input: {
      rootPartNumber: $rootPartNumber
      link: $link
      name: $name
      defaultMapper: $defaultMapper
    }) {
      id
    }
  }
`

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />


import GenericFailure from '../Failure/Failure'
import { useErrorModal } from 'src/lib/useErrorModal'
export const Failure = GenericFailure

type CellProps = {
  selectedMapper: string
  importedBom: ImportedPart[]
  orderNumber: number
  project: SelectedProject
  onClose: (afterClose?: () => void) => void
  onBack: () => void
} & CellSuccessProps<ImportPreviewQuery>
export const Success = ({
  selectedMapper,
  partCategories,
  partProtos: queryPartProtos,
  changeOrder: queryChangeOrder,
  currentOrg,
  importedBom,
  project,
  onBack,
  onClose, orderNumber
}: CellProps) => {

  // prevent apollo updating the component via the cache so that
  // it doesn't change while closing the modal
  const partProtos = useRef(queryPartProtos).current
  const changeOrder = useRef(queryChangeOrder).current

  const orgId = useParams().orgId!
  const [selectedPartChanges, setSelectedChanges] = useState<string[]>([])
  const [selectedChangesPage, setSelectedChangesPage] = useState(1)
  const [unselectedChangesPage, setUnselectedChangesPage] = useState(1)
  const metadataSchema = useMetadataSchema()!

  const [importParts, { error: importPartsMutationError }] = useMutation<
    ImportPartsMutation,
    ImportPartsMutationVariables
  >(IMPORT_PARTS_MUTATION, { errorPolicy: 'none' })

  const [createProject, { error: createProjectMutationError }] = useMutation<
    ImportCreateProjectMutation,
    ImportCreateProjectMutationVariables
  >(INPORT_CREATE_PROJECT_MUTATION, { errorPolicy: 'all' })

  const { showError, errorModal } = useErrorModal()

  const [loading, setLoading] = useState(false)

  const rootPartNumber = project.rootPartNumber

  type PartProto = Omit<LivePartProto, 'currentVersion'> & {
    currentVersion?: Omit<Part, 'metadata'> & {
      metadata: any
    }
  }
  const normalizedHeadParts = calculateImport.normalizeHeadParts(
    importedBom,
    // @ts-ignore
    partProtos as PartProto[],
    changeOrder.includedParts,
    rootPartNumber,
  )

  const headRootPart = normalizedHeadParts.find(p => p.proto.partNumber === rootPartNumber)

  const newRootImport = project.newProject || !headRootPart

  const mapper = getMapper(orgId, selectedMapper)

  if (!mapper) {
    throw new Error('No mapper found')
  }

  const completeInputBom = useMemo(() => {
    return calculateImport.completeInputBom({
      importedBom,
      normalizedHeadParts,
    })
  }, [importedBom, normalizedHeadParts])

  // prevent UI freezing for large imports
  const allChanges = useMemo(() => {
    const all = calculateImport.findChanges({
      rootPartNumber,
      completeInputBom,
      normalizedHeadParts,
      importOutputFields: mapper.importOutputFields
    })
    return all
  }, [rootPartNumber, completeInputBom, changeOrder.includedParts])

  const refChanges = allChanges.filter(p => {
    return p.changeSubject === 'reference' || (p.node.hierarchy.split('.').length === 1)
  })

  const hidePartChanges = true

  let firstChange
  const changeGroups = calculateImport.groupChanges(rootPartNumber, refChanges)
  for (const cg of changeGroups) {
    if (cg.id === null) continue
    firstChange = cg.changes[0]!
    break
  }
  if (!firstChange) firstChange = refChanges[0]
  const [selectedChangeKey, setSelectedChangeKey] = useState(firstChange?.key)
  const selectedChange = selectedChangeKey ? refChanges.find(c => c.key === selectedChangeKey) : null

  // top level components need to be here
  const noChanges = refChanges.length === 0

  const appContext = useAppContext()

  const todo = () => {
    if (noChanges) return 'There are no changes to import'
    if (selectedPartChanges.length === 0) return 'Select some changes to import'
    return ''
  }
  const todoMessage = todo()

  type FormData = {
    projectCadrev?: string
  }
  const handleImportClick = async (formData: FormData) => {
    if (todoMessage) return

    // ensure import calculations don't freeze the loading state
    setLoading(true)
    setImmediate(() => importToChangeOrder(formData))
  }
  const importToChangeOrder = async (formData: FormData) => {
    const { projectCadrev } = formData

    const allSelectedChanges = selectedPartChanges.flatMap(k => {
      const change = allChanges.find(c => c.key === k)!
      const partAddLinks = change.onAddLinks.filter(l => {
        const change = allChanges.find(c => c.key == l)!
        return change.changeSubject === 'part'
      })
      return [change.key, ...partAddLinks]
    })

    let partDeltas = calculateImport.deltas(allSelectedChanges, {
      rootPartNumber,
      normalizedHeadParts,
      completeInputBom,
      allChanges,
      importOutputFields: mapper.importOutputFields,
      defaultPartVersion: appContext.currentOrg.defaultPartVersion
    })

    const rootPartUpdates: Partial<PartDeltaInput> = {}
    if (projectCadrev) {
      rootPartUpdates.part = { cadRev: projectCadrev }
    }

    if (newRootImport) {
      rootPartUpdates.part = {
        ...rootPartUpdates.part,
        name: project.name,
        isRoot: Boolean(mapper.rootIsTopLevel)
      }
      rootPartUpdates.partNumber = project.rootPartNumber!
      rootPartUpdates.categoryId = project.categoryId
    }

    partDeltas = partDeltas.map(delta => {
      if (delta.partNumber !== rootPartNumber) return delta

      return {
        ...delta,
        ...rootPartUpdates,
        categoryId: delta.type === 'Create' ? rootPartUpdates.categoryId : undefined,
        part: {
          ...delta.part,
          ...rootPartUpdates.part
        }
      }
    }).filter(d => {
      return d.partNumber !== '__none__'
    })

    const inputType: ImportPartsMutationVariables['input'] = partDeltas.map(d => {
      return {
        ...d,
       part: {
         ...d.part,
         dependencies: d.part?.dependencies ?
           d.part.dependencies.map(d => ({
             ...d,
             groupId: d.groupId ?? nullControlSeq,
             referenceDesignator: d.referenceDesignator ?? nullControlSeq,
           }))
         : undefined
       }
      }
    })

    const importVariables: ImportPartsMutationVariables = {
      changeOrderNumber: orderNumber,
      rootPartNumber,
      input: inputType,
    }
    const refetch = {
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber } }
      ],
      awaitRefetchQueries: true
    }

    try {
      await importParts({
        variables: importVariables,
        ...(project.newProject ? null : refetch)
      })
    } catch (e) {
      const eventId = reportMutationError({
        errors: [e],
        variables: importVariables,
        message: `Failed to import project`
      })
      setLoading(false)
      showError({
        sentryEventId: eventId,
        title: 'Error Importing Project',
        subtitle: 'There was an error while importing parts',
        detail: 'No changes were applied due to the failed import.'
      })
      return
    }

    if (!project.newProject) {
      setLoading(false)
      // navigate(routes.changeOrderTab({ tab: 'changes', orderNumber, orgId }))
      // navigate will not close the modal if the tab is already changes
      onClose(() => navigate(routes.changeOrderTab({ tab: 'changes', orderNumber, orgId })))
      return
    }

    const createPrjectVariables: ImportCreateProjectMutationVariables = {
      defaultMapper: mapper.name,
      rootPartNumber,
      name: project.name,
      link: project.link
    }

    try {
      await createProject({
        variables: createPrjectVariables,
        ...refetch
      })
    } catch (e) {
      const eventId = reportMutationError({
        errors: [e],
        variables: importVariables,
        message: `Failed to create project`
      })
      setLoading(false)
      showError({
        sentryEventId: eventId,
        title: 'Error Creating Project',
        subtitle: 'There was an error while creating a project',
        detail: `All changes were applied, however the root part was not labeled as a project due to an unknown failure. You can label the
        root part as a project after the change order is applied, or contact support to do it for you.`
      })
      return
    }
    setLoading(false)

    onClose(() => navigate(routes.changeOrderTab({ tab: 'changes', orderNumber, orgId })))
  }

  const renderSelectedPart = () => {
    if (!selectedChange) {
      return null
    }
    const parent = findPartParent(importedBom, selectedChange.node.hierarchy) || { name: project.name, partNumber: rootPartNumber }
    const fullHierarchy = () => {
      if (selectedChange.changeSubject === 'part') return null
      if (parent.name === '__none__') return null
      return <div className='gap-2 flex flex-col'>
        <div className='inline-flex'>
          {parent.name} &gt;
        </div>
        <div className='border-gray-800 pl-0.5 inline-flex'>{selectedChange.node.name}</div>
      </div>
    }
    const revision = () => {
      let headRev: string | undefined | null
      if ('headDependencyData' in selectedChange && selectedChange.headDependencyData) {
        headRev = selectedChange.headDependencyData.to.cadRev
      }
      if ('head' in selectedChange && selectedChange.head && selectedChange.head.cadRev !== selectedChange!.node.cadRev) {
        headRev = selectedChange.head.cadRev
      }
      if (headRev && headRev !== selectedChange!.node.cadRev) {
        return `${headRev} → ${selectedChange!.node.cadRev}`
      }
      return selectedChange!.node.cadRev
    }

    const summary = () => {
      let headSummary: string | undefined | null
      if (!selectedChange!.node.summary) return null
      if ('headDependencyData' in selectedChange && selectedChange.headDependencyData) {
        headSummary = selectedChange.headDependencyData.to.summary
      }
      if ('head' in selectedChange && selectedChange.head && selectedChange.head.summary !== selectedChange!.node.summary) {
        headSummary = selectedChange.head.summary
      }
      let summary = ''
      if (headSummary && headSummary !== selectedChange!.node.summary) {
        summary = `${headSummary} → ${selectedChange!.node.summary}`
      } else {
        summary = selectedChange!.node.summary
      }
      return <div className='flex flex-col gap-2'>
        <div className='font-bold'>
          Summary
        </div>
        <div>{summary}</div>
      </div>
    }

    const name = () => {
      let headName: string | undefined | null
      if ('headDependencyData' in selectedChange && selectedChange.headDependencyData) {
        headName = selectedChange.headDependencyData.to.name
      }
      if ('head' in selectedChange && selectedChange.head) {
        headName = selectedChange.head.name
      }
      if (headName && headName !== selectedChange!.node.name) {
        return `${headName} → ${selectedChange!.node.name}`
      }
      return selectedChange!.node.name
    }
    const quantity = () => {
      if (selectedChange.changeSubject !== 'reference' || selectedChange!.changeType !== 'modify') {
        return `${selectedChange.node.quantity} ${selectedChange.node.units}`
      }
      const incomingQUnit = `${selectedChange.node.quantity} ${selectedChange.node.units}`
      const headQUnit = `${selectedChange.headDependencyData!.quantity} ${selectedChange.headDependencyData!.units}`
      if (headQUnit === incomingQUnit) {
        return incomingQUnit
      }
      return `${headQUnit} → ${incomingQUnit}`
    }
    const refDes = () => {
      if (selectedChange.changeSubject !== 'reference' || selectedChange!.changeType !== 'modify') {
        return selectedChange.node.referenceDesignator
      }
      const headRefDes = selectedChange.headDependencyData!.referenceDesignator || ''
      if (headRefDes === selectedChange.node.referenceDesignator) {
        return selectedChange.node.referenceDesignator
      }
      if (!headRefDes) {
        if (!selectedChange.node.referenceDesignator) return null
        return `${selectedChange.node.referenceDesignator} (new)`
      }
      return `${headRefDes} → ${selectedChange.node.referenceDesignator || '(removed)'}`
    }
    const offTheShelf = () => {
      let headResponse: boolean | undefined | null
      if ('headDependencyData' in selectedChange && selectedChange.headDependencyData) {
        headResponse = selectedChange.headDependencyData.to.isOffTheShelf
      }
      if ('head' in selectedChange && selectedChange.head) {
        headResponse = selectedChange.head.isOffTheShelf
      }
      if (headResponse && headResponse !== selectedChange!.node.isOffTheShelf) {
        return `${displayBoolean(headResponse)} → ${displayBoolean(selectedChange!.node.isOffTheShelf)}`
      }
      return displayBoolean(selectedChange!.node.isOffTheShelf)
    }
    const artifacts = () => {
      if (!selectedChange.node.artifacts) return
      if (selectedChange.node.artifacts.length === 0) return
      return <div className='flex gap-2 flex-col'>
        <div className='font-bold'>Artifacts</div>
        <div>
          {
            selectedChange.node.artifacts.map(a => {
              return <div>{a.filename}</div>
            })
          }
        </div>
      </div>
    }
    const metadata = () => {
      let headResponse: Record<string, MetadataValue> | undefined
      if ('headDependencyData' in selectedChange && selectedChange.headDependencyData) {
        headResponse = selectedChange.headDependencyData.to.metadata
      }
      if ('head' in selectedChange && selectedChange.head) {
        headResponse = selectedChange.head.metadata
      }

      const newPart = !headResponse

      const renderMetadata = (incomingM: Record<string, MetadataValue>, headM: Record<string, MetadataValue>) => {
        return Object.entries(metadataSchema)
          .filter(([k, schema]) => {
            return mapper.importOutputFields.metadata.includes(k)
          })
          .map(([k, schema]) => {
            const renderMetadataValue = (v: MetadataValue) => {
              if (typeof v === 'object') {
                if ('value' in v) {
                  return `${v.value} ${v.unit}`
                }
                return v.toDateString()
              }
              return v
            }
            const headValue = renderMetadataValue(headM[k]!)
            const incomingValue = renderMetadataValue(incomingM[k]!)
            const display = () => {
              if (headValue && !incomingValue) {
                return <div className='line-through'>{headValue}</div>
              }
              if (newPart && incomingValue) {
                return incomingValue
              }
              if (!headValue && incomingValue) {
                return `${incomingValue} (new)`
              }
              if (headValue !== incomingValue) {
                return `${headValue} → ${incomingValue}`
              }
              return incomingValue
            }
            const content = display()
            if (!content) return null
            return <div className='flex flex-col gap-2' key={k}>
              <div className='font-bold'>Metadata - {schema.displayName}</div>
              <div className='flex-1 items-center flex'>{content}</div>
            </div>
          })
      }
      return renderMetadata(selectedChange!.node.metadata, headResponse || {})
    }

    const sources = () => {
      let headSources: MapperSource[] = []
      if ('head' in selectedChange && selectedChange.head) {
        headSources = selectedChange.head.sources
      }
      const newSources = calculateImport.findNewSources(
        headSources,
        selectedChange.node.sources || []
      )
      if (!newSources.length) return null

      return <div className='flex flex-col gap-3'>
        <div className='font-bold'>
          Sources
        </div>
        <div className='flex gap-2'>{newSources.map(s => {
          const fields = [
            { key: 'distributorId', name: 'Distributor' },
            { key: 'distributorSku', name: 'SKU' },
            { key: 'url', name: 'Link' }
          ] as ({ key: keyof MapperSource, name: string })[]
          return <div
            className='border border-gray-500 rounded-md flex gap-4 bg-white text-gray-700'
            key={`${s.distributorId}-${s.distributorSku}`}>
            <div className='bg-green-300 rounded-l-md p-4 flex items-center font-medium'>New Source</div>
            <div className='p-4 flex flex-col gap-2'>
              {
                fields.map(({ key, name }) => {
                  let value = s[key]
                  if (!value) return null
                  if (key === 'distributorId') {
                    const distributorName = currentOrg.distributors.find(d => d.id === value)?.name
                    value = distributorName ? distributorName : 'DISTRIBUTOR NOT FOUND'
                  }
                  if (key === 'url') {
                    return <div className='flex gap-2' key={key}>
                      <div className='font-semibold'>{name}</div>
                      <a className=' overflow-hidden w-72 text-blue-600 text-ellipsis' href={value} target='_blank'>{value}</a>
                    </div>
                  }
                  return <div className='flex gap-2' key={key}>
                    <div className='font-semibold'>{name}</div>
                    <div>{value}</div>
                  </div>
                })
              }
            </div>
          </div>
        })}</div>
      </div>
    }

    const info = () => {
      if (selectedChange.changeType === 'create') {
        return 'This part has been created'
      }
      if (selectedChange.changeType === 'dereference') {
        return 'This part is no longer being referenced' + (parent.partNumber !== '__none__' ? ` in assembly #${parent.partNumber}` : '')
      }

      // let partChangeInfo = selectedChange.changeSubject === 'part' && selectedChange.changeType === 'modify' ? 'modified and ' : ''

      if (selectedChange.changeType === 'reference') {
        return `This part has been newly referenced` + (parent.partNumber !== '__none__' ? ` in assembly #${parent.partNumber}` : '')
      }

      if (selectedChange.changeSubject === 'part') {
        return `This part has been modified`
      }

      return 'This part reference has been updated' + (parent.partNumber !== '__none__' ? ` in assembly #${parent.partNumber}` : '')
    }

    const color = () => {
      if (selectedChange.changeType === 'create' || selectedChange.changeType === 'reference') return 'green'
      if (selectedChange.changeType === 'modify') return 'yellow'
      return 'red'
    }
    const cardColor = color()
    const headerClassName = classNames('py-5 px-6 flex flex-col gap-4', {
      'bg-green-200': cardColor === 'green',
      'bg-yellow-100': cardColor === 'yellow',
      'bg-red-200': cardColor === 'red',
    })

    const cardClassName = classNames('flex-1 overflow-scroll', {
      'bg-green-50': cardColor === 'green',
      'bg-yellow-50': cardColor === 'yellow',
      'bg-red-50': cardColor === 'red',
    })

    const title = () => {
      if (selectedChange.changeType === 'create') return 'Create'
      if (selectedChange.changeType === 'dereference') return 'Dereference'
      if (selectedChange.changeType === 'reference') return 'New reference'
      if (selectedChange.changeSubject === 'part') return 'Modify'
      return 'Update reference'
    }

    const thumbnail = () => {
      if (selectedChange.node.artifacts?.[0]?.previewUrl) {
        return <div className='flex items-center flex-col mb-6'>
          <img className='max-w-96' src={selectedChange.node.artifacts[0].previewUrl} />
        </div>
      }
    }

    const partCategory = partCategories.find(c => c.id === selectedChange.node.categoryId)
    if (!partCategory) throw new Error(`No category found with ID: ${selectedChange.node.categoryId}, row ${JSON.stringify(selectedChange.node, null, 2)}`)

    const referenceDesignator = refDes()
    return <div className={cardClassName}>
      <div className={headerClassName}>
        <div>
          {title()} <span className='font-bold'>#{selectedChange.node.partNumber}</span> <span className='italic'>{selectedChange.node.name}</span>
        </div>
        <div className='text-sm flex gap-2 flex-col'>
          <div className='text-sm flex gap-1 text-gray-800 font-semibold'>
            {info()}
          </div>
          {fullHierarchy()}
        </div>
      </div>
      <div className='py-5 px-6'>
        <div className='flex flex-col gap-4'>
        {
              thumbnail()
            }
          <div className='flex gap-12'>
            <div className='flex flex-col gap-2'>
              <div className='font-bold'>Part Number</div>
              <div className='flex-1 items-center flex'>{selectedChange.node.partNumber}</div>
            </div>

            {
              partCategory.hasCadRev && <div className='flex flex-col gap-2'>
                <div className='font-bold'>
                  Revision
                </div>
                <div>{revision()}</div>
              </div>
            }
            <div className='flex flex-col gap-2'>
              <div className='font-bold'>
                Quantity
              </div>
              <div>{quantity()}</div>
            </div>
            {
              referenceDesignator && <div className='flex flex-col gap-2'>
                <div className='font-bold'>
                  Reference Designator
                </div>
                <div>{refDes()}</div>
              </div>
            }
          </div>
          <div className='flex flex-col gap-2'>
            <div className='font-bold'>
              Name
            </div>
            <div>{name()}</div>
          </div>
          <div className='flex gap-12'>
            <div className='flex flex-col gap-2'>
              <div className='font-bold'>Category</div>
              {partCategory.name}
            </div>
            <div className='flex flex-col gap-2'>
              <div className='font-bold'>
                Is off the shelf?
              </div>
              <div>{offTheShelf()}</div>
            </div>
          </div>
          {summary()}
          <div className='flex gap-2 flex-col'>
            {metadata()}
          </div>
          {sources()}
          {artifacts()}
        </div>
      </div>
    </div>
  }

  const renderChangeNavItem = (change: Change, selected: boolean) => {
    const { node } = change
    const changeSymbol = () => {
      if (change.changeSubject === 'part' && change.changeType === 'create') {
        return <div className='px-2 bg-green-200 text-green-500'>C</div>
      }
      if (change.changeSubject === 'reference' && change.changeType === 'reference') {
        return <div className='px-2 bg-green-200 text-green-500'>R</div>
      }
      if (change.changeSubject === 'reference' && change.changeType === 'dereference') {
        return <div className='px-2 bg-red-200 text-red-500'>D</div>
      }
      if (change.changeSubject === 'reference' && change.changeType === 'modify') {
        return <div className='px-2 bg-yellow-200 text-yellow-500'>U</div>
      }
      return <div className='px-2 bg-yellow-200 text-yellow-500'>M</div>
    }

    const leftIcon = () => {
      if (change.changeSubject === 'part') return null
      if (hidePartChanges) return null
      return <ChevronDoubleRightIcon className='h-4 w-4 text-gray-600' />
    }

    const highlightedClass = selectedChangeKey === change.key ?
      'bg-blue-100 ring ring-inset ring-[1px] ring-blue-400' : ''

    const button = () => {
      if (selected) {
        return <button
          type='button'
          onClick={(e) => {
            e.stopPropagation()
            const removeKeys = [change.key, ...change.onRemoveLinks]
            setSelectedChanges(selectedPartChanges.filter(k => !removeKeys.includes(k)))
          }}
          className='opacity-0 group-hover:opacity-100 cursor-pointer p-0.5 hover:bg-blue-200'>
          <MinusIcon className='h-5 text-gray-600' />
        </button>
      }
      return <button
        type='button'
        onClick={(e) => {
          e.stopPropagation()
          const newLinkedChanges = change
            .onAddLinks.filter((k) => refChanges.find(r => r.key === k))
            .filter(k => !selectedPartChanges.includes(k))
          setSelectedChanges([...selectedPartChanges, change.key, ...newLinkedChanges])
        }}
        className='opacity-0 group-hover:opacity-100 cursor-pointer p-0.5 hover:bg-blue-200'>
        <PlusIcon className='h-5 text-gray-600' />
      </button>
    }

    return <div key={change.key} className={`flex items-center ${highlightedClass} p-4 justify-between gap-4 group`}
      onClick={() => setSelectedChangeKey(change.key)}>
      {leftIcon()}
      <div className={`w-48 cursor-pointer`}>
        <div className='font-medium'>#{node.partNumber}</div>
        <div className='text-ellipsis'>{node.name}</div>
      </div>
      {button()}
      <div>{changeSymbol()}</div>
    </div>
  }

  const renderChangeGroup = (cg: ChangeGroup, selected: boolean) => {
    const getLabel = () => {
      if (cg.id === null) return null
      if (cg.id === '__none__') return `Top Level Changes`
      return cg.id === rootPartNumber ? `Child changes for root assembly #${cg.id}` : `Child changes for assembly #${cg.id}`
    }
    const label = getLabel()
    const labelComponent = label ?
      <div className='text-xs italic text-gray-700 px-4 py-2 border-b border-gray-500 mb-2 mt-4'>{label}</div> : null
    return <div key={cg.id}>
      {labelComponent}
      {cg.changes.map(c => renderChangeNavItem(c, selected))}
    </div>
  }

  const pageSize = 20
  const selectedChangesVisible = pageSize * selectedChangesPage
  const unselectedChangesVisible = pageSize * unselectedChangesPage

  const allSelectedChanges = refChanges.filter(c => selectedPartChanges.includes(c.key))
  const selectedChanges = allSelectedChanges.slice(0, selectedChangesVisible)

  const allUnselectedChanges = refChanges.filter(c => !selectedPartChanges.includes(c.key))
  const unselectedChanges = allUnselectedChanges.slice(0, unselectedChangesVisible)

  const showMoreChanges = () => {
    if (allSelectedChanges.length < selectedChangesVisible) return null
    return <Button
      className='m-3'
      onClick={() => setSelectedChangesPage(selectedChangesPage + 1)}>Show more</Button>
  }

  const showMoreUnselectedChanges = () => {
    if (allUnselectedChanges.length < unselectedChangesVisible) return null
    return <Button
      className='m-3'
      onClick={() => setUnselectedChangesPage(unselectedChangesPage + 1)}>Show more</Button>
  }

  const remainingSelectedChanges = hidePartChanges ? selectedChanges
    .filter(c => c.changeSubject === 'reference' || (c.node.hierarchy.split('.').length === 1)) : selectedChanges
  const body = () => {
    if (!refChanges.length) {
      return <div className='text-gray-600 rounded-lg bg-gray-100 italic text-sm justify-center flex items-center h-14 w-full m-4'>No changes found</div>
    }
    return <>
      <div className='text-sm overflow-y-auto h-full'>
        {remainingSelectedChanges.length > 0 ? <div className='flex flex-col gap-0.5 mb-4'>
          <div className='px-4 py-2 bg-gray-100 flex justify-between items-center'>
            <div>Selected Changes</div>
            <Button size='sm'
              disabled={remainingSelectedChanges.length === 0}
              onClick={() => setSelectedChanges([])}>Remove all</Button>
          </div>
          {calculateImport.groupChanges(rootPartNumber, selectedChanges).map(cg => renderChangeGroup(cg, true))}
          {showMoreChanges()}
        </div> : null}
        <div className='flex flex-col gap-0.5'>
          <div className='px-4 py-2 bg-gray-100 flex justify-between items-center'>
            <div>Unselected Changes</div>
            <Button size='sm'
              disabled={unselectedChanges.length === 0}
              onClick={() => setSelectedChanges(refChanges.map(c => c.key))}>Add all</Button>
          </div>
          {calculateImport.groupChanges(rootPartNumber, unselectedChanges).map(cg => renderChangeGroup(cg, false))}
          {showMoreUnselectedChanges()}
        </div>
      </div>
      {renderSelectedPart()}
    </>
  }

  return <Form onSubmit={handleImportClick} className='flex flex-1 flex-col bg-white overflow-hidden'>
    {errorModal()}
    <div className='flex-1 flex overflow-hidden'>{body()}</div>
    <div className='flex flex-col bg-gray-50 gap-4 p-5 items-end text-gray-700'>
      <div className='text-sm'>{todoMessage}</div>
      <div className=''>
        <Button className='mr-2' onClick={onBack} disabled={loading}>Back</Button>
        <Button
          type='submit'
          disabled={Boolean(todoMessage) || loading}
          variant='primary'>Import to Change Order</Button>
      </div>
    </div>
  </Form>
}

function displayBoolean(x: boolean) {
  return x ? 'Yes' : 'No'
}
