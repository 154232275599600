import { useRemovePartMutation } from 'src/lib/mutations'
import Button  from './Button'
import * as Form from 'src/components/Form'
import { routes, useParams, navigate } from '@redwoodjs/router'

type RemovePartProps = {
  changeOrderNumber: number
  partNumber: string
  onComplete: () => void
}
export default ({ changeOrderNumber, partNumber, onComplete }: RemovePartProps) => {
  const params = useParams()
  const orgId = params.orgId!
  const currentContext = params.partContext

  const [removePartMutation, removeResult] = useRemovePartMutation()

  const handleMutation = async () => {
    const { errors } = await removePartMutation({
        changeOrderNumber,
        partNumber
    })

    if (!errors) {
      if (currentContext === partNumber) {
        navigate(
          routes.changeOrderTab({ orgId, orderNumber: changeOrderNumber, tab: 'changes' })
        )
      }
      onComplete()
    }
  }

  return <div className='flex flex-col gap-8'>
    <div className='text-xl semifont-bold leading-4'>
      Confirm delete
    </div>
    <div className='leading-4'>
      Are you sure you want to remove <span className='font-medium text-gray-900'>
        #{partNumber}
    </span> from the change order?
    </div>
    <div className='flex gap-2'>
      <Button disabled={removeResult.loading} onClick={() => onComplete()}>Cancel</Button>
      <Button disabled={removeResult.loading} className='ml-auto' variant='primary' onClick={handleMutation}>
        Remove
      </Button>
    </div>
    <Form.FormError error={removeResult.error} wrapperClassName="text-white bg-red-500 w-full p-4 rounded" />
  </div>
}
