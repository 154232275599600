import { Fragment, useEffect, useRef, useState } from 'react'
import {
  PartNumberByKeyMutation,
  PartNumberByKeyMutationVariables,
  CategoryIdByPartNumberQuery,
  CategoryIdByPartNumberQueryVariables,
  ImportEnsureDistributorMutation,
  ImportEnsureDistributorMutationVariables,
  PartNumberGenerateMutation,
  PartNumberGenerateMutationVariables
} from 'types/graphql'

import * as ListBox from 'src/components/ListBox'
import { Form, Submit } from '../Form'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Select from 'src/components/Select'
import DropZone from 'src/components/DropZone'
import { useAppContext } from 'src/lib/appContext'

import { MetadataSchema, useMetadataSchema } from 'src/lib/metadata'
import PreviewImportCell from 'src/components/PreviewImportCell'
import Button from 'src/components/Button'
import { useErrorModal } from 'src/lib/useErrorModal'
import ProjectSelectCell, { SelectedProject, NewProjectSelectData, NO_PROJECT } from 'src/components/ProjectSelectCell'
import { useParams } from '@redwoodjs/router'
import { fileToStandardBom, ImportedPart, getMapperOptions, Invalid, getMapper, ToBomInput } from 'src/lib/mapping'
import { useMutation } from '@redwoodjs/web'
import { useLazyQuery } from '@apollo/client'
import { reportGenericError } from 'src/lib/reportError'
import { useForm } from '@redwoodjs/forms'
import { partNumberFromBlockValues, getSchemaFromCategory } from 'shared/partNumbers'
import { wrapError, getErrorMessage } from 'src/lib/errors'
import OnShapeImportCell from 'src/components/OnShapeImportCell'
import AltiumImportCell from 'src/components/AltiumImportCell'
import LoadingSpinner from '../Loading'
import { ErrorBoundary } from '@sentry/react'

const Releases = () => {
  const [selected, setSelected] = useState('1513CA48-3DE0-4CD8-9956-A7BDEA51E5A8')
  const options = [
    {
      releaseId: '1513CA48-3DE0-4CD8-9956-A7BDEA51E5A8',
      author: 'Rita Lampard',
      releasedAt: '10/10/24 at 5:02PM'
    },
    {
      releaseId: '4F98C5A1-BB52-4B16-A2C9-71F05C23D9F3',
      author: 'Rita Lampard',
      releasedAt: '09/23/24 at 9:30AM'
    },
    {
      releaseId: '8A4EBC17-5B6A-462F-9D89-CF29AE3F7D01',
      author: 'Rita Lampard',
      releasedAt: '09/12/24 at 10:12 AM'
    },
    {
      releaseId: '7D13E24F-C8A7-44D1-8BC6-9F25D12E8B9A',
      author: 'Rita Lampard',
      releasedAt: '09/01/24 at 1:32 PM'
    },
    {
      releaseId: 'A20F7E3B-1C44-4992-8C11-2F5DA95F2C8D',
      author: 'Rita Lampard',
      releasedAt: '08/23/24 at 2:19 PM'
    },
  ]
  return <ListBox.ListBox value={selected} onChange={setSelected}>
    {({ open }) => (
      <>
        <div className="relative text-sm">
          <ListBox.Button displayValue={selected} className='w-full text-sm' />
          <ListBox.Options open={open}>
            {options.map((r) => (
              <ListBox.Option
                key={r.releaseId}
                className='py-3'
                value={r.releaseId}
                display={<div className='flex gap-2 flex-col'>
                  <div>{r.releaseId}</div>
                  <div className='text-gray-700 text-xs'>
                    Released {r.releasedAt} by {r.author}
                  </div>
                </div>}>
              </ListBox.Option>
            ))}
          </ListBox.Options>
        </div>
      </>
    )}
  </ListBox.ListBox>
}

type ImportAssemblyProps = {
  orderNumber: number
}
export default function ImportAssembly(props: ImportAssemblyProps) {
  const [open, setOpen] = useState(false)
  const appContext = useAppContext()

  // for the purpose of resetting the form
  const [renderContent, setRenderContent] = useState(false)
  const [selectedMapper, setSelectedMapper] = useState<string>()
  const orgId = useParams().orgId!

  // these 2 states are really strange, only due to the way the code
  // was adjusted
  const [selectedProject, setSelectedProject] = useState<SelectedProject>()

  let mapper = selectedMapper ? getMapper(orgId, selectedMapper) : undefined

  let project = selectedProject
  if (mapper?.noProject) {
    project = NO_PROJECT
  }

  const [importedBom, setImportedBom] = useState<ImportedPart[] | null>(null)
  const afterClose = useRef(() => { })

  const metadataSchema = useMetadataSchema()

  const handleReset = () => {
    afterClose.current()
    setRenderContent(false)
    setSelectedProject(undefined)
    setImportedBom(null)
    afterClose.current = () => { }
  }

  const body = () => {
    if (!renderContent) return null
    if (!importedBom || !project) {
      return <ImportBody
        orgId={orgId}
        metadataSchema={metadataSchema!}
        defaultProject={project}
        defaultMapper={selectedMapper}
        onImportSubmit={(bom, project, selectedMapper) => {
          setSelectedProject(project)
          setImportedBom(bom)
          setSelectedMapper(selectedMapper)
        }}
        setOpen={setOpen} />
    }

    let partNumbers = [...new Set(importedBom.map(p => p.partNumber)), project.rootPartNumber]
    return <PreviewImportCell
      selectedMapper={selectedMapper!}
      project={project}
      orderNumber={props.orderNumber}
      onClose={(afterCloseFn) => {
        setOpen(false)
        if (afterCloseFn) {
          afterClose.current = afterCloseFn
        }
      }}
      onBack={() => setImportedBom(null)}
      partNumbers={partNumbers}
      importedBom={importedBom} />
  }

  const subtitle = () => {
    if (!importedBom) return 'Import a project to apply changes to the change order'
    if (selectedProject!.newProject) {
      return 'Select changes to import and set fields for the new project'
    }
    return 'Select changes to import and optionally update the project part CAD revision'
  }

  const disabled = getMapperOptions(orgId, appContext).length === 0
  return (<>
    <Button disabled={disabled} writeOnly onClick={() => setOpen(true)}>Import Project</Button>
    <Transition.Root show={open} as={Fragment} beforeEnter={() => setRenderContent(true)} afterLeave={handleReset}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative pointer-events-auto w-screen max-w-screen-lg">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <Header
                      title={importedBom ? 'Review Import' : 'Import Project'}
                      subtitle={subtitle()}
                      setOpen={setOpen} />
                      <ErrorBoundary fallback={({eventId}) =>
                        <div className='bg-red-200 p-2'>
                          <div className='text-xl font-medium'>
                            An error during import
                          </div>
                          <div>Contact Bomello support with error ID: <span className='font-bold'>{eventId}</span> to escalate a bug fix.</div>
                        </div>
                      }>
                    {body()}
                    </ErrorBoundary>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </>)
}

type HeaderProps = {
  setOpen: (open: boolean) => void
  title: string
  subtitle: string
}
const Header: React.FC<HeaderProps> = ({ setOpen, title, subtitle }) => {
  return <div className="bg-gray-50 px-4 py-6 sm:px-6">
    <div className="flex items-start justify-between space-x-3">
      <div className="space-y-1">
        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </Dialog.Title>
        <p className="text-sm text-gray-500">
          {subtitle}
        </p>
      </div>
      <div className="flex h-7 items-center">
        <button
          type="button"
          className="relative text-gray-400 hover:text-gray-500"
          onClick={() => setOpen(false)}
        >
          <span className="absolute -inset-2.5" />
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
}

type ImportBodyProps = {
  orgId: string
  metadataSchema: MetadataSchema
  defaultProject?: SelectedProject
  defaultMapper?: string
  setOpen: (open: boolean) => void
  onImportSubmit: (bom: ImportedPart[] | null, selectedProject: SelectedProject, selectedMapper: string) => void
}
function ImportBody({ orgId, setOpen, onImportSubmit, defaultProject, defaultMapper, metadataSchema }: ImportBodyProps) {
  const [bomInput, setBomInput] = useState<ToBomInput | null>()
  const [selectedProject, setSelectedProject] = useState<SelectedProject | undefined>(defaultProject)
  const [selectedMapper, setSelectedMapper] = useState<string | undefined>(defaultMapper)

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null)

  const [importLoading, setImportLoading] = useState(false)
  const [bomErrors, setBomErrors] = useState<Invalid[]>([])

  const [ensureDistributorsMutation, { loading: distributorsLoading }] = useMutation<ImportEnsureDistributorMutation, ImportEnsureDistributorMutationVariables>(ENSURE_DISTRIBUTOR_MUTATION)
  const [partNumberByKeyMutation, { loading: mutationLoading }] = useMutation<PartNumberByKeyMutation, PartNumberByKeyMutationVariables>(PART_NUMBER_BY_KEY_MUTATION)
  const [partNumberGenerationMutation, { loading: generateLoading }] = useMutation<PartNumberGenerateMutation, PartNumberGenerateMutationVariables>(PART_NUMBER_GENERATE_MUTATION)

  const [categoryIdByPartNumberQuery, { loading: queryLoading }] = useLazyQuery<CategoryIdByPartNumberQuery, CategoryIdByPartNumberQueryVariables>(CATEGORY_ID_BY_PART_NUMBER_QUERY)

  const appContext = useAppContext()
  const { showError, errorModal } = useErrorModal()

  const formMethods = useForm<NewProjectSelectData>()

  let mapper = selectedMapper ? getMapper(orgId, selectedMapper) : undefined

  useEffect(() => {
    if (mapper?.noProject) {
      setSelectedProject(NO_PROJECT)
    }
  }, [mapper])

  const getProjectPartNumber = async (blockValues: Record<string, string | number>, categoryId: string) => {
    const { partCategories } = appContext

    const projectPartCategory = partCategories.find(c => c.id === categoryId)
    if (!projectPartCategory) throw Error('No category found for project to generate part number')
    const schema = getSchemaFromCategory(projectPartCategory)

    const { missingBlocks, partNumber } = partNumberFromBlockValues(blockValues, schema)

    if (missingBlocks.length === 0) return partNumber!
    const sequenceBlocks = missingBlocks.filter(block => block.config.type === 'sequence')

    if (missingBlocks.some(b => b.config.type !== 'sequence')) {
      throw new Error(`There are missing part number blocks that cannot be generated, ${JSON.stringify(missingBlocks, null, 2)}`)
    }

    if (sequenceBlocks.length !== 1) {
      throw new Error(`Cannot generate part number with no sequence blocks or more than one sequence block, sequence blocks: ${sequenceBlocks.length}`)
    }

    const generateVariables: PartNumberGenerateMutationVariables = {
      input: {
        parts: [{
          categoryId
        }]
      }
    }
    try {
      const { data } = await partNumberGenerationMutation({
        variables: generateVariables,
      })
      if (!data?.generatePartNumbers[0]?.partNumber) {
        throw Error('No part numbers returned for project part')
      }
      return data.generatePartNumbers[0].partNumber
    } catch (e) {
      throw wrapError('Error generating project part number', e)
    }
  }

  const onFormSubmit = async (formData: NewProjectSelectData) => {
    try {
      if (selectedProject?.newProject) {
        const blockValues = formData.partNumberBlockValues
        const categoryId = selectedProject.categoryId
        if (!categoryId) throw new Error('No category ID for new project part number')
        // generate new part number
        selectedProject.rootPartNumber = await getProjectPartNumber(blockValues, categoryId)
      } else if (!selectedProject?.noProject) {
        if (selectedProject?.rootPartNumber === '__none__') {
          throw new Error('No project part number')
        }
      }
      if (!bomInput) {
        throw new Error('No Input Found')
      }
      const output = await fileToStandardBom(orgId, bomInput, {
        selectedMapper: selectedMapper!,
        selectedProject: selectedProject!,
        metadataSchema,
        partNumberByKeyMutation,
        partNumberGenerationMutation,
        categoryIdByPartNumberQuery,
        appContext,
      })

      if (!output.valid) {
        setBomErrors(output.invalids)
        return
      }
      const variables = {
        input: {
          distributors: output.distributors
        }
      }
      await ensureDistributorsMutation({
        variables
      })
      onImportSubmit(output.bom, selectedProject!, selectedMapper!)
    } catch (e) {
      const eventId = reportGenericError(e)
      showError({
        sentryEventId: eventId,
        title: 'Error generating preview',
        subtitle: 'There was an error mapping and previewing the bom',
        detail: getErrorMessage(e)
      })
    }
  }

  const previewDisabled = () => {
    if (!bomInput) return true
    // onshape doesnt need a project
    // if (mapper?.type === 'OnShapeApi') {
    //   return false
    // }
    // console.log('has bom impout')
    if (!selectedProject) return true
    if (selectedProject?.newProject) {
      return !selectedProject.name || !selectedProject.categoryId
    }
    return false
  }

  if (bomErrors.length > 0) {
    return <div className='p-6 flex flex-col'>
      {errorModal()}
      <div className='text-lg mb-6 text-red-500'>There were errors in your BOM import</div>
      <div className='flex flex-col gap-4'>
        {bomErrors.map(error => {
          return <div key={error.rowIndex} className='flex flex-col gap-2'>
            <div className='flex gap-2 font-semibold text-sm'>
              <div className='flex gap-1'>
                <div>Row</div><div>#{error.rowIndex + 1}</div>
              </div>
              {error.part.partNumber && <div className='flex gap-1'>
                <div>Part</div><div>#{error.part.partNumber}</div>
              </div>}
            </div>
            <ul className='text-xs'>
              {error.issues.map((issue, i) => {
                return <li key={i}>• {issue.message}</li>
              })}
            </ul>
          </div>
        })}
      </div>
    </div>
  }

  const dataSource = () => {
    const importSection = () => {
      if (!selectedMapper) return null
      if (mapper?.type === 'OnShapeApi') {
        return <div className="space-y-2 px-4 sm:grid grid-cols-6 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div className="col-span-2">
            <label
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              OnShape Document and Version
            </label>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Select an OnShape document and version to import from
            </p>
          </div>
          <div className='col-span-2'></div>
          <div className="col-span-2">
            <OnShapeImportCell onLoading={() => setImportLoading(true)} onBomChange={(bom) => {
              setImportLoading(false)
              if (!bom) setBomInput(null)
              else setBomInput({ type: 'rows', data: bom })
            }} />
          </div>
        </div>
      }
      if (mapper?.type === 'AltiumApi') {
        return <div className="space-y-2 px-4 sm:grid grid-cols-6 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div className="col-span-2">
            <label
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Altium Design and Release
            </label>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Select an Altium design and release ID to import from
            </p>
          </div>
          <div className='col-span-2'></div>
          <div className="col-span-2">
            <AltiumImportCell onLoading={() => setImportLoading(true)} onBomChange={(bom) => {
              setImportLoading(false)
              if (!bom) setBomInput(null)
              else setBomInput({ type: 'rows', data: bom })
            }} />
          </div>
        </div>
      }

      return <>
        {!mapper?.noProject && <div className="space-y-2 px-4 sm:grid grid-cols-6 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">

          <div className="col-span-2">
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Choose Project
            </label>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Select the project you are importing, or import a new project
            </p>
          </div>
          <div className='col-span-2'></div>
          <div className="col-span-2">
            <ProjectSelectCell
              projectId={selectedProjectId}
              setProjectId={setSelectedProjectId}
              forMapper={selectedMapper}
              formMethods={formMethods}
              allowNone={Boolean(appContext?.me.isSuperUser)}
              onChange={setSelectedProject} />
          </div>
        </div>}
        <div className="space-y-2 px-4 sm:grid grid-cols-6 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div className="col-span-2">
            <div
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Upload BOM
            </div>
          </div>
          <div className='col-span-1'></div>
          <div className="col-span-3">
            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
              Bill of Materials
            </label>
            <p className='text-sm leading-6 text-gray-600'>
              Import a bill of materials into the change order
            </p>
            <div className="rounded text-sm leading-6 text-gray-600 flex mt-2 border border-gray-200 h-24 w-full bg-gray-50 justify-center items-center">
              <DropZone onChange={data => setBomInput({ type: 'file', data })} fileTypes={mapper?.fileToRows?.parsers?.map(p => p.fileType)} />
            </div>
          </div>
        </div>
      </>
    }

    return <div>
      <div className="space-y-2 px-4 sm:grid grid-cols-6 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div className="col-span-2">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Data Source
          </label>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Select a data source to import data into the change order
          </p>
        </div>
        <div className='col-span-2'></div>
        <div className="col-span-2">
          <Select
            options={getMapperOptions(orgId, appContext).map(m => m.name)}
            selected={selectedMapper || 'Select'}
            placeholder=''
            onChange={(m) => {
              setSelectedProject(undefined)
              setSelectedProjectId(null)
              setSelectedMapper(m)
            }}
          />
        </div>
      </div>
      {importSection()}
    </div>
  }
  const somethingLoading = mutationLoading || distributorsLoading || queryLoading || generateLoading || importLoading
  return (<>
    <Form onSubmit={onFormSubmit} formMethods={formMethods}>
      {errorModal()}
      <div className="flex-1">
        {/* Divider container */}
        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
          {/* Import Mapping */}
          {dataSource()}
          {/* Choose project */}
          {/* <Nexar>
            <Projects />
          </Nexar> */}
        </div>
      </div>
      {/* Action buttons */}
      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-end space-x-3">
          {
            somethingLoading && <LoadingSpinner size='sm' className='flex items-center' />
          }
          <Button
            disabled={somethingLoading}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Submit
            variant='primary'
            disabled={previewDisabled() || somethingLoading}
          >
            Preview Import
          </Submit>
        </div>
      </div>
    </Form>
  </>
  )
}

export const PART_NUMBER_BY_KEY_MUTATION = gql`
mutation PartNumberByKeyMutation ($input: PartNumberByKeyInput!) {
  partNumberByKey(input: $input) {
    key
    partNumber
    category {
      name
    }
  }
}
`

export const PART_NUMBER_GENERATE_MUTATION = gql`
mutation PartNumberGenerateMutation (
  $input: GeneratePartNumbersInput!
) {
  generatePartNumbers(input: $input) {
    partNumber
  }
}
`

export const CATEGORY_ID_BY_PART_NUMBER_QUERY = gql`
query CategoryIdByPartNumberQuery ($input: PartNumberByPartNumberInput!) {
  partCategoryByPartNumber(input: $input) {
    partNumber
    schemaKey
    category {
      id
    }
  }
}
`

export const ENSURE_DISTRIBUTOR_MUTATION = gql`
mutation ImportEnsureDistributorMutation ($input: CreateDistributorsInput!) {
  ensureDistributors(input: $input) {
    id
    name
  }
}
`
