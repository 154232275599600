import { MetaTags } from '@redwoodjs/web'
import { useParams } from '@redwoodjs/router'
import ChangeOrderCell from 'src/components/ChangeOrderCell'
import ChangeOrderChangesCell from 'src/components/ChangeOrderChangesCell'

const ChangeOrderPage = () => {
  const { orderNumber, tab, orgId, partContext } = useParams()
  const selectedTab = tab || 'activity'

  if (partContext && tab !== 'changes') {
    throw new Error("invalid route: " + window.location.toString())
  }

  return (
    <>
      <MetaTags title="ChangeOrder" description="ChangeOrder page" />
      <ChangeOrderCell orgId={orgId!} tab={selectedTab} orderNumber={orderNumber as unknown as number}>
        {selectedTab === 'activity' ?
          null :
          <ChangesTab changeOrderNumber={orderNumber as unknown as number} partContext={partContext}/>
        }
      </ChangeOrderCell>
    </>
  )
}

const ChangesTab = ({ changeOrderNumber, partContext }: { changeOrderNumber: number, partContext?: string }) => {
  return (
    <div>
      <ChangeOrderChangesCell orderNumber={changeOrderNumber} partContext={partContext} />
    </div>
  )
}

export default ChangeOrderPage
