import type { QuantityUnit } from 'shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

export const electricalConfig: MapperConfig = {
  name: 'PCBA',
  type: 'Upload',
  orgIds: ['xicato', 'xicato-sandbox'],
  rootIsTopLevel: false,
  Logo: DocumentIcon,
  rowsToBom: [{
    type: 'FilterRemove',
    config: {
      conditions: [{
        type: 'empty',
        column: 'item_number',
      }]
    }
  }],
  importOutputFields: {
    metadata: [],
  },
  standardizeBom: {
    columns: {
      sources: [{
        distributorName: {
          type: 'simple',
          value: '${row.manufacturer_1}'
        },
        distributorSku: {
          type: 'simple',
          value: '${row.manufacturer_item_number_1}'
        }
      }, {
        distributorName: {
          type: 'simple',
          value: '${row.manufacturer_2}'
        },
        distributorSku: {
          type: 'simple',
          value: '${row.manufacturer_item_number_2}'
        }
      }, {
        distributorName: {
          type: 'simple',
          value: '${row.manufacturer_3}'
        },
        distributorSku: {
          type: 'simple',
          value: '${row.manufacturer_item_number_3}'
        }
      }],
      cadRev: {
        type: 'simple',
        value: '{{row.revision}}'
      },
      summary: {
        type: 'ignore'
      },
      hierarchy: {
        type: 'simple',
        value: '1.{{rowIndex}}',
      },
      partNumber: {
        type: 'simple',
        value: '{{row.item_number}}'
      },
      categoryId: {
        type: 'simple',
        value: 'electrical-component',
      },
      isOffTheShelf: {
        value: 'true',
        type: 'simple'
      },
      name: {
        value: '{{row.item_name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
      referenceDesignator: {
        type: 'simple',
        value: '{{row.reference_designator}}'
      }
    }
  }
}

export const mechancalConfig: MapperConfig = {
  ...electricalConfig,
  name: 'Mechanical',
  orgIds: ['xicato', 'xicato-sandbox'],
  rootIsTopLevel: true,
  standardizeBom: {
    ...electricalConfig.standardizeBom,
    columns: {
      ...electricalConfig.standardizeBom.columns,
      categoryId: {
        type: 'simple',
        value: 'mechanical-component',
      },
    }
  }
}
