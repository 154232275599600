import {
  MetadataType,
  AddToMetadataSchemaMutation,
  AddToMetadataSchemaMutationVariables,
  AddToChangeOrderMetadataSchemaMutation,
  AddToChangeOrderMetadataSchemaMutationVariables,
  EditMetadataMutation,
  EditMetadataMutationVariables,
  Part,
  InputMaybe,
  EditChangeOrderMetadataMutation,
  EditChangeOrderMetadataMutationVariables,
} from 'types/graphql'

import { ReactNode, useEffect } from 'react'

import type { Prisma } from "@prisma/client"

import { QUERY as APP_BODY_QUERY } from 'src/components/AppBodyCell'
import kebabCase from 'lodash.kebabcase'
import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_QUERY } from 'src/lib/queries'
import type { PartDiffSummary, EditMode } from './ChangeOrderChangesCell/calculateAllChanges'
import { useRef, useState } from 'react'
import { Controller, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { resolveMetadata, useMetadataSchemaMaybe, MetadataSchema, metadataTypes, ResolvedMetadata } from 'src/lib/metadata'
import * as Form from 'src/components/Form'
import * as ListBox from 'src/components/ListBox'
import { ConditionalModal } from './Modal'
import { TrashIcon } from '@heroicons/react/24/outline'
import Button, { EditButton } from 'src/components/Button'
import { reportMutationError } from 'src/lib/reportError'
import { letF } from 'src/lib/functional'
import { codes as currencyCodes } from 'currency-codes'
import { massUnits } from 'shared/types'
import { OptionInput } from './OptionInput'
import { Omit } from '@prisma/client/runtime/library'
import { linkClass } from './styles'

const usedTypes = metadataTypes.filter(t => !['Time'].includes(t));
const changeOrderMetadataUsedTypes = usedTypes.filter(t => !['Price', 'Mass'].includes(t));

const ADD_TO_METADATA_SCHEMA_MUTATION = gql`
mutation AddToMetadataSchemaMutation (
  $input: AddToMetadataSchemaInput!
) {
  addToMetadataSchema(input: $input) {
    id
    metadataSchema
  }
}
`
const ADD_TO_CHANGE_ORDER_METADATA_SCHEMA_MUTATION = gql`
mutation AddToChangeOrderMetadataSchemaMutation (
  $input: AddToMetadataSchemaInput!
) {
  addToChangeOrderMetadataSchema(input: $input) {
    id
    metadataSchema
  }
}
`
const EDIT_METADATA_MUTATION = gql`
mutation EditMetadataMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
  $metadata: JSON
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: [{
    type: Patch
    partNumber: $partNumber
    version: $version
    part: {
      metadata: $metadata
    }
  }]) {
    partNumber
  }
}
`
const EDIT_CHANGE_ORDER_METADATA_MUTATION = gql`
mutation EditChangeOrderMetadataMutation (
  $number: Int!
  $input: SetChangeOrderMetadataInput!
) {
  setChangeOrderMetadata(number: $number, input: $input) {
    _id
    number
    metadata
    log {
      user {
        name
      }
      children {
        action
        partNumber
        payload
      }
      id
      action
      createdAt
      payload
      partNumber
    }
  }
}
`

type MetadataSchemaEditorWIPProps = {
  onClose: (selected?: string) => void
  variant?: 'changeOrder'
}
export const MetadataSchemaEditor = (props: MetadataSchemaEditorWIPProps) => {
  const { onClose, variant } = props;

  const metadataSchema = useMetadataSchemaMaybe(props.variant)
  if (!metadataSchema) { return; }

  const formMethods = useForm<AddSchemaInput>();

  const [addToMetadataSchema, { loading: loadingSchemaUpdate, error: schemaUpdateError }] = variant === 'changeOrder' ?
    useMutation<AddToChangeOrderMetadataSchemaMutation, AddToChangeOrderMetadataSchemaMutationVariables>(ADD_TO_CHANGE_ORDER_METADATA_SCHEMA_MUTATION) :
    useMutation<AddToMetadataSchemaMutation, AddToMetadataSchemaMutationVariables>(ADD_TO_METADATA_SCHEMA_MUTATION)

  const addSchemaEntry = async (data: { displayName: string, type: MetadataType }) => {
    const key = kebabCase(data.displayName);
    const variables: AddToMetadataSchemaMutationVariables = {
      input: {
        key,
        ...data
      }
    }
    const { errors } = await addToMetadataSchema({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: APP_BODY_QUERY }
      ]
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating ${variant === 'changeOrder' ? 'change order ' : ''}metadata schema with ${variables.input.key}`
      })
    }

    if (!errors) {
      onClose(key);
    }
  }

  type AddSchemaInput = Parameters<typeof addSchemaEntry>[0]

  const displayValue = (v: MetadataType | undefined) =>
    v ? (v === 'Price' ? 'Currency' : v)
      : 'Select Type'

  const valueTypesForVariant = variant === 'changeOrder' ?
    changeOrderMetadataUsedTypes :
    usedTypes

  return (
    <ConditionalModal onClose={onClose} className='w-auto max-w-screen-sm'>
      <Form.Form<AddSchemaInput> onSubmit={addSchemaEntry} className='flex gap-4 p-4 flex-col' formMethods={formMethods}>
        <div>
          <div className='font-medium mb-1 text-lg'>Add a new {variant === 'changeOrder' ? 'property' : 'metadata'} type</div>
          <div className='text-gray-400 text-sm'>
            This will be available for entry on every {variant === 'changeOrder' ? 'change order' : 'part'}. Keys cannot be removed from or edited in the schema to retain backwards compatability.
          </div>
        </div>
        <div className='flex gap-2 relative'>
          <Controller
            name='type'
            defaultValue={null}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) =>
              <ListBox.ListBox onChange={onChange} value={value} ref={ref}>
                {({ open }) =>
                  <div className="relative h-full">
                    <ListBox.Button className='py-2' displayValue={displayValue(value)}/>
                    <ListBox.Options open={open} align='left' className='text-sm'>
                      {valueTypesForVariant.map(t => (
                        <ListBox.Option key={t} className='py-3' value={t} display={displayValue(t)} />
                      ))}
                    </ListBox.Options>
                  </div>
                }
              </ListBox.ListBox>
            }
          />
          <Form.TextField name='displayName' placeholder='Display Name' required validation={{ minLength: 3 }} className='grow' autoComplete='off'></Form.TextField>
        </div>
        <div className='flex gap-2'>
          <Button className='py-2 grow basis-1' onClick={() => onClose()}>
            Cancel
          </Button>
          <Button className='py-2 grow basis-1' variant='primary' type='submit' disabled={!formMethods.formState.isValid || loadingSchemaUpdate}>
            Save
          </Button>
        </div>
        <Form.FormError error={schemaUpdateError} wrapperClassName="text-white bg-red-500 w-full p-4 rounded" />
      </Form.Form>
    </ConditionalModal>
  )
}

const NEW_TYPE = 'New Type' as const;
const NEW_TYPE_VAL = '__' + NEW_TYPE + '__'
export type ChangeType = undefined | 'Add' | 'Remove' | 'Change'

type AbstractMetadataPanelProps = {
  editMode?: EditMode
  edit?: {
    partNumber: string
    changeOrderNumber: number
  }
  headMetadata?: any
  diff?: PartDiffSummary['fields']['metadata']
  metadataJSON: any,
  doMutation: (newMetadata: InputMaybe<Prisma.JsonValue> | undefined, edit: NonNullable<AbstractMetadataPanelProps['edit']>) => Promise<void>,
  loading: boolean
  renderWithEditHandle?: (editButton: ReactNode, body: ReactNode) => ReactNode
  variant?: 'changeOrder'
}

export const AbstractMetadataPanel = ({editMode, ...props}: AbstractMetadataPanelProps) => {
  const { edit, headMetadata, diff, metadataJSON, doMutation, loading, renderWithEditHandle, variant } = props;
  const [editingMetadata, setEditingMetadata] = useState(false)
  const [activeEntries, setActiveEntries] = useState<ResolvedMetadata[]>([])
  const [showSchemaEditor, setShowSchemaEditor] = useState(false);

  const formMethods = useForm();

  const metadataSchema = useMetadataSchemaMaybe(variant)
  if (!metadataSchema) return;

  //the create new schema type callback uses an old reference, this helps avoid that. There must be a better solution
  const schemaRef = useRef<MetadataSchema>(metadataSchema)
  schemaRef.current = metadataSchema;

  useEffect(() => {
    for (const e of activeEntries) {
      Object.assign(e, metadataSchema[e.key])
    }
    setActiveEntries(activeEntries)
  }, [metadataSchema])

  const resolvedHeadMetadata = headMetadata ? resolveMetadata(metadataSchema, headMetadata) : undefined
  const resolvedMetadata = resolveMetadata(metadataSchema, metadataJSON)

  const getMetadataWithChange = () => {
    const input = diff?.change || resolvedMetadata
    return Object.entries(metadataSchema).map(([key, field]) => {
      const metadataItem = input.find(m => m.key === key)
      if (metadataItem) {
        return metadataItem
      }
      if (field.alwaysShow) {
        return {
          ...field,
          key,
          type: "Empty"
        }
      }
      return false
    }).filter(m => m) as MetadataField[]
  }
  const metadataWithChange = getMetadataWithChange()

  const updateMetadata = async (newMetadata: EditMetadataMutationVariables['metadata']) => {
    if (!edit) throw new Error('Metadata not editable in this context')
    await doMutation(newMetadata, edit)
    setEditingMode(false)
  }

  const setEditingMode = (editing: boolean) => {
    setEditingMetadata(editing)
    if (editing) {
      setActiveEntries(resolvedMetadata)
    }
  }

  const deleteLocalMetadata = (key: string) => {
    const newMetadata = activeEntries.filter(m => m.key !== key)
    setActiveEntries(newMetadata)
    formMethods.unregister(key)
  }

  const unusedFields = Object.entries(metadataSchema).filter(f => !activeEntries.find(m => m.displayName === f[1].displayName))

  const onNewEntrySelected = (key: string) => {
    if (key === NEW_TYPE_VAL) {
      setShowSchemaEditor(true);
    }
    else {
      const fieldDefinition = schemaRef.current![key]!;
      //@ts-ignore
      const virtualEntry: ResolvedMetadata = {
        ...fieldDefinition,
        key,
        entry: defaultMetadataValue(fieldDefinition.type)
      }

      setActiveEntries([
        ...activeEntries,
        virtualEntry
      ])
    }
  }

  const onSchemaModalClosed = (newKey?: string) => {
    setShowSchemaEditor(false)
    if (newKey) onNewEntrySelected(newKey)
  }

  const editButton = editMode && !editingMetadata &&
    <EditButton size={4} disabled={editMode === 'locked'} onClick={() => setEditingMode(true)} testId={variant === 'changeOrder' ? 'properties' : 'metadata'}/>

  const emptyMessage = variant === 'changeOrder' ? <div className='px-1 pb-4 pb-2 font-light italic'>No properties specified</div> : <tr>
    <td colSpan={2} className='whitespace-nowrap px-4 py-2 text-sm text-gray-400'>
      This part doesn't have any metadata
    </td>
  </tr>

  const body = (
    <div className='flex flex-col gap-0.5'>
      <div className='flex items-center gap-1 text-sm font-semibold text-gray-500'>
        {!(variant === 'changeOrder') &&
          <>
            <div>Metadata</div>
          </>
        }
        {!renderWithEditHandle && editButton}
      </div>
      {editingMetadata ?
        <>
          <Form.Form formMethods={formMethods} onSubmit={updateMetadata} >
            <MetadataTable variant={variant}>
              {activeEntries.length ?
                activeEntries.map(m =>
                <MetadataRow key={m.key}
                  field={m} editable
                  onDelete={(field) => deleteLocalMetadata(field.key)}
                  variant={variant}
                />) : emptyMessage
              }
            </MetadataTable>
            <div className={`flex gap-2 ${variant === 'changeOrder' ? 'mt-4' : ''}`}>
              <ListBox.ListBox onChange={onNewEntrySelected} value={null}>
                {({ open }) =>
                  <div className=''>
                    <ListBox.Button displayValue={variant === 'changeOrder' ? 'Add Property' : 'Add field'} className='py-2 text-sm'/>
                    <ListBox.Options open={open} align='left' className='text-xs'>
                      {unusedFields.map(([key, value]) => (
                        <ListBox.Option key={key} className='py-3' value={key} display={value.displayName} />
                      ))}
                      <ListBox.Option className={unusedFields.length ? 'border-t py-3' : ''} value={NEW_TYPE_VAL} display={NEW_TYPE} />
                    </ListBox.Options>
                  </div>
                }
              </ListBox.ListBox>
              <Button type='button' className='ml-auto py-2' onClick={() => setEditingMode(false)}>
                Cancel
              </Button>
              <Button type='submit' variant='primary' className='py-2' disabled={!formMethods.formState.isValid || loading}>
                Save
              </Button>
            </div>
          </Form.Form>
          {showSchemaEditor &&
            <MetadataSchemaEditor onClose={onSchemaModalClosed} variant={variant}/>
          }
        </>
        :
        <MetadataTable variant={variant}>
          {metadataWithChange && metadataWithChange.length ?
            metadataWithChange.map(field =>
              <MetadataRow key={field.key} field={field} variant={variant}/>)
            :
            emptyMessage
          }
        </MetadataTable>
      }
    </div>
  )

  if (!renderWithEditHandle) return body;
  else return renderWithEditHandle(editButton, body)
}

type MetadataPanelProps = Omit<AbstractMetadataPanelProps, 'metadataJSON' | 'doMutation' | 'loading'> & {
  part:  Pick<Part, 'version'> & { metadata: any }
}
export const MetadataPanel = ({part, ...props}: MetadataPanelProps) => {
  const [editPartMutation, { loading }] = useMutation<EditMetadataMutation, EditMetadataMutationVariables>(EDIT_METADATA_MUTATION)

  const doMutation: AbstractMetadataPanelProps['doMutation'] = async (newMetadata, edit) => {
    const variables = {
      partNumber: edit.partNumber,
      changeOrderNumber: edit.changeOrderNumber,
      version: part.version,
      metadata: newMetadata
    }
    const { errors } = await editPartMutation({
      variables: variables,
      refetchQueries: [
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: edit.changeOrderNumber } },
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: edit.changeOrderNumber } }
      ],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part metadata`
      })
    }
  }

  return <AbstractMetadataPanel {...props} metadataJSON={part.metadata} doMutation={doMutation} loading={loading}/>
}

type ChangeOrderMetadataPanelProps = Omit<AbstractMetadataPanelProps, 'doMutation' | 'loading'> & {
  changeOrderNumber: number
}
export const ChangeOrderMetadataPanel = ({...props}: ChangeOrderMetadataPanelProps) => {
  const [editMetadata, { loading }] = useMutation<EditChangeOrderMetadataMutation, EditChangeOrderMetadataMutationVariables>(EDIT_CHANGE_ORDER_METADATA_MUTATION)

  const doMutation: AbstractMetadataPanelProps['doMutation'] = async (newMetadata) => {
    if (!newMetadata) newMetadata = {}
    const variables: EditChangeOrderMetadataMutationVariables = {
      number: props.changeOrderNumber,
      input: { metadata: newMetadata }
    }
    const { errors } = await editMetadata({ variables })
      //TODO: are these necessary?
      //refetchQueries: [ { query: CHANGE_ORDER_QUERY, variables: { orderNumber: edit.changeOrderNumber } } ],
      //awaitRefetchQueries:

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part metadata`
      })
    }
  }

  return <AbstractMetadataPanel {...props} doMutation={doMutation} loading={loading} edit={{changeOrderNumber: props.changeOrderNumber}} variant='changeOrder'/>
}

type MetadataTableProps = React.PropsWithChildren<{
  variant?: 'changeOrder'
}>
const MetadataTable = ({variant, ...props}: MetadataTableProps) => {
  if (variant === 'changeOrder') {
    return <div>
      {props.children}
    </div>
  }
  return (
    <div className='border border-gray-200 -mx-1 rounded-lg my-2 text-xs'>
      <table className=''>
        <thead>
          <tr className='bg-gray-50'>
            <th scope="col" className={`whitespace-nowrap py-2 px-4 text-left font-semibold text-gray-900 rounded-tl-lg`}>
              Field Name
            </th>
            <th scope="col" className={`whitespace-nowrap py-2 px-4 text-left font-semibold text-gray-900 w-full rounded-tr-lg`}>
              Value
            </th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>
          {props.children}
        </tbody>
      </table>
    </div>
  )
}

const changeClasses = {
  'Add': 'bg-green-200',
  'Remove': 'bg-red-200 linethrough',
  'Change': 'bg-yellow-100',
  'None': ''
}

const required = { required: true }

// Empty will never be passed if in edit mode
type MetadataField = ResolvedMetadata | {
  key: string
  type: "Empty";
  displayName: string;
  entry: never;
  alwaysShow?: boolean | undefined;
}

type MetadataRowProps = {
  field: MetadataField & { changeType?: ChangeType }
  variant?: 'changeOrder'
} & ({
  editable?: false
} | {
  editable: true
  onDelete: (field: ResolvedMetadata) => void
})

const MetadataRow = ({ field, editable, variant, ...props }: MetadataRowProps) => {
  const inputId = 'metadata-input-' + field.key

  const rowClass = `whitespace-nowrap text-xs text-gray-600 ` +  (variant === 'changeOrder' ? 'py-1' : 'px-4 py-2')

  const valueComponent = <>
  { editable ? letF(props as Extract<MetadataRowProps, {editable: true}>, ({ onDelete }) => {
    const nonEmptyField = field as Exclude<MetadataField, { type: "Empty" }>
    const deleteButton = <button type='button' onClick={() => onDelete(nonEmptyField)} className='ml-auto pl-2'><TrashIcon className='w-4'/></button>
    return (
      field.type === 'String' ? <div className='flex gap-1'>
        <Form.TextField name={field.key} validation={required} defaultValue={field.entry} className='grow' data-testid={inputId} />
        {deleteButton}
      </div>
      : field.type === 'URL' ? <div className='flex gap-1'>
        <Form.UrlField name={field.key} validation={required} defaultValue={field.entry} className='grow'  data-testid={inputId}/>
        {deleteButton}
      </div>
      : field.type === 'Number' ? <div className='flex gap-1'>
        <Form.NumberField name={field.key} className='grow' step="any"
          validation={{ ...required, valueAsNumber: true }} defaultValue={field.entry} data-testid={inputId}/>
        {deleteButton}
      </div>
      : field.type === 'Boolean' ? <div className='flex gap-1'>
        <Form.CheckboxField name={field.key}
          className=''
          validation={{ valueAsBoolean: true }} defaultChecked={field.entry} data-testid={inputId}/>
        {deleteButton}
      </div>
      : field.type === 'Time' ? <div className='flex gap-1'>
        <Form.DatetimeField name={field.key} className='grow'
          validation={{ ...required, valueAsDate: true }} data-testid={inputId}/>{/*TODO: default this better*/}
        {deleteButton}
      </div>
      : field.type === 'Mass' ?
        <div className='flex gap-1'>
          <div className='flex relative grow'>
            <Form.NumberField name={`${field.key}.value`} placeholder='Value'  className='grow [&_input]:pr-20'
              validation={{ ...required, valueAsNumber: true }} defaultValue={field.entry.value} step="any" data-testid={inputId}/>
            <Controller
              name={`${field.key}.unit`}
              defaultValue={field.entry.unit}
              render={({ field: { onChange, value, ref } }) =>
                <ListBox.ListBox onChange={onChange} value={value} ref={ref}>
                  {({ open }) =>
                    <div className="absolute h-full right-0">
                      <ListBox.UncontrolledButton className='h-full !bg-transparent pr-8' hideRing/>
                      <ListBox.Options open={open}>
                        {massUnits.map(t => (
                          <ListBox.Option key={t} className='py-3' value={t} display={t} />
                        ))}
                      </ListBox.Options>
                    </div>
                  }
                </ListBox.ListBox>
              }
            />
          </div>
          {deleteButton}
        </div>
      : field.type === 'Price' ?
        <div className='flex gap-1'>
          <div className='flex relative grow'>
            <Form.NumberField name={`${field.key}.value`} placeholder='Value' className='grow [&_input]:pr-20'
              validation={{ ...required, valueAsNumber: true }} defaultValue={field.entry.value} step="any" data-testid={inputId}/>
            <Controller
              name={`${field.key}.unit`}
              defaultValue={field.entry.unit}
              render={({ field: { onChange, value, ref } }) =>
                <ListBox.ListBox onChange={onChange} value={value} ref={ref}>
                  {({ open }) =>
                    <div className="absolute h-full right-0">
                      <ListBox.UncontrolledButton className='h-full !bg-transparent pr-8' hideRing/>
                      <ListBox.Options open={open}>
                        {currencyCodes().map(t => (
                          <ListBox.Option key={t} className='py-3' value={t} display={t} />
                        ))}
                      </ListBox.Options>
                    </div>
                  }
                </ListBox.ListBox>
              }
            />
          </div>
          {deleteButton}
        </div>
      : field.type === 'Option' ? <div className='flex gap-1 relative'>
                      {/*
          <Form.TextField name={field.key} validation={required} defaultValue={field.entry} className='grow' data-testid={inputId} />
                      */}
          <OptionInput field={field} variant={variant} />
          {deleteButton}
        </div>
      : undefined
    )})
    :
    (
      field.type === 'String' ? <>{field.entry}</> :
      field.type === 'Boolean' ? <>{JSON.stringify(field.entry)}</> :
      field.type === 'Number' ? <>{field.entry}</> :
      field.type === 'URL' ? <><a className={linkClass} href={field.entry} target='_blank'>{field.entry.split('//')[1]}</a></> :
      field.type === 'Time' ? <>{field.entry}</> :
      field.type === 'Mass' ? <>{field.entry.value} {field.entry.unit}</> :
      field.type === 'Price' ? <>{field.entry.value} {field.entry.unit}</> :
      field.type === 'Option' ? <>{field.entry}</> :
      field.type === 'Empty' ? <>-</> :
      undefined
    )
  }
</>

  if (variant === 'changeOrder') {
    return (<>
          <div className={`${rowClass} font-semibold`} data-testid={'metadata-label-' + field.key}>
            {field.displayName}
          </div>
          <div className={`${rowClass} w-full text-ellipsis overflow-hidden`}>
            {valueComponent}
          </div>
      </>
    )
  }
  return (
    <tr className={changeClasses[field.changeType ?? 'None']}>
      <td className={`${rowClass}`} data-testid={'metadata-label-' + field.key}>
        {field.displayName}
      </td>
      <td className={`${rowClass} pl-4`}>
        {valueComponent}
      </td>
    </tr>
  )
}

function defaultMetadataValue(type: MetadataType) {
  return (
    type === 'String' ? '' :
    type === 'Option' ? '' :
    type === 'Boolean' ? false :
    type === 'Number' ? 0 :
    type === 'URL' ? '' :
    type === 'Time' ? '' :
    type === 'Mass' ? {
      unit: 'g',
      value: 0
    } :
    type === 'Price' ? {
      unit: 'USD',
      value: 0
    } :
    ''
  )
}
