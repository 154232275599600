import {
  UpdateMetadataOptionMutation,
  UpdateMetadataOptionMutationVariables,
  UpdateChangeOrderMetadataOptionMutation,
  UpdateChangeOrderMetadataOptionMutationVariables,
} from 'types/graphql'

import { useLayoutEffect } from 'react'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Transition, Popover, PopoverButton, PopoverPanel } from 'headlessuinext'
import { inputClasses } from 'src/components/Input'
import { useController } from '@redwoodjs/forms'
import { useState, useRef } from 'react'
import { useMutation } from '@redwoodjs/web'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'

import { ResolvedMetadata } from 'src/lib/metadata'
import { letF } from 'api/src/shared/functional'

const UPDATE_METADATA_OPTION = gql`
mutation UpdateMetadataOptionMutation (
  $input: UpdateMetadataOptionInput!
) {
  updateMetadataOption(input: $input) {
    id
    metadataSchema
  }
}
`

const UPDATE_CHANGE_ORDER_METADATA_OPTION = gql`
mutation UpdateChangeOrderMetadataOptionMutation (
  $input: UpdateMetadataOptionInput!
) {
  updateChangeOrderMetadataOption(input: $input) {
    id
    changeOrderMetadataSchema
  }
}
`

type OptionInputProps = {
  field: Extract<ResolvedMetadata, {type: 'Option'}>,
  variant?: 'changeOrder'
}
export const OptionInput = ({field, variant}: OptionInputProps) => {
  const initialValue = field.entry ?? ''

  const {
    field: { onChange: controllerOnChange, ...combobox },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: field.key,
    defaultValue: initialValue,
    rules: { required: true }
  });

  const ref = useRef<HTMLSpanElement>(null);
  const [badgeWidth, setBadgeWidth] = useState(0); // You don't know real height yet

  useLayoutEffect(() => {
    if (!ref.current) return
    const { width } = ref.current?.getBoundingClientRect();
    setBadgeWidth(width);
  }, [combobox.value]);

  const [query, setQuery] = useState('')

  const [updateMetadataOption, { loading: loadingOptionUpdate, error: optionUpdateError }] = variant === 'changeOrder' ?
    useMutation<UpdateChangeOrderMetadataOptionMutation, UpdateChangeOrderMetadataOptionMutationVariables>(UPDATE_CHANGE_ORDER_METADATA_OPTION) :
    useMutation<UpdateMetadataOptionMutation, UpdateMetadataOptionMutationVariables>(UPDATE_METADATA_OPTION)

  const setOptionConfig = async (value: string, deprecated = false) => {
    await updateMetadataOption({
      variables: {
        input: {
          key: field.key,
          option: value,
          deprecated
        }
      }
    })
  }

  //fires on empty and selection
  const onChange = async (value: string) => {
    const newSelection = value && !Object.entries(field.options).find(([o]) => value === o)

    if (newSelection) {
      await setOptionConfig(value)
    }

    if (value) {
      controllerOnChange(value)
      setQuery('')
    }
  }

  const options = Object.entries(field.options).sort(([a], [b]) =>
    (a < b) ? -1 :
    (a > b) ? 1 :
    0)

  const activeOptions = options.filter(([_, {deprecated}]) => !deprecated)

  const queriedOption = options.find(([o]) => query === o)

  const newOption = query && !queriedOption
  const deprecatedOption = queriedOption?.[1]?.deprecated && queriedOption


  return (
    <Combobox as='div' className='grow relative w-fit'
      immediate
      {...combobox} onChange={onChange}>
      {({ open }) =>
        <>
          <ComboboxInput
            className={`${inputClasses}`}
            style={{paddingLeft: badgeWidth ? `${12 + badgeWidth}px` : 'revert-layer'}}
            value={query}
            onChange={s => setQuery(s.target.value)} />
          {combobox.value &&
            <span ref={ref} className='rounded bg-green-200 px-1 absolute top-0 bottom-0 my-2 left-2'> {combobox.value} </span>
          }
          {(open || loadingOptionUpdate) &&
            <ComboboxOptions
              static
              anchor='top'
              className='bg-white rounded-md absolute z-[80] w-[var(--input-width)] p-2 text-gray-800 shadow-md text-sm max-h-96 overflow-y-auto'
            >
              {activeOptions.map(([option, cfg]) => (
                <div key={option}>
                  <ComboboxOption value={option}
                    className='data-[focus]:bg-brand-500 data-[focus]:text-white cursor-pointer p-3 flex items-center h-12'
                  >
                    {option}
                    <InnerButton
                      onMenuClicked={async () => {
                        await setOptionConfig(option, true)
                        setQuery('')
                      }}
                      loading={loadingOptionUpdate}
                      menuText='Deprecate' menuTextDescription='Existing instances of the option will remain but the option can no longer be selected for future updates'/>
                  </ComboboxOption>
                </div>
              ))}
              {deprecatedOption && letF(deprecatedOption[0], option =>
                <div key={'deprecated_' + option}>
                  <ComboboxOption value={option}
                    className='data-[focus]:bg-brand-500 data-[focus]:text-white cursor-pointer p-3 flex items-center h-12'
                  >
                    Deprecated: {query}
                    <InnerButton onMenuClicked={() => setOptionConfig(option, false)} loading={loadingOptionUpdate}
                      menuText='Restore' menuTextDescription='Restore the deprecated option so that it can be used in future updates'/>
                  </ComboboxOption>
                </div>
              )}
              {(newOption || (activeOptions.length === 0 && !deprecatedOption)) &&
                <ComboboxOption key={'__new__'} value={query} disabled={!query}
                  className='data-[focus]:bg-brand-500 data-[focus]:text-white cursor-pointer p-3 flex items-center h-12'
                >
                  {query ? `Create New: ${query}` : `Enter Option Name` }
                </ComboboxOption>
              }
            </ComboboxOptions>
          }
        </>
      }
    </Combobox>
  )
}

type InnerButtonProps = {
  menuText: string
  menuTextDescription: string
  onMenuClicked: () => void
  loading: boolean
}
const InnerButton = (props: InnerButtonProps) =>
  <Popover className="relative ml-auto">
    <PopoverButton className='mr-1 py-1 px-1 right-0 top-0 bottom-0 flex items-center hover:bg-black hover:bg-opacity-50 rounded'>
      <EllipsisHorizontalIcon className='w-4 text-gray-400'/>
    </PopoverButton>
    <PopoverPanel anchor='bottom end' className='z-[100] w-64 text-xs rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none right-0 py-1 mt-1'>
      <button type='button' className='hover:bg-brand-600 hover:text-white w-full rounded-none py-2 px-3 text-left'
        onClick={props.onMenuClicked}
        onMouseDown={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        disabled={props.loading}
      >
        <div>{props.menuText} Option</div>
        <div className='text-xs font-light mt-2'>{props.menuTextDescription}</div>

      </button>
    </PopoverPanel>
  </Popover>
