import { QuantityUnit } from 'src/shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

export const creo: MapperConfig = {
  orgIds: ['charbroil-sandbox', 'charbroil'],
  name: 'Creo',
  type: 'Upload',
  Logo: DocumentIcon,
  rootIsTopLevel: true,
  fileToRows: {
    parsers: [{
      fileType: 'txt',
      parserId: 'creo'
    }, {
      fileType: 'bom',
      parserId: 'creo'
    }, {
      fileType: 'json',
      parserId: 'creo'
    }]
  },
  importOutputFields: {
    metadata: [
      'material',
      'finish',
      'artwork-number',
      'sheet-metal-thickness',
      'factory-drawing-number',
      'factory-part-number',
      'alt-language-description',
    ]
  },
  // rowsToBom: [{
  //   type: 'FilterRemove',
  //   config: {
  //     conditions: [{
  //       type: 'contains',
  //       column: 'part_number',
  //       value: 'G702'
  //     }, {
  //       type: 'contains',
  //       column: 'part_number',
  //       value: 'G329'
  //     }]
  //   }
  // }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        material: {
          type: 'simple',
          value: '{{row.material}}'
        },
        finish: {
          type: 'simple',
          value: '{{row.finish}}'
        },
        'artwork-number': {
          type: 'simple',
          value: '{{row.art_number}}'
        },
        'sheet-metal-thickness': {
          type: 'simple',
          value: '{{row.smt_thickness}}'
        },
        'factory-drawing-number': {
          type: 'simple',
          value: '{{row.factory_drawing_number}}'
        },
        'factory-part-number': {
          type: 'simple',
          value: '{{row.factory_part_number}}'
        },
        'alt-language-description': {
          type: 'simple',
          value: '{{row.alternate_desc}}'
        }
      },
      hierarchy: {
        value: '{{row.hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.name}}',
        type: 'simple'
      },
      cadRev: {
        type: 'empty'
      },
      categoryId: {
        type: 'simple',
        value: 'mechanical-part'
      },
      isOffTheShelf: {
        value: 'false',
        type: 'simple'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}

export const spreadsheet: MapperConfig = {
  name: 'Latest BOM Mapper',
  type: 'Upload',
  Logo: DocumentIcon,
  orgIds: ['charbroil-sandbox', 'charbroil'],
  noProject: true,
  rootIsTopLevel: true,
  importOutputFields: {
    metadata: ['material', 'finish', 'sheet-metal-thickness', 'factory-part-number', 'factory-drawing-number', 'alt-language-description']
  },
  fileToRows: {
    xlsx: {
      enable: true,
      firstRow: 0  // Skip header row
    }
  },
  rowsToBom: [{
    type: 'FilterRemove',
    config: {
      conditions: [{
        type: 'empty',
        column: '客户图号_c_b_bom',
      }, {
        type: 'eq',
        column: '客户图号_c_b_bom',
        value: '/'
      }, {
        type: 'empty',
        column: '阶层',
      }, {
        type: 'contains',
        column: '客户图号_c_b_bom',
        value: ' '
      }]
    }
  }, {
    type: 'OrderedLevelHierarchy',
    config: {
      input: '${row["阶层"]}',
      skipHierarchyJumps: true
    }
  }],
  standardizeBom: {
    columns: {
      // Map metadata fields
      metadata: {
        'material': {
          type: 'simple',
          value: '${row.material}'
        },
        'finish': {
          type: 'simple',
          value: '${row.finish}'
        },
        'sheet-metal-thickness': {
          type: 'simple',
          value: '${row.gage}'
        },
        'factory-part-number': {
          type: 'simple',
          value: '${row["制造编码_md_no"]}'
        },
        'factory-drawing-number': {
          type: 'simple',
          value: '${row["md图号_md_drawing_no"]}'
        },
        'alt-language-description': {
          type: 'simple',
          value: '${row["零件名称_中文_ch_des"]}'
        },
      },
      // Map standard fields
      hierarchy: {
        type: 'simple',
        value: '${row.__hierarchy}'
      },
      partNumber: {
        type: 'simple',
        value: '${row["客户图号_c_b_bom"]}'
      },
      name: {
        type: 'simple',
        value: '${row["英文名称_eng_des"] || row["客户图号_c_b_bom"]}'
      },
      summary: [{
        default: true,
        onMatch: {
          type: 'empty'
        }
      }],
      quantity: {
        type: 'simple',
        value: '${row["数量"]}'
      },
      units: {
        type: 'quantity',
        value: 'each' as QuantityUnit
      },
      categoryId: [{
        conditions: [{
          type: 'eq',
          column: 'material',
          value: 'ASSY'
        }],
        onMatch: {
          type: 'simple',
          value: 'mechanical-part'
        }
      }, {
        conditions: [{
          type: 'eq',
          column: 'material',
          value: 'SPCC'
        }],
        onMatch: {
          type: 'simple',
          value: 'mechanical-part'
        }
      }, {
        conditions: [{
          type: 'startsWith',
          column: 'material',
          value: 'SUS'
        }],
        onMatch: {
          type: 'simple',
          value: 'mechanical-part'
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: 'mechanical-part'
        }
      }],
      isOffTheShelf: {
        type: 'simple',
        value: 'false'
      }
    }
  }
}

