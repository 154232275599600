import { QuantityUnit } from 'src/shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

export const allParts: MapperConfig = {
  orgIds: ['copper', 'copper-sandbox'],
  name: 'All Parts',
  type: 'Upload',
  superUserOnly: true,
  Logo: DocumentIcon,
  rootIsTopLevel: true,
  importOutputFields: {
    metadata: ['consigned-status', 'manufacturer', 'manufacturer-pn']
  },
  fileToRows: {
    xlsx: {
      enable: true,
      firstRow: 1
    }
  },
  // rowsToBom: [{
  //   type: 'FilterRemove',
  //   config: {
  //     conditions: [{
  //       type: 'contains',
  //       column: 'part_number',
  //       value: 'G702'
  //     }, {
  //       type: 'contains',
  //       column: 'part_number',
  //       value: 'G329'
  //     }]
  //   }
  // }],
  standardizeBom: {
    columns: {
      sources: [{
        distributorName: {
          type: 'simple',
          value: '${row.vendor_1}'
        },
        distributorSku: {
          type: 'simple',
          value: '${row.vendor_1_part_number}'
        },
        // need price
      }],
      metadata: {
        'consigned-status': {
          type: 'simple',
          value: '{{row.consigned_status}}'
        },
        manufacturer: {
          type: 'simple',
          value: '{{row.manufacturer}}'
        },
        'manufacturer-pn': {
          type: 'simple',
          value: '{{row.mfg_pn}}'
        }
      },
      hierarchy: {
        value: '1.{{rowIndex}}',
        type: 'simple'
      },
      partNumber: {
        value: "${padStart(row.part_number, 4, '0')}",
        type: 'simple'
      },
      cadRev: {
        type: 'empty'
      },
      categoryId: {
        type: 'simple',
        value: "${kebabCase((row.category || 'other') + ' ' + (row.item_type === '-' ? '' : row.item_type))}"
      },
      isOffTheShelf: [{
        onMatch: {
          value: 'true',
          type: 'simple'
        },
        conditions: [{
          type: 'contains',
          column: 'make_buy',
          value: 'OTS'
        }]
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: 'false'
        }
      }],
      name: {
        value: "${row.short_description || 'No name'}",
        type: 'simple'
      },
      summary: {
        value: '{{row.long_description}}',
        type: 'simple'
      },
      quantity: {
        value: '1',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}

export const bom: MapperConfig = {
  orgIds: ['copper', 'copper-sandbox'],
  name: 'BOM',
  type: 'Upload',
  superUserOnly: true,
  Logo: DocumentIcon,
  rootIsTopLevel: true,
  importOutputFields: {
    metadata: []
  },
  rowsToBom: [{
    type: 'OrderedLevelHierarchy',
    config: {
      input: '{{row.level}}'
    }
  }],
  standardizeBom: {
    columns: {
      hierarchy: {
        value: '{{row.__hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        value: "${padStart(row.part_number, 4, '0')}",
        type: 'simple'
      },
      cadRev: {
        type: 'empty'
      },
      categoryId: {
        type: 'ignore'
      },
      isOffTheShelf: {
        type: 'ignore'
      },
      name: {
        type: 'ignore'
      },
      summary: {
        type: 'ignore'
      },
      quantity: {
        value: '${row.qty_per_assy}',
        type: 'simple',
      },
      units: [{
        conditions: [{
          type: 'eq',
          column: 'qty_per_assy',
          value: 'meter'
        }],
        onMatch: {
          value: 'm' as QuantityUnit,
          type: 'quantity'
        }
      }, {
        conditions: [{
          type: 'eq',
          column: 'qty_per_assy',
          value: 'feet'
        }],
        onMatch: {
          value: 'ft' as QuantityUnit,
          type: 'quantity'
        }
      }, {
        conditions: [{
          type: 'eq',
          column: 'qty_per_assy',
          value: 'drop'
        }],
        onMatch: {
          value: 'drop' as QuantityUnit,
          type: 'quantity'
        }
      }, {
        default: true,
        onMatch: {
          value: 'each' as QuantityUnit,
          type: 'quantity'
        }
      }],
    }
  }
}
