
import {
  PartVersionSelectQuery,
  PartCompareQueryVariables
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import * as ListBox from 'src/components/ListBox'
import classNames from 'classnames'

const MAIN_BRANCH = -1 as const;

export const QUERY = gql`
query PartVersionSelectQuery ($partNumber: String!) {
  partProto (partNumber: $partNumber) {
    currentPublishId
    instances {
      version
      inChangeOrderNumber
      branch
      updateToPublishId
    }
  }
}
`

type CellProps = {
  defaultVersion: string
  name: string
  orderNumber?: number
  size?: 'sm' | 'default'
  hideRing?: boolean
  className?: string
  versionRange?: boolean
  wrapperClassName?: string
  showMergeConflicts?: boolean
}

export const Loading = ({ ...props }: CellProps) => {
  return <PartVersionSelect {...props} />
}

type SuccessProps = CellSuccessProps<PartVersionSelectQuery, PartCompareQueryVariables> & CellProps
export const Success = ({ ...props }: SuccessProps) => {
  return <PartVersionSelect {...props} />
}

import { FailureErrorBar } from '../Failure/Failure'
import { sortInstances } from 'src/lib/version';
export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading part versions' {...props}>
    <PartVersionSelect {...props} />
  </FailureErrorBar>
}

type PartVersionSelectProps = CellProps & Partial<CellSuccessProps<PartVersionSelectQuery, PartCompareQueryVariables>>

export const PartVersionSelect = ({ partProto, defaultVersion, name, variant, orderNumber, size, hideRing, className, wrapperClassName, versionRange, showMergeConflicts }: PartVersionSelectProps) => {
  variant = variant || 'plain'
  size = size || 'default'

  const instances = partProto?.instances ? sortInstances(
    partProto.instances.filter(i => {
      if (i.branch === MAIN_BRANCH) return true
      if (i.inChangeOrderNumber === null) return true
      if (i.inChangeOrderNumber === orderNumber) return true
      return false
    })
    .map(i => ({
      ...i,
      hasDuplicate: (i.branch !== MAIN_BRANCH && i.updateToPublishId != partProto?.currentPublishId) &&
        (partProto.instances.filter(inside => inside.version === i.version).length > 1)
    }))
  ) : [{ version: defaultVersion, branch: undefined, hasDuplicate: false }]

  const buttonCn = classNames(
    size === 'sm' && 'py-0 leading-4 -ml-1 pl-1 pr-6 min-w-14 [&>*:nth-child(2)]:pr-0 text-xs',
    className
  )

  const optionClasses = classNames(
    'py-3',
    size === 'sm' && '!py-2',
  )

  return <ListBox.HookedListBox variant={variant} name={name} defaultValue={defaultVersion}>
    <ListBox.UncontrolledButton
      variant={variant}
      hideRing={hideRing}
      className={buttonCn}
      size={size === 'sm' ? 'sm' : undefined}
      wrapperClassName={wrapperClassName}
      displayFunction={v => v.value === '*' ? 'Always Update' : v.value} />
    <ListBox.Options align='left'>
      {
        versionRange && <ListBox.Option key={'*'} className={optionClasses} value={'*'} display={`Always Update`} />
      }
      {instances.map(i => (
        <ListBox.Option key={`${i.branch ?? 'new'}:${i.version}`} className={optionClasses} value={i.version}
          display={i.version + ((showMergeConflicts && i.hasDuplicate) ? ' (in this change order)' : '')} />
      ))}
    </ListBox.Options>
  </ListBox.HookedListBox>
}
