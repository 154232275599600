import { useLayoutEffect } from 'react'
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from 'headlessuinext'
import { inputClasses } from 'src/components/Input'
import { useState, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface ComboboxOption {
  id: string
  display: string
}

type ComboboxProps = {
  selectedId: string | null,
  onSelectId: (id: string) => void
  options: ComboboxOption[]
  autoFocus?: boolean
  placeholder?: string
  testId?: string
}
export default ({selectedId, onSelectId, options, autoFocus, placeholder, testId}: ComboboxProps) => {
  const pillRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [badgeWidth, setBadgeWidth] = useState(0)
  testId = testId || 'combobox'

  useLayoutEffect(() => {
    if (!pillRef.current) return
    const { width } = pillRef.current?.getBoundingClientRect()
    setBadgeWidth(width);
  }, [selectedId, pillRef.current]);

  const [query, setQuery] = useState('')
  const selectedOption = options.find(o => o.id === selectedId)

  //fires on empty and selection
  const onChange = async (value: string | null) => {
    setQuery('')
    if (value) {
      onSelectId(value)
    }
  }

  const filteredOptions = options
    .filter(o => {
      if (!query) return true
      return o.display.toLowerCase().includes(query.toLowerCase())
    })
    .sort((a, b) => {
      if (a.display < b.display) return -1
      if (a.display > b.display) return 1
      return 0
    })


  const className = twMerge(inputClasses, 'h-10 bg-transparent cursor-pointer transition-all whitespace-nowrap')

  return (
    <Combobox
      as='div'
      autoFocus={autoFocus}
      className='grow relative w-full text-xs hover:bg-blue-50 data-[open]:bg-blue-50 cursor-pointer min-w-52'
      immediate
      value={selectedId}
      data-testid={`${testId}`}
      onChange={onChange}>
      {({ open }) =>
        <>
          <ComboboxInput
            ref={inputRef}
            placeholder={selectedId ? '' : placeholder}
            className={className}
            style={{paddingLeft: badgeWidth ? `${12 + badgeWidth}px` : 'revert-layer'}}
            value={query}
            onChange={s => setQuery(s.target.value)} />
          {selectedOption &&
            <div
              ref={pillRef}
              onClick={() => inputRef.current?.focus()}
              className='rounded bg-pink-500 text-white px-2 absolute top-1 bottom-1 left-1 flex items-center'>
              {selectedOption.display}
            </div>
          }
          {(open) &&
            <ComboboxOptions
              static
              anchor='top'
              className='bg-white mt-1 rounded-md absolute z-[80] w-[var(--input-width)] text-gray-800 shadow-md text-xs overflow-y-auto'
            >
              {filteredOptions.map((option) => (
                <ComboboxOption
                  value={option.id}
                  key={option.id}
                  data-log={console.log(`${testId}.option.${option.id}`)}
                  data-testid={`${testId}.option.${option.id}`}
                  className='data-[focus]:bg-brand-500 data-[focus]:text-white cursor-pointer p-3 flex items-center'
                >
                  {option.display}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          }
        </>
      }
    </Combobox>
  )
}
