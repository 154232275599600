import { useMemo } from "react"
import { findAncestors } from 'api/src/lib/bom'

import { RootInput, addVersionWarnings, useHierarchyControllerFactory } from '../PartHierarchy'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'

import 'src/lib/queries'
import PartHierarchy from '../PartHierarchy/PartHierarchy'
import { usePartsCache } from 'src/lib/usePartsCache'

const WhereUsed: React.FC<{ highlightedPartNumber: string }> = ({
  highlightedPartNumber
}) => {
  const { data } = usePartsCache()

  const controller = useHierarchyControllerFactory({
    roots: data?.orphanParts || [],
    hideFilterUi: true,
    initialFilters: [{
      key: 'field.partNumber',
      type: 'Equals',
      value: highlightedPartNumber
    }]
  })

  if (!data) {
    return <LoadingSpinner className='flex p-10 items-center justify-center' />
  }

  return <>
    <div className='flex flex-col gap-3 z-10 relative'>
      <PartHierarchy hierarchyController={controller} />
    </div>
  </>
}

export default WhereUsed
