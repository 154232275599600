// web/src/pages/NexarCallbackPage/NexarCallbackPage.tsx

import { useEffect, useState } from 'react'
import { navigate, routes, useParams } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'

const HANDLE_CALLBACK = gql`
  mutation HandleNexarCallback($input: NexarCallbackInput!) {
    handleNexarCallback(input: $input)
  }
`

const NexarCallbackPage = () => {
  const [error, setError] = useState<string | null>(null)
  const [handleCallback] = useMutation(HANDLE_CALLBACK)

  useEffect(() => {
    const processCallback = async () => {
      try {
        // Get code and state from URL parameters
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')
        const state = params.get('state')
        const error = params.get('error')

        if (error) {
          setError(`Auth failed: ${error}`)
          return
        }

        if (!code || !state) {
          setError('Missing code or state')
          return
        }

        // Parse the state to get our context

        // Handle the callback
        await handleCallback({
          variables: {
            input: {
              code,
              state
            }
          }
        })

        console.log('Deocign state', state)
        const { whenDoneRedirectUrl } = JSON.parse(state)
        console.log('navigating to ', whenDoneRedirectUrl)
        window.location.href = whenDoneRedirectUrl
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred')
      }
    }

    processCallback()
  }, [handleCallback])

  if (error) {
    return (
      <>
        <MetaTags title="Auth Error" />
        <div className="p-4">
          <h1 className="text-xl font-bold text-red-600">Authentication Error</h1>
          <p className="mt-2">{error}</p>
          <button
            onClick={() => navigate(routes.root())}
            className="mt-4 px-4 py-2 bg-gray-200 rounded"
          >
            Return Home
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <MetaTags title="Completing Authentication" />
      <div className="p-4">
        <h1 className="text-xl">Completing Authentication...</h1>
        <p className="mt-2">Please wait while we complete the authentication process.</p>
      </div>
    </>
  )
}

export default NexarCallbackPage