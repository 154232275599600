import {
  Part,
  Dependency,
  Source,
  Artifact,
  S3FileRef,
  PartProto,
  Organization,
  Maybe,
  Distributor,
  DependencySection,
  PartCategory,
  DependencyDisplayFragment
} from 'types/graphql'
import { ResolvedMetadata, resolveMetadata } from 'src/lib/metadata'
import { displayUnits } from '../Dependencies'
import type { ChangeType as MetadataChangeType } from '../Metadata'
import { getThumbnail } from '../Artifacts/Artifacts'

export type PartArtifact = Pick<Artifact, 'id' | 'isThumbnail' | 'filename' | 'fileId'> & {
  file: Pick<S3FileRef, 'url' | 'inlineUrl' | 'size' | 'contentType' | 'timestamp'>
}

type WholeChange = 'added' | 'removed'

export type EditMode = 'locked' | 'edit'
type DistributorChangeInput = Omit<Distributor,
  '__typename'>
type SourceChangeInput = Omit<Source,
  'distributor' | '__typename'> & {
    distributor?: DistributorChangeInput | null
  }
type SourceInputChanges = Pick<SourceChangeInput,
  'priority' |
  'distributor' |
  'distributorId' |
  'id' |
  'distributorSku' |
  'price' |
  'priceCurrency' |
  'leadTimeDays' |
  'perQuantity' |
  'perQuantityUnit' |
  'stock' |
  'comment' |
  'url'
>

export type SourceFieldChange = 'added' | 'removed' | 'modified' | null

type ChangeFieldName = keyof Omit<SourceInputChanges, 'priority' | 'distributor' | 'id'>
type FieldChange = {
  [Key in ChangeFieldName]: SourceFieldChange
}
export type SourceChange = {
  priority: number
  wholeSource?: WholeChange
  fields?: FieldChange
};

export type PartChangePart = Pick<Part,
  'partNumber'
  | 'name'
  | 'version'
  | 'lifeCycle'
  | 'isRoot'
  | 'isOffTheShelf'
  | 'summary'
  | 'metadata'
  | 'changeMessage'
  | 'transitionPlan'
  | 'cadRev'> & {
  dependencies: DependencyDisplayFragment[]
  sources: SourceInputChanges[]
  artifacts: PartArtifact[]
  // optional because it is only used when editing
  ancestors?: {
    part: Pick<Part, 'partNumber'>
  }[]
}

type IncomingPart = PartChangePart & {
  changeMessage?: Part['changeMessage']
  transitionPlan?: Part['transitionPlan']
  updateToPublishId?: Part['updateToPublishId']
}

type PartDiffInput = IncomingPart & {
  proto: Pick<PartProto, 'partNumber'> & {
    category: Pick<PartCategory, 'name'>
  }
  updateTo?: Maybe<PartChangePart>
}

// type PartWithUpdatePart = PartChangePart & {
//   updateTo?: Maybe<PartChangePart>
// }

export type PartUpdate = {
  proto: {}
  headPart: PartChangePart
  incomingPart?: IncomingPart
}

type OrgForChanges = Pick<Organization, 'metadataSchema'>
type MatchType = 'nothing' | 'edit' | 'deletion'
type OmissionType = 'addition'
export type ArtifactChangeType = MatchType | OmissionType
export type DependencyChange = {
  partNumber: string
  wholeDependency?: WholeChange
  originalSection?: DependencySection
  version?: boolean
  versionNeedsRebase?: boolean
  versionRange?: boolean
  quantity?: boolean
  referenceDesignator?: boolean
  groupChange?: {
    from?: string,
    to?: string,
  }
}
export type PartDiffSummary = {
  incomingPart: IncomingPart
  headPart?: Maybe<PartChangePart>
  type: 'update' | 'none' | 'create'
  proto: {
    partNumber: string
    currentPublishId?: PartProto['currentPublishId']
    category: Pick<PartCategory, 'name' | 'id'>
  }
  fields: {
    metadata: {
      hasChange: boolean
      change?: (ResolvedMetadata & { changeType?: MetadataChangeType })[]
    }
    name : {
      hasChange: boolean
    }
    lifeCycle : {
      hasChange: boolean
    }
    summary : {
      hasChange: boolean
    }
    cadRev : {
      hasChange: boolean
    }
    artifacts: {
      hasChange: boolean
      change?: {
        changeType: ArtifactChangeType
        artifact: PartArtifact
        concreteIndex: number
      }[]
    }
    thumbnail: {
      hasChange: boolean,
    }
    isOffTheShelf: {
      hasChange: boolean
    }
    isRoot: {
      hasChange: boolean
    }
    sources: {
      hasChange: boolean
      change: SourceChange[]
    }
    dependencies: {
      hasChange: boolean
      change: DependencyChange[]
    }
  }
}

/*
 * Calculates all changes for all parts. Also calculates whether a part
 * has CAD changes (checking also if any of its dependencies have CAD changes).
 *
 * CAD changes are important to flag, because a part can only change the version
 * of a CAD dependency if that dependency does not have CAD changes.
 */
const calculateAllChanges: (currentOrg: OrgForChanges, parts: PartDiffInput[]) => PartDiffSummary[] = (currentOrg, parts) => {
  return parts.map(incomingPart => {

    const diffSummary: Omit<PartDiffSummary, 'type'> = {
      incomingPart,
      proto: incomingPart.proto,
      fields: {
        metadata: {
          hasChange: false
        },
        name : {
          hasChange: false
        },
        lifeCycle: {
          hasChange: false
        },
        summary : {
          hasChange: false
        },
        cadRev : {
          hasChange: false
        },
        artifacts: {
          hasChange: false
        },
        thumbnail: {
          hasChange: false
        },
        sources: {
          hasChange: false,
          change: []
        },
        dependencies: {
          hasChange: false,
          change: []
        },
        isRoot: {
          hasChange: false
        },
        isOffTheShelf: {
          hasChange: false
        }
      }
    }

    const headPart = incomingPart.updateTo
    if (!headPart) {
      return {
        ...diffSummary,
        type: 'create'
      }
    }

    // Overview
    diffSummary.fields.name.hasChange = headPart.name !== incomingPart.name
    diffSummary.fields.lifeCycle.hasChange = headPart.lifeCycle !== incomingPart.lifeCycle
    diffSummary.fields.summary.hasChange = headPart.summary !== incomingPart.summary
    diffSummary.fields.cadRev.hasChange = headPart.cadRev !== incomingPart.cadRev
    diffSummary.fields.isOffTheShelf.hasChange = headPart.isOffTheShelf !== incomingPart.isOffTheShelf
    diffSummary.fields.isRoot.hasChange = headPart.isRoot !== incomingPart.isRoot

    const resolvedHeadMetadata = headPart.metadata ? resolveMetadata(currentOrg.metadataSchema, headPart.metadata as any) : undefined
    const incomingMetadata = resolveMetadata(currentOrg.metadataSchema, incomingPart.metadata as any);

    const metadataWithChange = resolvedHeadMetadata ?
      resolvedHeadMetadata.map((old) => {
        const current = incomingMetadata.find(m => m.displayName === old.displayName)

        const changeType = !current ? 'Remove'
          : JSON.stringify(current.entry) !== JSON.stringify(old.entry) ? 'Change' : undefined

        const ref = current ?? old;

        return {
          ...ref,
          changeType: changeType as MetadataChangeType
        }
      }).concat(
        incomingMetadata.filter(m => !resolvedHeadMetadata.find(old => old.displayName === m.displayName)).map(additions => ({
          ...additions,
          changeType: 'Add' as MetadataChangeType
        }))
      ) :
      incomingMetadata.map(m => ({...m, changeType: undefined as unknown as MetadataChangeType}))

    diffSummary.fields.metadata.hasChange = metadataWithChange.some(change => change.changeType)
    diffSummary.fields.metadata.change = metadataWithChange

    // Artifacts
    let concreteIndex = 0;
    const matches = headPart.artifacts.map(oldArtifact => {
      const nameMatch = incomingPart.artifacts.find(newArtifact => newArtifact.filename === oldArtifact.filename);

      const changeType = (!nameMatch ? 'deletion'
        : (nameMatch.fileId !== oldArtifact.fileId || nameMatch.isThumbnail !== oldArtifact.isThumbnail) ? 'edit'
          : 'nothing') as MatchType as ArtifactChangeType

      if (changeType !== 'deletion') {
        concreteIndex += 1;
      }

      return {
        changeType,
        concreteIndex,
        artifact: nameMatch ?? oldArtifact
      }
    })

    const additions = incomingPart.artifacts.filter(newArtifact => {
      const match = headPart.artifacts.find(oldArtifact => oldArtifact.filename === newArtifact.filename)
      return !match;
    }).map(newArtifact => ({
      changeType: 'addition' as OmissionType as ArtifactChangeType,
      artifact: newArtifact,
      concreteIndex: concreteIndex++
    }))

    const artifactChangeInfo = matches.concat(additions)
    const hasArtifactsChange = artifactChangeInfo.some(change => change.changeType !== 'nothing')
    diffSummary.fields.artifacts.hasChange = hasArtifactsChange
    diffSummary.fields.artifacts.change = artifactChangeInfo

    if (hasArtifactsChange) {
      const headThumbnail = getThumbnail(headPart.artifacts)
      const incomingThumbnail = getThumbnail(incomingPart.artifacts)
      if (headThumbnail?.fileId !== incomingThumbnail?.fileId) {
        diffSummary.fields.thumbnail.hasChange = true;
      }
    }

    const findDependencyChanges = (headDependencies: PartDependency[], incomingDependencies: PartDependency[]) => {
      const removedAndModified = headDependencies.reduce((memo, head) => {
        const changes: DependencyChange = {
          partNumber: head.to.partNumber,
          originalSection: head.section,
        }
        const incoming = incomingDependencies.find(incoming => incoming.to.partNumber === head.to.partNumber)
        if (!incoming) {
          return [...memo, { ...changes, wholeDependency: 'removed' }] as DependencyChange[]
        }
        const fullChanges = {
          ...changes,
          version: incoming.to.publishId !== head.to.publishId,
          // used in the UI to disambiguate when version looks the same
          versionNeedsRebase: incoming.to.version === head.to.version && incoming.to.publishId !== head.to.publishId,
          versionRange: incoming.toVersionRange !== head.toVersionRange,
          quantity: incoming.quantity !== head.quantity || displayUnits(incoming.units) !== displayUnits(head.units),
          referenceDesignator: Boolean(incoming.referenceDesignator || head.referenceDesignator) && incoming.referenceDesignator !== head.referenceDesignator,
        }
        return [...memo, fullChanges]
      }, [] as DependencyChange[])
      return incomingDependencies.reduce((memo, incoming) => {
        const changes: DependencyChange = {
          partNumber: incoming.to.partNumber,
        }
        const head = headDependencies.find(head => head.to.partNumber === incoming.to.partNumber)
        if (!head) {
          return [...memo, { ...changes, wholeDependency: 'added' } as DependencyChange]
        }
        if (head.groupId !== incoming.groupId) {
          const ref = memo.find(m => m.partNumber === head.to.partNumber)
          if (ref) ref.groupChange = {
            from: head.groupId ?? undefined,
            to: incoming.groupId ?? undefined
          }
        }
        return memo
      }, removedAndModified)
    }

    const dependencyChangeInfo = findDependencyChanges(headPart.dependencies, incomingPart.dependencies)

    const dependenciesHaveChange = dependencyChangeInfo.some(changeInfo => {
      return changeInfo.quantity || changeInfo.version || changeInfo.wholeDependency || changeInfo.groupChange || changeInfo.versionRange
    })

    diffSummary.fields.dependencies.hasChange = dependenciesHaveChange
    diffSummary.fields.dependencies.change = dependencyChangeInfo

    type PossibleTypes = string | number | undefined | null
    const compareSourceField = (head: PossibleTypes, incoming: PossibleTypes) => {
      if (!head && incoming) return 'added'
      if (head && !incoming) return 'removed'
      if (!head && !incoming) return null
      if (head !== incoming) return 'modified'
      return null
    }
    const removedAndModifiedSources: SourceChange[] = headPart.sources.reduce((memo, head) => {
      const incoming = incomingPart.sources.find(incoming => incoming.priority === head.priority)
      if (!incoming) {
        return [...memo, { priority: head.priority, wholeSource: 'removed' }]
      }

      const fullChanges: SourceChange = {
        priority: head.priority,
        fields: {
          distributorId: compareSourceField(head.distributorId, incoming.distributorId),
          distributorSku: compareSourceField(head.distributorSku, incoming.distributorSku),
          price: compareSourceField(head.price, incoming.price),
          priceCurrency: compareSourceField(head.priceCurrency, incoming.priceCurrency),
          leadTimeDays: compareSourceField(head.leadTimeDays, incoming.leadTimeDays),
          perQuantity: compareSourceField(head.perQuantity, incoming.perQuantity),
          perQuantityUnit: compareSourceField(head.perQuantityUnit, incoming.perQuantityUnit),
          stock: compareSourceField(head.stock, incoming.stock),
          comment: compareSourceField(head.comment, incoming.comment),
          url: compareSourceField(head.url, incoming.url),
        }
      }
      return [...memo, fullChanges]
    }, [] as SourceChange[])

    // added goes last
    const sourceChanges = incomingPart.sources.reduce((memo, incoming) => {
      const head = headPart.sources.find(head => head.priority === incoming.priority)
      if (!head) {
        return [...memo, { priority: incoming.priority, wholeSource: 'added' } as SourceChange]
      }
      return memo
    }, removedAndModifiedSources)

    const sourcesHaveChange = sourceChanges.some(changeInfo => {
      return changeInfo.wholeSource ||
        changeInfo.fields?.distributorId ||
        changeInfo.fields?.distributorSku ||
        changeInfo.fields?.price ||
        changeInfo.fields?.priceCurrency ||
        changeInfo.fields?.leadTimeDays ||
        changeInfo.fields?.perQuantity ||
        changeInfo.fields?.perQuantityUnit ||
        changeInfo.fields?.stock
    })

    diffSummary.fields.sources.hasChange = sourcesHaveChange
    diffSummary.fields.sources.change = sourceChanges

    const anyChanges = Object.values(diffSummary.fields).some(field => field.hasChange)

    return {
      ...diffSummary,
      headPart: incomingPart.updateTo,
      type: anyChanges ? 'update' : 'none'
    }
  })
}

export default calculateAllChanges
