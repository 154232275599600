import { useContext, createContext, useState } from 'react'
import type {
  FindChangeOrderQuery,
  AddReviewerMutation,
  AddReviewerMutationVariables,
  CreateUserGroupReviewConfigMutation,
  CreateUserGroupReviewConfigMutationVariables,
  DeleteReviewerMutation,
  DeleteReviewerMutationVariables,
  ChangeOrderResponse,
  ChangeOrderReviewer,
  ReviewerRole,
  UserGroup,
  WorkflowRule,
  ChangeOrderReviewersFragment
} from 'types/graphql'
import { CellSuccessProps, useMutation } from '@redwoodjs/web'
import { registerFragment } from '@redwoodjs/web/apollo'
import {
  CheckIcon,
  XMarkIcon,
  ExclamationCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import {
  ChatBubbleLeftIcon,
  ClockIcon,
  UserPlusIcon,
  UserGroupIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline'

import { useLifeCycleStages, resolveLifeCycle, ResolvedLifeCycleStage } from "src/lib/lifecycle"

import AppContext from 'src/lib/appContext';
import * as ListBox from '../ListBox'
import * as Tooltip from 'src/components/ToolTip'
import { reportMutationError } from 'src/lib/reportError';

import { QUERY } from './ChangeOrderCell';
import { groupBy } from 'src/lib/util';

type QueryType = CellSuccessProps<FindChangeOrderQuery>;
type LifeCyclesType = ReturnType<typeof useLifeCycleStages>
type COType = QueryType['changeOrder'];
type User = QueryType['users'][number]
type Group = QueryType['currentOrg']['userGroups'][number]

export const changeOrdersReviewersFragment = registerFragment(gql`
  fragment ChangeOrderReviewersFragment on ChangeOrder {
    _id
    highestLifeCycle
    invalidLifeCycle
    creator {
      id
    }
  }
`)

type ReviewsDataProps = {
  changeOrder: ChangeOrderReviewersFragment
  lifeCycleStages: LifeCyclesType
  allUsers: User[]
  allGroups: Group[]
  reviewers: COType['reviewers']
  groupConfigs: COType['reviewerGroups']
  workflow: COType['workflow']
}

const isCompletedState = (changeOrder: COType) => changeOrder.state === 'Complete' || changeOrder.state === 'Cancelled'
const StatusIcon = ({reviewState, className = ''}: {reviewState: ChangeOrderResponse, className?: string}) => {
  const sizeCl = 'h-5 w-5'
  if (reviewState === 'Approve') {
    return <Tooltip.Container className={className}>
      <Tooltip.Message>
        Approved
      </Tooltip.Message>
      <CheckIcon className={`${sizeCl} text-green-500`} />
    </Tooltip.Container>
  }
  if (reviewState === 'RequestChanges') {
    return <Tooltip.Container className={className}>
      <Tooltip.Message>
        Changes requested
      </Tooltip.Message>
      <ExclamationCircleIcon className={`${sizeCl} text-yellow-400}`} />
    </Tooltip.Container>
  }
  if (reviewState === 'Dismissed') {
    return <Tooltip.Container className={className}>
      <Tooltip.Message>
        Review Dismissed
      </Tooltip.Message>
      <ClockIcon className={`${sizeCl}`} />
    </Tooltip.Container>
  }
  if (reviewState === 'Comment') {
    return <Tooltip.Container className={className}>
      <Tooltip.Message>
        Left comments
      </Tooltip.Message>
      <ChatBubbleLeftIcon className={`${sizeCl}`} />
    </Tooltip.Container>
  }
  return <Tooltip.Container className={className}>
    <Tooltip.Message>
      Waiting for review
    </Tooltip.Message>
    <ClockIcon className={`${sizeCl}`} />
  </Tooltip.Container>
}

//@ts-ignore
export const WorkflowContext = createContext<ReviewsDataProps>()

type ReviewerSelectProps = {
  loggedInId: string
  displayValue: string
  omit: User['id'][]
  onSelect: (selection: User | Group) => void
}

const ReviewerSelect = ({ displayValue, omit, onSelect, loggedInId }: ReviewerSelectProps) => {
  const workflowContext = useContext(WorkflowContext)
  //hot reload breaks context
  if (!workflowContext) return;
  const { allUsers, reviewers } = workflowContext;

  const userValues = allUsers.filter(u => {
    return !omit.find(id => id === u.id)
  })

  const me = userValues.find(u => u.id === loggedInId)
  const withoutMe = userValues.filter(u => u.id !== loggedInId)

  // Empty value, because we are not using the checked value function
  //@ts-ignore this is only an event emitter
  return <ListBox.ListBox onChange={onSelect} value=''>
    {({ open }) => (
      <>
        <div className={'relative'}>
          <ListBox.Button className='min-w-full' displayValue={displayValue} />
          <ListBox.Options className='min-w-full z-20 text-sm' open={open}>
            {userValues.length === 0 &&
              <div className='py-1 px-4'>No valid users</div>
            }
            {
              me ? <ListBox.Option
                key={'user-' + me.id}
                className='py-3'
                value={me}
                display={`${me.name} (You)`} /> : null
            }
            {withoutMe.map((user) => (
              <ListBox.Option
                key={'user-' + user.id}
                className='py-3'
                value={user}
                display={`${user.name}`} />
            ))}
          </ListBox.Options>
        </div>
      </>
    )}
  </ListBox.ListBox>
}

export const ADD_REVIEWER_MUTATION = gql`
mutation AddReviewerMutation ($input: ChangeOrderReviewerInput!) {
  createChangeOrderReviewer(input: $input) {
    user {
      name
    }
  }
}
`

export const CREATE_USER_GROUP_REVIEW_CONFIG_MUTATION = gql`
mutation CreateUserGroupReviewConfigMutation($input: CreateUserGroupReviewConfigInput!) {
  createUserGroupReviewConfig(input: $input) {
    groupId
  }
}
`

export const DELETE_REVIEWER_MUTATION = gql`
mutation DeleteReviewerMutation ($userId: String!, $orderNumber: Int!) {
  deleteChangeOrderReviewer(input: { userId: $userId, number: $orderNumber }) {
    user {
      name
    }
  }
}
`

type ReviewStatus = {
  valid: boolean,
  highestLifeCycle: ResolvedLifeCycleStage
  groupsReviews: Record<string, GroupReview>
}
type GroupReview = {
  groupId: string,
  activeRule: WorkflowRule | undefined,
  ruleConditionMet?: boolean,
  ruleLifeCycle: ResolvedLifeCycleStage | undefined
  requiredCount: number,
  approvedCount: number,
  approved: boolean,
  approvalReason: 'ownerOnly' | 'empty' | 'standard',
  name: string
  members: {
    userId: number,
    user: User,
    review?: {
      role: ReviewerRole,
      response: ChangeOrderResponse
    }
  }[]
}
export const getGroupReviewStatus = ({ changeOrder, reviewers, groupConfigs, allGroups, allUsers, workflow, lifeCycleStages }: ReviewsDataProps): ReviewStatus => {
  let valid = true;

  const highestLifeCycle = resolveLifeCycle(lifeCycleStages, changeOrder.highestLifeCycle)

  const groupsReviews = groupConfigs.reduce((acc, cfg) => {
    const group = allGroups.find(a => a.id === cfg.groupId.toString())!;

    const activeRule = workflow?.rules?.find(r => r.effect.groupId === cfg.groupId)

    let ruleConditionMet: undefined | boolean = true;
    let ruleLifeCycle;
    if (activeRule) {
      ruleLifeCycle = resolveLifeCycle(lifeCycleStages, activeRule.condition.stage)
      ruleConditionMet = ruleLifeCycle && ruleLifeCycle.stageIndex <= highestLifeCycle.stageIndex
    }

    const isOwnerOnlyGroup = group.members.length === 1
      && group.members[0]?.userId.toString() === changeOrder.creator.id
    const requiredCount = Math.min(group.members.length, 1)
    const approvedCount = group.members
      .filter(
        m => reviewers.find(r => r.response === 'Approve' && r.user.id === m.userId.toString()))
      .length
    const approved = !ruleConditionMet || isOwnerOnlyGroup || approvedCount >= requiredCount;
    if (!approved) valid = false;
    acc[group.id] = {
      groupId: group.id,
      activeRule,
      ruleConditionMet,
      ruleLifeCycle,
      requiredCount,
      approvedCount,
      approved,
      approvalReason: isOwnerOnlyGroup ? 'ownerOnly' : group.members.length === 0 ? 'empty' : 'standard',
      name: group.name,
      members: group.members.filter(m => m.userId.toString() !== changeOrder.creator.id).map(m => ({
        ...m,
        user: allUsers.find(u => u.id === m.userId.toString())!,
        review: reviewers.find(r => r.user.id === m.userId.toString())
      }))
    }
    return acc;
  }, {} as Record<string, GroupReview>)

  return {
    highestLifeCycle,
    valid,
    groupsReviews
  }
}

export default ({ changeOrder, loggedInId }: { changeOrder: COType, loggedInId: string }) => {
  const dataProps = useContext(WorkflowContext)

  const [addReviewer, { loading }] = useMutation<AddReviewerMutation, AddReviewerMutationVariables>(ADD_REVIEWER_MUTATION)
  const [addUserGroup] = useMutation<CreateUserGroupReviewConfigMutation, CreateUserGroupReviewConfigMutationVariables>(CREATE_USER_GROUP_REVIEW_CONFIG_MUTATION)
  const [deleteReviewer] = useMutation<DeleteReviewerMutation, DeleteReviewerMutationVariables>(DELETE_REVIEWER_MUTATION)

  const handleAddReviewerOption = (role: ReviewerRole) => async (selection: User | Group) => {
    const { mutation, variables, errorMessage } = selection.__typename === 'User' ?
      {
        mutation: addReviewer,
        variables: {
          input: {
            userId: selection.id,
            number: changeOrder.number,
            role
          },
        },
        errorMessage: `Failed to add reviewer to change order`,
      } : {
        mutation: addUserGroup,
        variables: {
          input: {
            groupId: parseInt(selection.id),
            changeOrderNumber: changeOrder.number
          }
        },
        errorMessage: `Failed to add reviewer group to change order`
      }

    const { errors } = await mutation({
      //@ts-ignore untracked covariance
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true,
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: errorMessage
      })
    }
  }

  const handleDeleteReviewer = async (userId: string) => {
    const variables = {
      userId,
      orderNumber: changeOrder.number
    }
    const { errors } = await deleteReviewer({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Failed to remove reviewer from change order`
      })
    }
  }

  const { Reviewer: reviewers = [], Subscriber: subscribers = [] } = groupBy(changeOrder.reviewers, r => r.role)
  const reviewStatus = getGroupReviewStatus(dataProps)

  const groupReviews = changeOrder.reviewerGroups.map(g => reviewStatus.groupsReviews[g.groupId]!).sort((a, b) => {
    const aIdx = a.ruleLifeCycle?.stageIndex ?? -1
    const bIdx = b.ruleLifeCycle?.stageIndex ?? -1
    return aIdx === bIdx ? 0 : aIdx < bIdx ? -1 : 1
  }).filter(p => p.activeRule ? p.ruleConditionMet : true)

  return <div>
    {changeOrder.workflow && <>
      <div className='font-medium mt-4 mb-2 flex gap-2 items-center'>
        Workflow
      </div>
      <div data-testid='Workflow-Section'>
        <div>
          {(groupReviews.length === 0) ?
            <div className='italic font-light'>There are no reviewer groups</div> :
            <ul className=''>
              {groupReviews.map(g =>
                <GroupPanel
                  key={g.groupId}
                  changeOrder={changeOrder}
                  groupReview={g}
                  onAddReviewer={user => handleAddReviewerOption('Reviewer')(user)}/>
              )}
            </ul>
          }
        </div>
      </div>
    </> }
    <ReviewerSection
      label='Reviewer'
      reviewers={reviewers}
      changeOrder={changeOrder}
      onUserAdd={(user) => handleAddReviewerOption('Reviewer')(user)}
      onUserDelete={handleDeleteReviewer}
      loggedInId={loggedInId}
      loading={loading}
    />
    <ReviewerSection
      label='Watcher'
      reviewers={subscribers}
      omitOptions={reviewers}
      changeOrder={changeOrder}
      onUserAdd={(user) => handleAddReviewerOption('Subscriber')(user)}
      onUserDelete={handleDeleteReviewer}
      loggedInId={loggedInId}
      loading={loading}
    />
  </div>
}

type ReviewerSectionProps = {
  label: string
  reviewers: COType['reviewers']
  omitOptions?: COType['reviewers']
  changeOrder: COType
  onUserAdd: (user: User | Group) => void
  onUserDelete: (userId: User['id']) => void
  loggedInId: string
  loading?: boolean
}
const ReviewerSection = (props: ReviewerSectionProps) => {
  const { label, reviewers, changeOrder, onUserAdd, onUserDelete, loggedInId, omitOptions = [], loading } = props;
  const appContext = useContext(AppContext)
  const isComplete = isCompletedState(changeOrder)
  const plural = label + 's'
  const pluralLower = plural.toLowerCase()
  const lower = label.toLowerCase()
  return <>
    <div className='font-medium mt-4 mb-2'>
      {plural}
    </div>
    <div data-testid={`${label}-Section`}>
      <div className='mb-2'>
        {reviewers.length === 0 ?
          <div className='pb-2 font-light italic'>There are no {pluralLower}</div> :
          <ul className=''>
            {reviewers.map(r => {
              return <li key={r.user.id} className='text-gray-600 flex items-center mb-2'>
                <div className='flex-1'>{r.user.name}</div>
                <div className='flex items-center has-tooltip relative'>
                  <StatusIcon reviewState={r.response} />
                </div>
                {appContext?.canEdit && !isComplete &&
                  <button onClick={() => onUserDelete(r.user.id)} className='pr-1 -mr-1' disabled={loading}>
                    <Tooltip.Container className='has-tooltip relative'>
                        <Tooltip.Message>
                          Remove {lower}
                        </Tooltip.Message>
                        <XMarkIcon className='h-6 w-5 ml-1 cursor-pointer' />
                      </Tooltip.Container>
                  </button>
                }
              </li>
            })}
          </ul>
        }
      </div>
      {!isComplete && appContext?.canEdit &&
        <ReviewerSelect
          loggedInId={loggedInId}
          displayValue={`Add a ${lower}`}
          omit={reviewers.concat(omitOptions).map(r => r.user.id)}
          onSelect={onUserAdd}/>}
    </div>
  </>
}

type GroupPanelProps = {
  groupReview: ReturnType<typeof getGroupReviewStatus>['groupsReviews']['string']
  onAddReviewer: (user: User) => void
  changeOrder: COType
}
const GroupPanel = ({ groupReview, onAddReviewer, changeOrder }: GroupPanelProps) => {
  const appContext = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const isComplete = isCompletedState(changeOrder)

  return <li key={groupReview.groupId} className={`flex flex-col -ml-6 mb-1 last:mb-inherit ${
    (groupReview.approvalReason === 'ownerOnly' || groupReview.approvalReason === 'empty') ?
      'text-gray-300' : 'text-gray-600'
    }`} >
    <button type='button' onClick={() => setOpen(!open)} className='grow px-2 -mx-2 flex gap-2 items-center mb-1'>
      {open ?
        <ChevronDownIcon className='h-6 w-4'/> :
        <ChevronRightIcon className='h-6 w-4'/>
      }
      <UserGroupIcon className='w-4'/>
      {groupReview.name}
      {' '}
      <div className='ml-auto flex' data-testid={`group-review-${groupReview.approvalReason ?? 'pending'}`}>
        <Tooltip.Container>
          {groupReview.approved ?
            (groupReview.approvalReason === 'ownerOnly' ?
              <>
                <Tooltip.Message className='max-w-6xl !w-[350px] !whitespace-normal'> This team has been excluded from the workflow because only the owner of the change order is in the team </Tooltip.Message>
                <NoSymbolIcon className='h-6 w-6' />
              </>
            : groupReview.approvalReason === 'empty' ?
              <>
                <Tooltip.Message className='max-w-6xl !w-[300px] !whitespace-normal'> This team has been excluded from the workflow because there are no team members </Tooltip.Message>
                <NoSymbolIcon className='h-6 w-6' />
              </>
            :
              <>
                <Tooltip.Message> Approved </Tooltip.Message>
                <ShieldCheckIcon className='h-6 w-6 text-green-500' />
              </>
            )
          :
            <>
              <Tooltip.Message position='left'> Waiting for review from group members </Tooltip.Message>
              <ShieldExclamationIcon className='h-6 w-6' />
            </>
          }
        </Tooltip.Container>
      </div>
    </button>
    { open &&
      <div className='flex flex-col min-h-10 pb-2 mt-1' data-testid={`${groupReview.name}-team-members`}>
        {groupReview.members.map(m =>
          <div className='ml-12 flex gap-1 items-center h-6'>
            <span className='font-extralight'>{m.user.name}</span>
            <div className='ml-auto'>
              {m.review ?
                <StatusIcon reviewState={m.review.response} className='mr-[2px]'/> :
                <Tooltip.Container className='h-full mr-[2px]'>
                  <Tooltip.Message> Add as reviewer </Tooltip.Message>
                  {appContext?.canEdit && !isComplete &&
                    <button type='button' onClick={() => onAddReviewer(m.user)} className='h-full'>
                      <UserPlusIcon className='h-4 w-4'/>
                    </button>
                  }
                </Tooltip.Container>
              }
            </div>
          </div>
        )}
        {groupReview.members.length === 0 &&
          <div className='ml-12 italic'>
            No valid members
          </div>
        }
      </div>
    }
  </li>
}
