import { useState } from 'react'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import {
  ArrowTopRightOnSquareIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'

import {
  Source,
  Distributor,
  SourcesInput,
  EditSourcesMutation,
  EditSourcesMutationVariables
} from 'types/graphql'

import { TrashIcon } from '@heroicons/react/24/outline'
import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_QUERY } from 'src/lib/queries'
import type { PartDiffSummary, SourceFieldChange } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import * as ListBox from 'src/components/ListBox'
import * as Form from 'src/components/Form'

import Button, {
  EditButton
} from 'src/components/Button'
import { quantityUnits } from 'shared/types'
import { displayUnits } from '../Dependencies'


const EDIT_SOURCES_MUTATION = gql`
mutation EditSourcesMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
  $sources: [SourcesInput!]
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: [{
    type: Patch
    partNumber: $partNumber
    version: $version
    part: {
      sources: $sources
    }
  }]) {
    partNumber
  }
}
`

const NULL_DISTRIBUTOR = '__NULL__'

type DistributorInput = Omit<Distributor,
  '__typename'>

type SourceInput = Omit<Source,
  'distributor' | '__typename'> & {
    distributor?: DistributorInput | null
  }

type SourceInputChanges = Pick<SourceInput,
  'priority' |
  'distributor' |
  'distributorSku' |
  'price' |
  'priceCurrency' |
  'leadTimeDays' |
  'perQuantity' |
  'perQuantityUnit' |
  'stock' |
  'comment' |
  'url'
>

type ChangeFieldName = keyof SourceInputChanges
type TextChangeFieldName = keyof Pick<SourceInputChanges, 'distributorSku' | 'url'>

type DisplayOptions = {
  inputContainerClass: string
  inputClass: string
}
const DisplayFieldNames = {
  priority: {
    inputContainerClass: '',
    inputClass: '',
  },
  distributor: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs'
  },
  distributorSku: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs'
  },
  price: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs'
  },
  priceCurrency: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs'
  },
  leadTimeDays: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs w-20 arrow-hide'
  },
  perQuantity: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs w-20 arrow-hide'
  },
  perQuantityUnit: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs'
  },
  stock: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs arrow-hide w-10'
  },
  comment: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs'
  },
  url: {
    inputContainerClass: '',
    inputClass: 'py-1.5 px-2 text-xs'
  },
} as Record<ChangeFieldName, DisplayOptions>


type SourceChangesProps = {
  changeOrderComplete: boolean
  distributors: DistributorInput[]
  changeOrderNumber?: number
  partDiff: PartDiffSummary
  isExpanded: boolean
}

export const SourceChanges: React.FC<SourceChangesProps> = ({
  changeOrderComplete,
  distributors,
  changeOrderNumber,
  partDiff,
  isExpanded
}) => {
  const { headPart, incomingPart } = partDiff
  const editProps = changeOrderComplete ? undefined : {
    distributors,
    changeOrderNumber: changeOrderNumber!,
    partNumber: incomingPart.partNumber,
    partVersion: incomingPart.version
  }
  if (!headPart) {
    return <Sources
      sources={incomingPart.sources}
      editMode={!changeOrderComplete && 'edit'}
      editProps={editProps}
    />
  }

  const sourceChanges = partDiff.fields.sources.change
  const sourceChangePriority = sourceChanges.filter(change => {
    return Object.values(change.fields || {}).some(Boolean) || change.wholeSource
  }).map(c => c.priority)
  const showOnly = isExpanded ? undefined : sourceChangePriority

  if (showOnly?.length === 0) return null

  return <div className='flex'>
    <Sources
      showOnly={showOnly}
      compact
      sources={headPart.sources}
    />
    <div className='border-l border-gray-200 mx-10'></div>
    <Sources
      showOnly={showOnly}
      compact
      changes={{
        sourceChanges: partDiff.fields.sources.change,
        headSources: headPart.sources
      }}
      sources={incomingPart.sources}
      editMode={!changeOrderComplete && 'edit'}
      editProps={editProps}
    />
  </div>
}

type SourcesProps = {
  editMode?: 'edit' | 'lock' | false
  sources: SourceInput[]
  compact?: boolean
  changes?: {
    headSources: SourceInput[],
    sourceChanges: PartDiffSummary['fields']['sources']['change']
  }
  editProps?: {
    distributors: DistributorInput[]
    changeOrderNumber: number
    partVersion: string
    partNumber: string
  }
  showOnly?: number[]
}

const emptyPlaceholder = (changeClass: string) => {
  return <span className={`${changeClass} italic text-gray-500 min-w-20 inline-block pl-1`}>-</span>
}

let newId = 0
export const Sources: React.FC<SourcesProps> = ({
  editMode,
  sources,
  changes,
  compact,
  editProps,
  showOnly
}) => {
  let renderedSources: Partial<SourceInput>[] = [...sources].sort((a, b) => a.priority - b.priority)

  // right side sources needs to show removed things too
  if (changes) {
    const { sourceChanges, headSources } = changes
    renderedSources = sourceChanges.map(change => {
      if (change.wholeSource === 'removed') {
        return headSources.find(head => head.priority === change.priority)
      }
      return sources.find(incoming => incoming.priority === change.priority)
    }) as SourceInput[]
  }

  const [editableSources, setEditableSources] = useState<Partial<SourceInput>[] | null>(null)

  if (editableSources) {
    renderedSources = editableSources
  }

  const handleAddSource = () => {
    setEditableSources([...editableSources!, {
      id: `NEW-${newId++}`,
      perQuantity: 1,
      perQuantityUnit: 'Each'
    }])
  }

  const handleCancel = () => {
    setEditableSources(null)
  }

  const handleMoveSource = (id: string, direction: 'up' | 'down') => {
    const subjectIndex = editableSources!.findIndex(s => s.id === id)!
    const destinationIndex = direction === 'up' ? subjectIndex - 1 : subjectIndex + 1
    if (!editableSources![destinationIndex]) return

    const nextEditableSources = [...editableSources!]
    nextEditableSources[subjectIndex] = editableSources![destinationIndex]!
    nextEditableSources[destinationIndex] = editableSources![subjectIndex]!

    setEditableSources(nextEditableSources)
  }

  const gridClass = compact ? 'grid-cols-1' : 'grid-cols-2'

  type FormSourceData = {
    comment: string
    distributorSku: string
    distributorId: string
    leadTimeDays?: number
    perQuantity?: number
    perQuantityUnit?: string
    price: {
      value?: number
      unit: string
    }
    stock?: number
    url?: string
  }

  const [editSourcesMutation] = useMutation<EditSourcesMutation, EditSourcesMutationVariables>(EDIT_SOURCES_MUTATION)

  const handleSubmit = async (data: Record<string, FormSourceData>) => {
    const sources = editableSources!.map((s, i) => {
      const formData = data[s.id]!
      const savedSource: SourcesInput = {
        priority: i
      }
      if (formData.distributorSku) savedSource.distributorSku = formData.distributorSku
      if (!formData.distributorId || formData.distributorId === NULL_DISTRIBUTOR) {
        savedSource.distributorId = null
      } else {
        savedSource.distributorId = formData.distributorId
      }
      if (formData.price.value) {
        savedSource.price = formData.price.value
        savedSource.priceCurrency = formData.price.unit
      }
      if (formData.leadTimeDays) savedSource.leadTimeDays = formData.leadTimeDays
      if (formData.perQuantity) savedSource.perQuantity = formData.perQuantity
      if (formData.perQuantityUnit) savedSource.perQuantityUnit = formData.perQuantityUnit
      if (typeof formData.stock === 'number') savedSource.stock = formData.stock
      if (formData.comment) savedSource.comment = formData.comment
      if (formData.url) savedSource.url = formData.url
      return savedSource
    })

    const changeOrderNumber = editProps!.changeOrderNumber
    const variables: EditSourcesMutationVariables = {
      partNumber: editProps!.partNumber,
      changeOrderNumber,
      version: editProps!.partVersion,
      sources
    }

    const { errors } = await editSourcesMutation({
      variables: variables,
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: changeOrderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: changeOrderNumber } },
      ],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part sources`
      })
    }
    setEditableSources(null)
  }
  const body = () => {
    if (renderedSources.length === 0) return <div className='text-gray-600 col-span-2 rounded-lg bg-gray-100 italic text-sm justify-center flex items-center h-10 px-4'>
      This part doesn't have any sources
    </div>
    return renderedSources.map((source, sourceIndex) => {
      const sourcePriority = sourceIndex
      if (!editableSources && showOnly && !showOnly.includes(sourcePriority)) return null
      const change = changes?.sourceChanges.find(s => s.priority === sourcePriority)
      const wholeChangeClass = () => {
        const noChange = {
          headerClass: '',
          bodyClass: '',
          outerClass: 'border-gray-200'
        }
        if (editableSources) return noChange
        if (change?.wholeSource === 'removed') return {
          headerClass: 'bg-red-200',
          bodyClass: 'bg-red-100',
          outerClass: 'border-red-300'
        }
        if (change?.wholeSource === 'added') return {
          headerClass: 'bg-green-200',
          bodyClass: 'bg-green-100',
          outerClass: 'border-green-400'
        }
        return noChange
      }
      const {
        headerClass,
        bodyClass,
        outerClass
      } = wholeChangeClass()

      const getChangeClass = (change?: SourceFieldChange) => {
        if (editableSources) return ''
        if (change === 'added') return 'bg-green-200'
        if (change === 'removed') return 'bg-red-200'
        if (change === 'modified') return 'bg-yellow-200'
        return ''
      }
      const distributor = () => {
        const name = `${source.id}.distributorId`
        if (editableSources) return <div className='relative'>
          <DistributorSelect
            name={name}
            distributors={editProps!.distributors}
            defaultId={source.distributor?.id} />
        </div>
        const changeClass = getChangeClass(change?.fields?.distributorId)
        if (!source.distributor) return emptyPlaceholder(changeClass)
        return <div className={changeClass}>{source.distributor.name}</div>
      }
      const sku = () => {
        const changeClass = getChangeClass(change?.fields?.distributorSku)
        if (editableSources) return renderTextField('distributorSku')
        if (!source.distributorSku) return emptyPlaceholder(changeClass)
        return <div className={changeClass}>{source.distributorSku}</div>
      }
      const url = () => {
        if (editableSources) return renderTextField('url')
        const changeClass = getChangeClass(change?.fields?.url)
        return source.url ?
          <a className={changeClass + ' text-blue-600'} target='_blank' href={source.url}>{source.url}</a> :
          emptyPlaceholder(changeClass)
      }

      const price = () => {
        const changeClass = getChangeClass(change?.fields?.price)
        if (!editableSources && !source.price) return emptyPlaceholder(changeClass)
        const fields = editableSources ? <div className='flex gap-1'>
          <Form.PriceField
            size='sm'
            defaultUnit={source.priceCurrency || 'USD'}
            defaultValue={String(source.price) || '0'}
            name={`${source.id}.price`}
            />
        </div> : <div className={changeClass}>
          {source.price} {source.priceCurrency}
        </div>
        return fields
      }
      const leadTimeDays = () => {
        const changeClass = getChangeClass(change?.fields?.leadTimeDays)
        if (!editableSources && !source.leadTimeDays) return emptyPlaceholder(changeClass)

        const displayOptions = DisplayFieldNames.leadTimeDays
        const name = `${source.id}.leadTimeDays`
        if (editableSources) return <Form.NumberField
          emptyAs="undefined"
          className={displayOptions.inputContainerClass}
          inputClassName={displayOptions.inputClass}
          trailingAddOn={<span>days</span>}
          name={name}
          min='0'
          step='1'
          validation={{ valueAsNumber: true }}
          placeholder={`-`}
          data-testid={`leadtime-input`}
          defaultValue={source.leadTimeDays || ''} />
        return <div className={changeClass} data-testid={`leadtime-display`}>{source.leadTimeDays} days</div>
      }

      const perQuantityUnit = () => {
        const name = `${source.id}.perQuantityUnit`
        if (editableSources) return <div className='relative'>
          <ListBox.HookedListBox
            required
            defaultValue={source.perQuantityUnit}
            name={name}>

            <ListBox.UncontrolledButton className='leading-4 ml-1 py-0 pl-1 pr-6 min-w-14 [&>*:nth-child(2)]:pr-0 text-xs align-center' wrapperClassName='block' />
            <ListBox.Options>
              {quantityUnits.map((o) => (
                <ListBox.Option
                  key={o}
                  className='py-3 text-right'
                  value={o}
                  display={o} />
              ))}
            </ListBox.Options>
          </ListBox.HookedListBox>
        </div>
        const changeClass = getChangeClass(change?.fields?.perQuantityUnit)
        return <div className={changeClass}>{displayUnits(source.perQuantityUnit)}</div>
      }
      const perQuantity = () => {
        const changeClass = getChangeClass(change?.fields?.perQuantity)
        if (!editableSources && !source.perQuantity) return emptyPlaceholder(changeClass)
        console.log({ source })
        const displayOptions = DisplayFieldNames.perQuantity
        const name = `${source.id}.perQuantity`
        if (editableSources) return <Form.NumberField
          emptyAs="undefined"
          className={displayOptions.inputContainerClass}
          inputClassName={displayOptions.inputClass}
          name={name}
          min='0'
          step='1'
          validation={{ valueAsNumber: true }}
          placeholder={`-`}
          data-testid={`perQuantity-input`}
          defaultValue={source.perQuantity || ''} />
        return <div className={changeClass} data-testid={`perQuantity-display`}>{source.perQuantity}</div>
      }
      const stock = () => {
        const changeClass = getChangeClass(change?.fields?.stock)
        if (!editableSources && typeof source.stock !== 'number') {
          return emptyPlaceholder(changeClass)
        }
        const displayOptions = DisplayFieldNames.stock
        const name = `${source.id}.stock`
        if (editableSources) return <Form.NumberField
          emptyAs="undefined"
          className={displayOptions.inputContainerClass}
          inputClassName={displayOptions.inputClass}
          name={name}
          min='0'
          step='1'
          validation={{ valueAsNumber: true }}
          placeholder={`-`}
          defaultValue={source.stock || ''} />
        return <div className={changeClass}>{source.stock}</div>
      }
      const comment = () => {
        const changeClass = getChangeClass(change?.fields?.comment)
        const displayOptions = DisplayFieldNames.comment
        const name = `${source.id}.comment`
        const staticComment = source.comment ? <div className={changeClass}>{source.comment}</div> :
          emptyPlaceholder(changeClass)
        return <div className='flex gap-2 flex-col' data-testid='source-comment'>
          <div className='text-gray-900 font-semibold'>Comment</div>
          <div>{editableSources ? <Form.TextArea
            className={displayOptions.inputClass}
            name={name}
            placeholder={`-`}
            defaultValue={source.comment || ''} /> : staticComment}</div>
        </div>
      }
      const prioritySelector = () => {
        if (!editableSources) return null
        const hideUp = sourceIndex === 0
        const hideDown = sourceIndex === editableSources.length - 1
        return <div className='text-gray-500 flex items-center flex-col'>
          <button type='button' className='cursor-pointer' onClick={() => handleMoveSource(source.id, 'up')}>
            {!hideUp && <ChevronUpIcon className='w-3 h-3' />}
          </button>
          <button type='button' className='cursor-pointer' onClick={() => handleMoveSource(source.id, 'down')}>
            {!hideDown && <ChevronDownIcon className='w-3 h-3' />}
          </button>
        </div>
      }

      const renderTextField = (fieldName: TextChangeFieldName) => {
        const name = `${source.id}.${fieldName}`
        const displayOptions = DisplayFieldNames[fieldName]
        return <Form.TextField
          className={displayOptions.inputContainerClass}
          inputClassName={displayOptions.inputClass}
          name={name}
          placeholder={`-`}
          defaultValue={source[fieldName] as string} />
      }

      const deleteSource = () => {
        if (!editableSources) return null
        const handleDeleteSource = () => {
          setEditableSources(editableSources!.filter(s => s.id !== source.id))
        }
        return <button type="button" className={''} onClick={handleDeleteSource}>
          <TrashIcon className={'w-4'} />
        </button>
      }

      return <div className={`border rounded-lg text-xs ${outerClass}`} key={source.id} data-testid={`source-priority-${sourceIndex + 1}`}>
        <div className={`text-gray-900 bg-gray-50 p-4 rounded-t-lg flex justify-between ${headerClass}`}>
          <div className='font-semibold flex gap-1 items-center'>
            <div>Priority #{sourceIndex + 1}</div>{prioritySelector()}
          </div>
          <div className='flex gap-2 items-center' data-testid='sku'>
            <div className='font-semibold'>SKU</div>
            <div>{sku()}</div>
            {deleteSource()}
          </div>
        </div>
        <div className={`p-4 flex flex-col gap-4 text-xs rounded-b-lg ${bodyClass}`} data-testid='source-data'>
          <div className='flex gap-2 flex-col'>
            <div className='text-gray-900 font-semibold'>Link</div>
            <div>{url()}</div>
          </div>
          <div className='flex gap-6'>
            <div className='flex gap-2 flex-col' data-testid='price'>
              <div className='text-gray-900 font-semibold'>Price</div>
              {price()}
            </div>
            <div className='flex gap-2 flex-col' data-testid='perquantity'>
              <div className='text-gray-900 font-semibold whitespace-nowrap'>Per Quantity</div>
              <div className='flex gap-1'>
                <div>{perQuantity()}</div>
                <div>{perQuantityUnit()}</div>
              </div>
            </div>
          </div>
          <div className='flex gap-6'>
            <div className='flex-1 flex gap-2 flex-col' data-testid='distributor'>
              <div className='text-gray-900 font-semibold'>Distributor</div>
              <div>{distributor()}</div>
            </div>
            <div className='flex gap-2 flex-col' data-testid='leadtime'>
              <div className='text-gray-900 font-semibold whitespace-nowrap'>Lead Time</div>
              <div>{leadTimeDays()}</div>
            </div>
            <div className='flex gap-2 flex-col' data-textid='stock'>
              <div className='text-gray-900 font-semibold'>Stock</div>
              <div>{stock()}</div>
            </div>
          </div>
          { comment() }
        </div>
      </div>
    })
  }
  return <Form.Form className='flex-1' onSubmit={handleSubmit} data-testid={`sources-form${changes ? '-changes' : ''}`}>
    <div className='flex items-center gap-2 text-sm font-semibold text-gray-900 h-8 mb-2'>
      <div>Sources</div>
      {editMode && <EditButton onClick={() => setEditableSources(sources)} disabled={editMode === 'lock'} size={4} testId='sources'/>}
    </div>
    <div className='text-sm text-gray-700 mb-4'>
      Sources for the part in priority order
    </div>
    <div className={`-mx-1 grid gap-4 ${gridClass}`}>
      {
        body()
      }
    </div>
    {
      editableSources && <div className='mt-4 flex justify-between'>
        <Button onClick={handleAddSource}>Add source</Button>
        <div className='flex gap-2'>
          <Button onClick={handleCancel}>Cancel</Button>
          <Form.Submit variant='primary'>Save</Form.Submit>
        </div>
      </div>
    }
  </Form.Form>
}


type DistributorSelectProps = {
  distributors: Distributor[]
  defaultId?: string
  name: string
}


const DistributorSelect: React.FC<DistributorSelectProps> = ({ name, distributors, defaultId }) => {
  return <ListBox.HookedListBox name={name} defaultValue={defaultId || NULL_DISTRIBUTOR}>
          <div className=''>
            <ListBox.UncontrolledButton className='text-xs' displayFunction={
              (s) => distributors.find(d => s.value === d.id)?.name || 'None'
            } />
            <ListBox.Options>
              <ListBox.Option
                key={'none'}
                className='py-3 text-right'
                value={NULL_DISTRIBUTOR}
                display={`None`} />
              {distributors.map((d) => (
                <ListBox.Option
                  key={d.id}
                  className='py-3 text-right'
                  value={d.id}
                  display={`${d.name}`} />
              ))}
            </ListBox.Options>
          </div>
  </ListBox.HookedListBox>
}
