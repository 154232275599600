import { Fragment, createContext } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { ZipIcon } from '../Icons';
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { DocumentTextIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { displayUnits } from 'src/components/Dependencies'
import { useLifeCycleStages, resolveLifeCycle } from "src/lib/lifecycle"
import { mkConfig, generateCsv } from "export-to-csv";
import { useMetadataTabularizer } from 'src/lib/metadata'
import Button from 'src/components/Button'

import { AppContext, useAppContext } from 'src/lib/appContext';
import { HierarchyController } from '../PartHierarchy';
import { shortDateTime } from 'src/lib/formatters';
import { getInstanceQuantity } from './aggregation';

type ExportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
} & ExportBodyProps

type ExportBodyProps = {
  hierarchyController: HierarchyController
}
export type ExportMapper = AppContext['currentOrg']['manualExportMapper']
export const ExportMapperContext = createContext<ExportMapper>(null)

export const ExportDialog: React.FC<ExportDialogProps> = ({ open, setOpen, hierarchyController }) => {
  const nodesSelected = hierarchyController.selectable ? hierarchyController.roots.some(r => r.selectedNodes.length > 0) : true
  return <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-8 py-6 text-left shadow-xl transition-all min-w-[28rem]">
              <div>
                <div className="">
                  <Dialog.Title className="font-medium mb-5 text-lg text-gray-900">
                    Export
                  </Dialog.Title>
                  {nodesSelected ?
                    <ExportBody hierarchyController={hierarchyController} /> :
                    <div>Selection was empty. Please select items to export or turn off selection mode.</div>
                  }
                </div>
              </div>
              <div className="mt-5 flex flex-col items-end -mr-1">
                <Button
                  onClick={() => setOpen(false)}
                >
                  Done
                </Button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
}

const ExportBody = ({ hierarchyController }: ExportBodyProps) => {
  const exportInfo = calculateExports({ hierarchyController })

  return (
    <div className='flex flex-col gap-3 my-68'>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Bill of materials
        </div>
        <CsvLink body={exportInfo.csv} fileName={`${exportInfo.name}.csv`} />
      </div>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          BOM with Artifacts
        </div>
        <ArtifactsBundle files={exportInfo.bundle} filename={`${exportInfo.name}.zip`} />
      </div>
    </div>
  )
}

type CsvLinkProps = {
  fileName: string
  testId?: string
  body: Record<string, unknown>[]
}
export const csvConfig = mkConfig({ useKeysAsHeaders: true })
export const CsvLink: React.FC<CsvLinkProps> = ({ body, fileName, testId }) => {
  const csv = generateCsv(csvConfig)(body)
  const csvContent = `data:text/csv;charset=utf-8,${csv}`;

  const encodedUri = encodeURI(csvContent).replaceAll('#', '%23');
  return <a
    data-testId={testId || 'csv-download-link'}
    className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
    href={encodedUri} download={fileName}>
    <DocumentTextIcon className='h-10 w-10 text-green-500' />
    <div className='flex-1'>{fileName}</div>
    <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
  </a>
}

export const PlainCsvLink: React.FC<CsvLinkProps & { children: React.ReactNode }> = ({ body, fileName, children, testId }) => {
  // generateCsv throws error when body is empty array
  const csv = body.length === 0 ? '' : generateCsv(csvConfig)(body)
  const csvContent = `data:text/csv;charset=utf-8,${csv}`;

  const encodedUri = encodeURI(csvContent).replaceAll('#', '%23');
  return <a
    data-testId={testId || 'csv-download-link'}
    href={encodedUri} download={fileName}>
    <div className='flex-1'>{children}</div>
  </a>
}


type ArtifactDownload = {
  source?: string
  sourceContent?: string
  destination: string
}

type ArtifactsBundleProps = {
  filename: string
  files: ArtifactDownload[]
  align?: 'left' | 'center'
}

export const ArtifactsBundle: React.FC<ArtifactsBundleProps> = ({ filename, files, align }) => {
  return <button
    className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
    onClick={() => generateDownloadBundle({ files, filename })}>
    <ZipIcon className='h-10 w-10 text-gray-500' />
    <div className={`flex-1 ${align === 'left' ? 'text-left' : ''}`}>{filename}</div>
    <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
  </button>
}

type GenerateBundleInput = {
  filename: string
  files: ArtifactDownload[]
}
export const generateDownloadBundle = async ({ files, filename }: GenerateBundleInput) => {
  const jszip = new JSZip()
  const filesWithContent = await Promise.all(files.map(async file => {
    if (file.sourceContent) return file
    const response = await fetch(file.source!)
    return {
      destination: file.destination,
      sourceContent: await response.blob()
    }
  }))
  for (let file of filesWithContent) {
    jszip.file(file.destination, file.sourceContent!);
  }
  jszip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, filename);
  });
}

export const calculateExports = ({ hierarchyController }: ExportBodyProps) => {
  const tabularizeMetadata = useMetadataTabularizer()
  const appContext = useAppContext()
  const exportMapper = appContext.currentOrg.manualExportMapper
  const stages = useLifeCycleStages()

  if (hierarchyController.roots.length === 0) {
    return {
      name: '',
      csv: [],
      flatCsv: [],
      bundle: []
    }
  }

  const fullTree = hierarchyController.roots.flatMap((root, i) => {
    const filteredTree = root.tree.filter(n => !n.filterRemove)
    const selectedByLevel = Object.fromEntries(root.selectedNodes.map(key => [key, true]))
    const selectedHierarchy = hierarchyController.selectable ?
      filteredTree.filter(n => selectedByLevel[n.hierarchy]) : filteredTree
    return selectedHierarchy.map(node => {
      const [top, ...levels] = node.hierarchy.split('.')
      return {
        ...node,
        hierarchy: `${i+1}.${levels.join('.')}`
      }
    })
  })

  const downloads = fullTree
    .reduce((deduped, h, i) => {
      const exists = deduped.some(e => {
        return h.part.partNumber === e.part.partNumber &&
          h.part.version === e.part.version
      })
      if (!exists) {
        return [...deduped, h]
      }
      return deduped
    }, [] as typeof fullTree)
    .flatMap(h => {
      return h.part.artifacts.map(a => {
        return {
          source: a.file.url,
          destination: `${h.part.partNumber}v${h.part.version} - ${h.part.name}/${a.filename}`
        }
      })
    })

  const sourcesHeaderPrefixes =
    Array(fullTree.reduce((count, p) => Math.max(count, p.part.sources.length), 0)).fill(null)
      .map((_, i) => `Source-${i + 1}-`)

  type Sources = (typeof fullTree)[number]['part']['sources']
  const tabularizeSources = (sources: Sources) =>
    sourcesHeaderPrefixes.reduce((acc, prefix, idx) => {
      const source = sources[idx];
      return {
        ...acc,
        [prefix + 'Distributor']: source?.distributor?.name ?? '',
        [prefix + 'SKU']: source?.distributorSku ?? '',
        [prefix + 'Link']: source?.url ?? '',
        [prefix + 'Comment']: source?.comment ?? '',
        [prefix + 'Price']: source?.price ?? '',
        [prefix + 'Currency']: source?.priceCurrency ?? '',
        [prefix + 'Lead_Time_Days']: source?.leadTimeDays ?? '',
        [prefix + 'Per_Quantity']: source?.perQuantity ?? '',
        [prefix + 'Per_Quantity_unit']: source?.perQuantityUnit ?? '',
        [prefix + 'Stock']: source?.stock ?? '',
      }
    }, {} as Record<string, any>)

  const hierarchyCsvBody = fullTree.map(h => {
    const part = {
      'Part Number': h.part.partNumber,
      Version: h.part.version,
      'CAD Revision': h.part.cadRev,
      Category: h.part.proto.category.name,
      Name: h.part.name,
      ['Option-Name']: h.parentToNodeDependency?.groupId ?? '',
      Lifecycle: stages?.length ? resolveLifeCycle(stages, h.part.lifeCycle).name : undefined,
      Summary: h.part.summary,
      Quantity: h.parentToNodeDependency?.quantity,
      ['Quantity-Units']: displayUnits(h.parentToNodeDependency?.units),
      ...tabularizeMetadata(h.part.metadata),
      ...tabularizeSources(h.part.sources)
    }
    if (hierarchyController.hierarchyMode === 'flat') return part
    return {
      Hierarchy: h.hierarchy,
      ...part,
      ['Reference-Designator']: h.parentToNodeDependency?.referenceDesignator ?? '',
    }
  })


  // flat CSV is only designed to work in part cell
  // where there is only one BOM to be flattened
  const firstRoot = hierarchyController.roots[0]!
  const selectedWithRoot = ['1', ...firstRoot.selectedNodes]
  const selectedByLevel = Object.fromEntries(selectedWithRoot.map(key => [key, true]))

  const filteredTree = firstRoot.tree.filter(n => !n.filterRemove)
  const remainingTree = hierarchyController.selectable ? filteredTree.filter(n => selectedByLevel[n.hierarchy]) : filteredTree

  const flatCsvBody = remainingTree.reduce((rolledUp, h) => {
    const matchingIndex = rolledUp.findIndex(e => {
      return h.part.partNumber === e.part.partNumber &&
        h.part.version === e.part.version
    })
    if (matchingIndex === -1) {
      return [...rolledUp, {
        ...h,
      }]
    }
    return rolledUp.map((e, i) => {
      if (matchingIndex !== i) {
        return e
      }

      return {
        ...e,
        dependency: {
          ...e.parentToNodeDependency,
          quantity: (e.parentToNodeDependency?.quantity || 0) + (h.parentToNodeDependency?.quantity || 0),
          units: displayUnits(h.parentToNodeDependency?.units),
          designator: e.parentToNodeDependency?.referenceDesignator
        },
      }
    })
  }, [] as ((typeof remainingTree)[number] & { quantityRange?: [number, number] })[]).map(h => {
    const q = getInstanceQuantity(firstRoot.hierarchyTree, h.part.partNumber, h.part.version)
    return {
      'Part Number': h.part.partNumber,
      Version: h.part.version,
      Category: h.part.proto.category.name,
      Name: h.part.name,
      Lifecycle: stages?.length ? resolveLifeCycle(stages, h.part.lifeCycle).name : undefined,
      Summary: h.part.summary,
      Quantity: (q && q[1]) ?
        (q[0] === q[1] ? q[0] : `${q[0]}-${q[1]}`)
        : '',
      ['Quantity-Units']: displayUnits(h.parentToNodeDependency?.units),
      ['Reference-Designator']: h.parentToNodeDependency?.referenceDesignator ?? '',
      ...tabularizeMetadata(h.part.metadata),
      ...tabularizeSources(h.part.sources)
    }
  })

  type R = Record<string, any>
  const withMappedKeys = (objs: Object[]) => exportMapper ?
    objs.map(obj =>
      Object.entries(obj).reduce((mapped, [k, v]) => ({
        ...mapped,
        [(exportMapper as R)[k] ?? k]: v
      }), {} as R))
    :
    objs as R[];

  const mappedHierarchy = withMappedKeys(hierarchyCsvBody)

  const makeName = () => {
    if (hierarchyController.roots.length === 1) {
      const part = hierarchyController.roots[0]!.rootNode.part
      return `${part.partNumber}-v${part.version}`
    }
    return `Export ${shortDateTime(new Date())}`
  }
  const name = makeName()

  const files = () => {
    if (Object.keys(mappedHierarchy).length === 0) return downloads
    const csvConfig = mkConfig({ useKeysAsHeaders: true })
    const hierarchyCsvContents = String(generateCsv(csvConfig)(mappedHierarchy))
    const filesWithCsvs = [...downloads, {
      sourceContent: hierarchyCsvContents,
      destination: `${name} - Hierarchical Bill of Materials.csv`
    }]
    return filesWithCsvs
  }

  return {
    name,
    csv: mappedHierarchy,
    flatCsv: flatCsvBody,
    bundle: files()
  }
}
