
import { Metadata } from '@redwoodjs/web'
import AllParts from 'src/components/AllParts/AllParts'

const PartsPage = () => {
  return (
    <>
      <Metadata title="All Parts" description="All Parts page" />
      <div className='w-full'>
        <AllParts />
      </div>
    </>
  )
}

export default PartsPage
